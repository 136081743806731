import { ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { HeadCell } from 'components/ui/EnhancedTable';
import useAxios from 'library/axios'; 
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {IApplicationSetup, IApplicationSetupSearch } from '../models/ApplicationSetup';
import { IAppTheme } from 'themes/commonStyles';

const _ = () => {

    const axios = useAxios();

    const createApplicationSetup = async (applicationSetup: IApplicationSetup)  =>       
        await (await axios.post('/api/setup/applicationSetup/create', applicationSetup)).data;       
        
    const updateApplicationSetup = async (applicationSetup: IApplicationSetup)  =>       
        await (await axios.post('/api/setup/applicationSetup/update', applicationSetup)).data;     
        
    const getApplicationSetup = async (id  : number )  => {
        const {data} = (await axios.get(`/api/setup/applicationSetup/get-applicationSetup/${id}`));
        return await data;
    }

    const getApplicationSetups = async (criteria: IApplicationSetupSearch) : Promise<IApplicationSetup[]> => {
        const {companyName} = criteria;

        const {data} = (await axios.get(`/api/setup/applicationSetup/get-applicationSetups?companyName=${companyName}`));
        return await data;
    }

    const getApplicationTheme = async () : Promise<IAppTheme>  => {
      const {data} = (await axios.get(`/api/setup/applicationSetup/get-application-theme`));
      return await data;
    }

    const defineApplicationTheme = async (appTheme  : IAppTheme )  => 
      await (await axios.post('/api/setup/applicationSetup/define-application-theme', appTheme)).data;     
    
        
    return {
        createApplicationSetup,
        updateApplicationSetup,
        getApplicationSetup,        
        getApplicationSetups,

        getApplicationTheme,
        defineApplicationTheme
        
    } 
}

export default _;


export const useBasicFilterApplicationSetup = ( onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IApplicationSetup) => void  ) => {

    const { getApplicationSetups } = _();
  
    const { t, i18n } = useTranslation();   
      
    const [headApplicationSetupCells, setHeadApplicationSetupCells]  = useState<HeadCell<IApplicationSetup>[]>([
      {id:'id', label : t('Id'),  display: true, type: 'numeric', width: 110 },
      {id:'companyName', label : t('Name'),  display: true, type: 'string', },
     
  
    ]); 
    const [filterElements,] = useState( [       
        {name: 'name', text: t('Name'), value: ''}, 
        {name: 'description', text: t('Description'), value: ''},     
      ]);    
  
    const [filteredApplicationSetups, ] = useState<IApplicationSetup[]>([]); 
  
    const onFilterButtonClick = async (filterElements: ITextFilterElement[]): Promise<IApplicationSetup[]> => {
              
      const companyName = filterElements.find( elt => elt.name === 'companyName')?.value || '';
           
      const arr = await getApplicationSetups( {companyName} );
      
      return arr;
    }
  
    const objKey: keyof IApplicationSetup = 'id';
  
    return {
      title: t('Application Setup'), headCells: headApplicationSetupCells, objKey,
      filterElements, rows: filteredApplicationSetups, 
      onFilterButtonClick, onRowDoubleClick
    }
  }


  