
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';



import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TokenIcon from '@mui/icons-material/Token';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import MailIcon from '@mui/icons-material/Mail';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { ContactType, IContact, defaultContact } from './models/Contact';

import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import useMessageService from './services/Message';

import useEnumerationService from 'features/configuration/services/Enumeration';


import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, currentUserSessionAtom, isSaveLoadingAtom } from 'library/store';
import useContactService, { useBasicFilterContact } from './services/Contact';
import { useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import NumberFormat from 'react-number-format';

import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm, defaultBasicTextFilterProps } from 'components/ui/BasicTextFilterForm';

import IEnumeration, { Enum_MAIL_TRANSMISSION_MODE, IEnumerationItem } from 'features/configuration/models/Enumeration';

import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';

import md5 from 'md5';
import { globalConfig } from 'config';
import { isFalsy } from 'utility-types';
import { typographyGroupBoxStyling } from 'themes/commonStyles';
import { DateTimePicker } from '@mui/x-date-pickers';
import { ThreeCircles } from 'react-loader-spinner';
import { green } from '@mui/material/colors';
import { SiMicrosoftexcel } from 'react-icons/si';
import { BasicPersonForm } from './BasicPersonForm';
import { defaultPerson } from './models/Person';

const rand = () => {
  return Math.random().toString(36).substring(2);
};

const generateToken = (len: number) : string => {
  return (rand() + rand() + rand() + rand()).substring(0,len);
};

export const ContactForm: FC<IContact> = (props: IContact = {...defaultContact} ) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, checkEntitySaveAuthorization } = entityService();

  const { createContact, updateContact } = useContactService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();

  const {generateContractMessageXlsx} = useMessageService();

  const [currentUserSession, setCurrentUserSession] = useRecoilState(currentUserSessionAtom); 
  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);

  const basicFilterContact = useBasicFilterContact( 0,
    (event: React.MouseEvent<unknown>, row: IContact) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );


  const [isExportingMessages, setIsExportingMessages] = useState<boolean>(false);

  const emptyFunc = (obj: any) => {}

  const methods = useForm<IContact>({defaultValues:{...defaultContact}});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchType = watch('type');

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IContact>,Error,IContact>(
      _id>0?updateContact:createContact, {   
        onSuccess: (data: IResult<IContact>) => {
          enqueueSnackbar( '_Operation_done', { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          setCurrentFormNameAtom(`${t('Contact')} - # ${data.data.id} # ` );
          //queryClient.invalidateQueries(['Contract',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<IContact>(['Contact', _id], () => retrieveEntity('Contact',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

      const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'Contact'], () => getEnumerationItemsByEnumerationCodes
        ( [Enum_MAIL_TRANSMISSION_MODE ] ));

     const getInputAdornmentToken = (row: IContact, cellId: keyof IContact)  => ({
 
      toolTip: 'Token gen....',
      icon: TokenIcon,
      onClickIcon: (event: any, index: number, row: IContact ) => {
        // const contract = getValues().contracts[index];    

        // (refUpdateContract.current??emptyFunc)(index, {...contract, identityToken: generateToken(26)});
      }  
   })

      const cellEditable = (row: IContact, cellId: keyof IContact) => {
        return true;
     }
     
     const handleChangeContactType = ( event: React.MouseEvent<HTMLElement>, newType: ContactType ) => {
       
      if (newType === null) 
        return;
      setValue('type', newType);              
    }
    
    const [base64QrCode, setBase64QrCode] = useState<string>('');

    const handleAddContracts = (event: any) => {
      // const minId = Math.min( ...getValues().contracts.map( ctr => ctr.id) ) - 1;
      // (refAppendContracts.current??emptyFunc)({ customerId: _data?.id ?? 0,
      //   id: 0, identityToken: generateToken(26), login: '', description: '', isPasswordChanged: true, password: '', passwordMD5: '',
      //   instanceId: '', token: '',  status: '', statusDate: new Date(), 
      //    });
    } 
      
      useEffect( () => {        
        setCurrentFormNameAtom(t('Contact'));
        setCurrentBasicTextFilterProps(basicFilterContact);

        // const {id} = currentUserSession;
        // _setId(id);
      }, []);    
      
      

      // useEffect( () => {        
        
      //   async function _() {
      //     if(_id > 0) {
      //       const {phoneNumber, profilePicture} = await getContractPhoneInformation(_id);

      //       console.log({phoneNumber, profilePicture});
      //       setValue('whatsAppNumber', phoneNumber);
      //       setValue('profilePicture', profilePicture);
      //     }          
      //   }

      //   _();
      // }, [_id]);

    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            setCurrentFormNameAtom(_id>0?`${t('Contact')} - # ${_id} # -`: `${t('Contact')}` );
            if(_id > 0)
              retrieveData('Contact',_id, refetch);  
          }, [_id] );
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
          reset({..._data, person: isFalsy(_data.person) ? defaultPerson : _data.person  });          
        }
        }, [_data]);
    
           
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset({...defaultContact});    
      }

      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {     
        if(!checkEntitySaveAuthorization('Contact', _id)){
          setIsSaveLoading(false);
          return;
        }

        const data = getValues(); 
        // if(data.fullName.trim() === '' ) {
        //     enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
        //           anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        //     return;
        //   }
              
        // data.contracts.forEach( ctr => {
        //     ctr.passwordMD5 = md5(ctr.password); 
        //     ctr.isPasswordChanged= (ctr.password != '********'); 
        //   } );
        
        mutate( {...data});
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Contact', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={12} md={7} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <ToggleButtonGroup size="small"
                                color="primary" value={watchType} exclusive
                                onChange={ (event, newType) => handleChangeContactType(event, newType,)}
                                aria-label="Small sizes" fullWidth
                              >
                                <ToggleButton value="whatsApp">{t('WhatsApp')}</ToggleButton>
                                <ToggleButton value="phoneCall">{t('Call')} </ToggleButton>
                                <ToggleButton value="facebook">{t('Facebook')} </ToggleButton>
                                <ToggleButton value="email">{t('Email')} </ToggleButton>
                                <ToggleButton value="mail">{t('Mail')} </ToggleButton>
                                <ToggleButton value="twitter">{t('Twitter')}</ToggleButton>
                                <ToggleButton value="linkedIn">{t('LinkedIn')}</ToggleButton>
                                <ToggleButton value="instagram">{t('Instagram')}</ToggleButton>
                              </ToggleButtonGroup>
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                      
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                
                                <TextField sx={{width:'calc(60% - 8px)'}} id="name" label={t('Name')} 
                                    {...register('name')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: true } } />
                                <TextField sx={{width:'calc(20% - 8px)'}} id="portable1" label={`${t('Portable')} 1`} 
                                    {...register('portable1')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: false } } /> 
                                <TextField sx={{width:'calc(20% - 8px)'}} id="portable2" label={`${t('Portable')} 2`} 
                                    {...register('portable2')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: false } } />                                
                            </Box>                          
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <TextField sx={{width:'calc(60% - 8px)'}} id="description" label={t('Description')} 
                                    {...register('description')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: false } } />                                
                                { watchType === 'whatsApp'  && <TextField sx={{width:'calc(40% - 8px)'}} id="whatsAppNumber" label={`${t('WhatsApp')} : ${t('Number')}`} 
                                    {...register('whatsAppNumber')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: false } }
                                    InputProps={{                                        
                                      endAdornment: (
                                        <InputAdornment position="start">
                                          <IconButton color="primary">
                                            <WhatsAppIcon />
                                          </IconButton>                                                                                                          
                                      </InputAdornment>
                                    ) 
                                  }} />  } 
                                { watchType === 'phoneCall'  && <TextField sx={{width:'calc(40% - 8px)'}} id="description" label={t('Phone number')} 
                                    {...register('description')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: false } } 
                                    InputProps={{                                        
                                      endAdornment: (
                                        <InputAdornment position="start">
                                          <IconButton color="primary">
                                            <PhoneCallbackIcon />
                                          </IconButton>                                                                                                          
                                      </InputAdornment>
                                    ) 
                                  }}
                                    />  }
                                { watchType === 'facebook'  && <TextField sx={{width:'calc(40% - 8px)'}} id="facebookAccount" label={`${t('Facebook')} : ${t('Account')}`} 
                                    {...register('facebookAccount')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: false } } 
                                    InputProps={{                                        
                                      endAdornment: (
                                        <InputAdornment position="start">
                                          <IconButton color="primary">
                                            <FacebookIcon />
                                          </IconButton>                                                                                                          
                                      </InputAdornment>
                                    ) 
                                  }}
                                    />  } 
                                { watchType === 'email'  && <TextField sx={{width:'calc(40% - 8px)'}} id="emailAddress" label={`${t('Email')} : ${t('Address')}`} 
                                    {...register('emailAddress')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: false } }
                                    InputProps={{                                        
                                      endAdornment: (
                                        <InputAdornment position="start">
                                          <IconButton color="primary">
                                            <EmailIcon />
                                          </IconButton>                                                                                                          
                                      </InputAdornment>
                                    ) 
                                  }} />  }
                                { (watchType === 'mail')  && <Controller 
                                  name={`mailTransmissionMode`}
                                  control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="severityCode"
                                      label={t('Mail transmission mode')} inputProps={ {readOnly: false}}>                                
                                      {enumItems && enumItems.filter( e => 
                                                  e.enumerationCode === Enum_MAIL_TRANSMISSION_MODE ).map( 
                                              (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                            }
                                    </TextField>
                                  )}
                                />  } 
                                { watchType === 'twitter'  && <TextField sx={{width:'calc(40% - 8px)'}} id="twitterAccount" label={`${t('Twitter')} : ${t('Account')}`} 
                                    {...register('twitterAccount')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: false } }
                                    InputProps={{                                        
                                      endAdornment: (
                                        <InputAdornment position="start">
                                          <IconButton color="primary">
                                            <TwitterIcon />
                                          </IconButton>                                                                                                          
                                      </InputAdornment>
                                    ) 
                                  }} />  }
                                { watchType === 'linkedIn'  && <TextField sx={{width:'calc(40% - 8px)'}} id="linkedInAccount" label={`${t('LinkedIn')} : ${t('Account')}`} 
                                    {...register('linkedInAccount')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: false } } 
                                    InputProps={{                                        
                                      endAdornment: (
                                        <InputAdornment position="start">
                                          <IconButton color="primary">
                                            <LinkedInIcon />
                                          </IconButton>                                                                                                          
                                      </InputAdornment>
                                    ) 
                                  }} />  } 
                                { watchType === 'instagram'  && <TextField sx={{width:'calc(40% - 8px)'}} id="instagramAccount" label={`${t('Instagram')} : ${t('Account')}`} 
                                    {...register('instagramAccount')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: false } } 
                                    InputProps={{                                        
                                      endAdornment: (
                                        <InputAdornment position="start">
                                          <IconButton color="primary">
                                            <InstagramIcon />
                                          </IconButton>                                                                                                          
                                      </InputAdornment>
                                    ) 
                                  }} />  }                                    
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                {` ${t(('Identity of the contact'))}`}
                              </Typography>                                         
                            </Box>
                            <BasicPersonForm personKeyPath='personId' personPath='person' isIdentityOnly={false} />
                            
                        </Stack>                        
                    </Grid>
                    <Grid item xs={12} md={5} component={Paper} >
                      <Stack flexDirection='column'>
                        <Box sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={`${getValues().publicWhatsAppImageUrl}`} >
                          {/* <img src={`${globalConfig.get().apiUrl}/api/production/contract/activation`}/> */}
                          {/* <img src={`${globalConfig.get().apiUrl}/Download/${getValues().publicWhatsAppImageUrl}`}/> */}
                        </Box>           
                      </Stack>   
                    </Grid>                   
                    
                </Grid>
            </Box>
        </FormProvider> 
  )
}

