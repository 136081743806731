
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';

import { FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import {isFalsy} from 'utility-types';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';


import { IExternalLibrary, defaultExternalLibrary, IExternalLibraryClass } from './models/ExternalLibrary';

import { IResult } from 'library/interface';


import entityService from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom } from 'library/store';
import useExternalLibraryService, { useBasicFilterExternalLibrary } from './services/ExternalLibrary';
import ArrayFieldTableEx, { HeadCell } from 'components/ui/ArrayFieldTableEx';


export const ExternalLibraryForm: FC<IExternalLibrary> = (props: IExternalLibrary = defaultExternalLibrary) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();  

  const {id} = useParams();
    
  const [_id, _setId] = useState<number>( Number( id || 0 ) );  

  const {retrieveEntity, retrieveData, openEntityActionDrawer } = entityService();

  const { createExternalLibrary, updateExternalLibrary } = useExternalLibraryService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
    
  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterExternalLibrary = useBasicFilterExternalLibrary( 
    (event: React.MouseEvent<unknown>, row: IExternalLibrary) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );
  
  const methods = useForm<IExternalLibrary>({defaultValues:defaultExternalLibrary});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchFileName = watch("fileName");


  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IExternalLibrary>,Error,IExternalLibrary>(
      _id>0?updateExternalLibrary:createExternalLibrary, {   
        onSuccess: (data: IResult<IExternalLibrary>) => {
          enqueueSnackbar( '_Operation_done', { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          //reset(data.data);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['ExternalLibrary',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
        }
      });

    const {data: _data, refetch} = useQuery<IExternalLibrary>(['ExternalLibrary', _id], () => retrieveEntity('ExternalLibrary',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );


    const [headExternalLibraryClassCells, setHeadExternalLibraryClassCells]  = useState<HeadCell<IExternalLibraryClass>[]>([      
    
        {id:'className', label : t('Name'),  display: false, type: 'string', },
        {id:'classFullName', label : t('Full name'),  display: true, type: 'string', },
        {id:'isAbstractClass', label : t('Is abstract ?'),  display: true, type: 'boolean', },
        {id:'hasDefaultConstructor', label : t('Has def. constr. ?'),  display: true, type: 'boolean', },
        {id:'numberOfContructor', label : t('# constructor'),  display: true, type: 'numeric', },
        {id:'numberOfMethod', label : t('# method'),  display: true, type: 'numeric', },
    ]);

    const handleExternalLibraryClassSelected = (event: React.MouseEvent<unknown>,index: number,row: IExternalLibraryClass) => {  
        console.log(row);    
        //setRoleEntityIndex(index);
    }

  function openFileDialog() {
    (document as any).getElementById("file-upload").click();
  }

  const setFile = (_event: any) => {
    let f = _event.target.files![0];
    var reader = new FileReader();

    reader.onload = function () {

        var binaryString = reader.result as string;

        //console.log(binaryString);

        const base64String = binaryString
                                    .replace('data:', '')
                                    .replace(/^.+,/, '');

        //console.log(base64String);
        
        //console.log( Buffer.from(binaryString, 'utf8').toString('base64') );

        //let bytes = Array.from(new Uint8Array(reader.result));

        setValue("base64LibraryFile", base64String);
        setValue("fileName", f.name);
        //console.log(f);
      };
  
      reader.onerror = function () {
        console.log("File load failed");
      };
  
      reader.readAsDataURL(f);

    // methods.setValue(`files[${index}].file` as any, _event.target.files[0]);
    // methods.setValue(
    //   `files[${index}].title` as any,
    //   _event.target.files[0]?.name
    // );
    //console.log(_event.target.files[0]);
  };

  useEffect( () => {        
    setCurrentFormNameAtom(t('External library'));    
    setCurrentBasicTextFilterProps(basicFilterExternalLibrary);
  }, []);    

  /********** This use effect call retrieve data wich will call refetch and _data will be updated. 
    and the new useEffect will take place ********************/
    useEffect( () => {
        // setCurrentFormName(t('Billing'));        
        
        if(_id > 0)
          retrieveData('ExternalLibrary',_id, refetch);  
      }, [_id] );


    useEffect( () => {
        
    if(_data && _data.id > 0) {
        reset(_data);
    }
    }, [_data]);

  const newData = async (event: MouseEvent<HTMLButtonElement>) => {        
    reset(defaultExternalLibrary);    
  }
  
  const saveData = async (event: MouseEvent<HTMLButtonElement>) => {        
      const data = getValues(); 
      if(data.name.trim() === '' || data.description.trim() === '') {
          enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          return;
        }
  
      mutate(data);
  }

  const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
    openEntityActionDrawer('ExternalLibrary', _id);
  }
  
  
  
  const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
  //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
  //    await retrieveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
  //    reset(_data);        
   }



  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={12} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='row'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <TextField sx={{width:'calc(10% - 8px)'}} id="id" label="Id" {...register('id')} inputProps={ {readOnly: true}} /> 
                                <TextField sx={{width:'calc(25% - 8px)'}} id="name" label={t('Name')} {...register('name')} />
                                <TextField sx={{width:'calc(35% - 8px)'}} id="description" label={t('Description')} {...register('description')} />
                                <input type="file" id="file-upload" style={{ display: "none" }} onChange={setFile}/>
                                <Button onClick={openFileDialog}>
                                    {isFalsy(getValues().fileName) ? `${t('Click to toad file')} ...`: `${getValues().fileName} -> Click to change`}
                                </Button>
                            </Box>
                        </Stack>                        
                    </Grid>
                    <Grid item xs={12} md={7} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                        <Stack flexDirection='column'>
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <ArrayFieldTableEx<IExternalLibrary,IExternalLibraryClass,'id'> 
                                        mainObject={getValues()} fieldKey='className' 
                                        headCells={headExternalLibraryClassCells} rowsPathName='externalLibraryClasses' 
                                        title={t('Classes')} 
                                        onRowSelected={handleExternalLibraryClassSelected}
                                                            
                                        //refAppend={refAppendRoleEntities as MutableRefObject<(value: Partial<FieldArray<IRole>> | Partial<FieldArray<IRole>>[], options?: FieldArrayMethodProps) => void>}
                                        //refUpdate={refUpdateRoleEntity as MutableRefObject<(index: number,value: Partial<FieldArray<IRole>>) => void>}
                                        //refRemove={refRemoveRoleEntity as MutableRefObject<(index: number) => void>}

                                        //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                        //displayMore={undefined}
                                        toolbarActions={[                                                                               
                                        ]}
                                    />
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={5} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                        <Stack flexDirection='column'>
                            <Box sx={{ mt: 1, width: '100%' }} >
                                List element lib...
                            </Box>
                        </Stack>
                    </Grid>
                    
                </Grid>
            </Box>
        </FormProvider> 
  )
}



