
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {IGroup, IGroupSearch } from "../models/Group";
import { useTranslation  } from 'react-i18next';
import { ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';

import { currentUserSessionAtom } from 'library/store';


const _ = () => {

    const axios = useAxios(); 
   

    const createGroup = async (group: IGroup)  =>       
        await (await axios.post('/api/security/group/create', group)).data;       
        
    const updateGroup = async (group: IGroup)  =>       
        await (await axios.post('/api/security/group/update', group)).data; 
    
    const getGroup = async (id  : number )  => {
      const {data} = (await axios.get(`/api/security/group/get-group/${id}`));
      return await data;
    }
   

    const getGroups = async (criteria: IGroupSearch) : Promise<IGroup[]> => {

      const { name, description} = criteria;

      const {data} = (await axios.get(`/api/security/group/get-groups?name=${name}&description=${description}`));
      return await data;
    }
    
      
    return {    
      createGroup,
      updateGroup,
      getGroup,
      getGroups
      
    } 
}

export default _;

export interface IFilterGroupOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [IGroup[], React.Dispatch<React.SetStateAction<IGroup[]>>],
}

const defaultFilterGroupOption: IFilterGroupOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterGroup = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IGroup) => void,
                                            filterOption?: IFilterGroupOption  ) => {

  const { getGroups } = _();

  const { t, i18n } = useTranslation();   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterGroupOption;



  const [headGroupCells, setHeadGroupCells]  = useState<HeadCell<IGroup>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    
    {id:'name', label : t('Name'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },
    

  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
         
      //{name: 'code', text: t('Code'), value: ''},
      {name: 'name', text: t('Name'), value: ''},
      {name: 'description', text: t('Description'), value: ''},
      
    ]);    

  const [filteredGroups, ] = useState<IGroup[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]) : Promise<IGroup[]> => {
       
    
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
       
    const arr = await getGroups( { name,description} );
    
    return arr;
  }

  const objKey: keyof IGroup = 'id';

  return {
    title: t('Group'), headCells: headGroupCells, objKey,
    filterElements, rows: filteredGroups, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
