
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import { IDashboardMessage, IMessage, IMessageSearch } from "../models/Message";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell } from 'components/ui/EnhancedTable';


import { currentUserSessionAtom } from 'library/store';


const _ = () => {

    const axios = useAxios(); 

    
    const createMessage = async (message: IMessage)  =>       
        await (await axios.post('/api/production/message/create', message)).data;       
        
    const updateMessage = async (message: IMessage)  =>       
        await (await axios.post('/api/production/message/update', message)).data; 
    
    const getMessage = async (id  : number )  => {
      const {data} = (await axios.get(`/api/production/message/get-message/${id}`));
      return await data;
    }

    const getMessages = async (criteria: IMessageSearch, pagination?: IPagination) : Promise<IMessage[]> => {

      const {type, messageBody, fileName} = criteria;
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;
      
      const {data} = (await axios.get(`/api/production/message/get-messages?type=${type}&` +
          `messageBody=${messageBody}&fileName=${fileName}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
      }
  
      const generateContractMessageXlsx = async (exportRequest: any) : Promise<{fileToken: string, fileName: string}> => {
           
        const {data} = (await axios.post(`/api/production/message/generate-contract-messages-xlsx`, exportRequest));
        return await data;
      }
  
      const getContractMessagesDashboard = async () : Promise<IDashboardMessage> => {
        const {data} = (await axios.get(`/api/production/message/get-contract-messages-dashboard`));
        return await data;
      }

         
    return {    
      createMessage,
      updateMessage,
      
      getMessages,      
      generateContractMessageXlsx,
      getContractMessagesDashboard
      
    } 
}

export default _;

export const useBasicFilterMessage = ( onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IMessage) => void ) => {

  const { t, i18n } = useTranslation();   
  const { getMessages } = _();

  const [headMessageCells, setHeadMessageCells]  = useState<HeadCell<IMessage>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', width: 5 },
    {id:'type', label : t('Type'),  display: true, type: 'string', width: 10},
    {id:'messageBody', label : t('MessageBody'),  display: true, type: 'string', width: 35},
   // {id:'fileName', label : t('File'),  display: true, type: 'string', width: 15},
    {id:'isSended', label : t('Is Sended ?'),  display: true, type: 'boolean', width: 15},
    {id:'statusText', label : t('Status'),  display: true, type: 'string', width: 15},
    {id:'sendingDate', label : t('Sending date'),  display: true, type: 'datetime', width: 10},
    {id:'recipientNumber', label : t('Recipient'),  display: true, type: 'string', width: 10},
    
    //{id:'validityEndDate', label : t('Expiration date'),  display: true, type: 'date', width: 10},
  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
      
      {name: 'type', text: t('Type'), value: '', dataType: 'enumeration', 
          options: [ {value: '', name: t('All')}, 
                     {value: 'text', name: t('Text')}, 
                     {value: 'image', name: t('Image') }, 
                     {value: 'document', name: t('Document')}, 
                     {value: 'video', name: t('Video') },
                     {value: 'audio', name: t('Audio')}]},
      {name: 'messageBody', text: t('MessageBody'), value: ''},
      {name: 'fileName', text: t('File name'), value: ''},
      
    ]);    
  const [filteredMessages, ] = useState<IMessage[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IMessage[]> => {    
    
    const type = filterElements.find( elt => elt.name === 'type')?.value || '';
    const messageBody = filterElements.find( elt => elt.name === 'messageBody')?.value || '';
    const fileName = filterElements.find( elt => elt.name === 'fileName')?.value || '';    
        
    const arr = await getMessages( {type, messageBody, fileName}, pagination );
    
    return arr.map(x => ({...x, statusText: x.status === 1? t('Delivered'): x.status === 2 ? t('Read') : t('Sended') }) );
  }

  const objKey: keyof IMessage = 'id';

  return {
    title: t('Message'), headCells: headMessageCells, objKey,
    filterElements, rows: filteredMessages, 
    onFilterButtonClick, onRowDoubleClick
  }
}


