import { HeadCell } from 'components/ui/EnhancedTable';
import useAxios from 'library/axios'; 
import { useState } from 'react';
import {IContract,  IContractDashboardDay,  IContractMessage,  IContractMessageDashboardDay,  IContractSearch, IPhoneInformation, MessageFilter } from "../models/Contract";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { useNavigate } from 'react-router-dom';


const _ = () => {

    const axios = useAxios();     

    const createContract = async (contract: IContract)  =>       
        await (await axios.post('/api/production/contract/create', contract)).data;       
        
    const updateContract = async (contract: IContract)  =>       
        await (await axios.post('/api/production/contract/update', contract)).data; 

    const setContractMessageNonTicketable = async (request: any)  =>       
        await (await axios.post('/api/production/contract/set-contract-message-non-ticketable', request)).data;  

    const setContractMessageTicketable = async (request: any)  =>       
        await (await axios.post('/api/production/contract/set-contract-message-ticketable', request)).data;  
    
    const getContract = async (id  : number )  => {
      const {data} = (await axios.get(`/api/production/contract/get-contract/${id}`));
      return await data;
    }

    const getContracts = async (criteria: IContractSearch) : Promise<IContract[]> => {
      const {description, whatsAppNumber} = criteria;

      const {data} = (await axios.get(`/api/production/contract/get-contracts?description=${description}&whatsAppNumber=${whatsAppNumber}`));
      return await data;
    }

    const getAllContracts = async (criteria: IContractSearch) : Promise<IContract[]> => {
      const {description} = criteria;

      const {data} = (await axios.get(`/api/production/contract/get-contracts?description=${description}`));
      return await data;
    }

    const scheduleRefreshContractMessagesForChatId = async (contractId: number, chatId: string)  =>       
        await (await axios.post(`/api/production/contract/schedule-refresh-contract-messages-for-chatId?contractId=${contractId}&chatId=${chatId}`, {})).data; 

    const getContractActivation = async (contractId: number) : Promise<string> => {
      const {data} = (await axios.get(`/api/production/contract/get-contract-activation?contractId=${contractId}`));
      return await data;
    }

    // : Promise<{status: {accountStatus: {status: string, substatus: string}}}>
    const getContractStatus = async (contractId: number)  => {
      const {data} = (await axios.get(`/api/production/contract/get-conctract-status?contractId=${contractId}`));
      return await data;
    }

    const getContractPhoneInformation = async (contractId: number) : Promise<IPhoneInformation>  => {
      const {data} = (await axios.get(`/api/production/contract/get-conctract-phone-information?contractId=${contractId}`));
      return await data;
    }

    const getContractMessagesByPage = async (contractIds: number[], whatsAppId: string, messageFilter: MessageFilter ,pagination?: IPagination) : Promise<IContractMessage[]> => {
      const ids = contractIds.join(':-:');

      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;
      
      const {data} = (await axios.get(`/api/production/contract/get-contractMessages-by-page?contractIds=${ids}&whatsAppId=${whatsAppId}&messageFilter=${messageFilter}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

    const getContractMessagesByQuotedMessageId = async (quotedMessageId: string) : Promise<IContractMessage[]> => {
            
      const {data} = (await axios.get(`/api/production/contract/get-contractMessages-by-quotedMessageId?quotedMessageId=${quotedMessageId}`));
      return await data;
    }

    const getDayDashboard = async (dateFrom: Date, dateTo: Date) : Promise<IContractDashboardDay> => {
            
      const sDateFrom = encodeURIComponent(dateFrom.toISOString().split('T')[0]);
      const sDateTo = encodeURIComponent(dateTo.toISOString().split('T')[0]);

      const {data} = (await axios.get(`/api/production/contract/get-contract-day-dashboard?sDateFrom=${sDateFrom}&sDateTo=${sDateTo}`));
      return await data;
    }

    const getContractMessageDayDashboard = async (dateFrom: Date, dateTo: Date) : Promise<IContractMessageDashboardDay> => {
            
      const sDateFrom = encodeURIComponent(dateFrom.toISOString().split('T')[0]);
      const sDateTo = encodeURIComponent(dateTo.toISOString().split('T')[0]);

      const {data} = (await axios.get(`/api/production/contract/get-contract-message-day-dashboard?sDateFrom=${sDateFrom}&sDateTo=${sDateTo}`));
      return await data;
    }
      
    return {    
      createContract,
      updateContract,

      setContractMessageNonTicketable,
      setContractMessageTicketable,

      getContract,
      getContracts,

      getContractMessagesByPage,
      getContractMessagesByQuotedMessageId,

      getDayDashboard,
      getContractMessageDayDashboard,

      scheduleRefreshContractMessagesForChatId,

      getContractStatus,
      getContractActivation,

      getContractPhoneInformation,      
    } 
}

export default _;


export const useBasicFilterContract = ( onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IContract) => void  ) => {

  const { getContracts } = _();

  const { t, i18n } = useTranslation();   
  const navigate = useNavigate();

  

  const [headContractCells, setHeadContractCells]  = useState<HeadCell<IContract>[]>([
    {id:'login', label : t('Name'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },

    {id:'whatsAppNumber', label : t('WhatsApp'),  display: true, type: 'string', },    
  ]); 

  const [filterElements, ] = useState([       
        {name: 'description', text: t('Description'), value: ''}, 
        {name: 'whatsAppNumber', text: t('Number'), value: ''}, 
         
      ]);

  const [filteredContracts, ] = useState<IContract[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]): Promise<IContract[]> => {
            
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
    const whatsAppNumber = filterElements.find( elt => elt.name === 'whatsAppNumber')?.value || '';

    const arr = await getContracts( {description, whatsAppNumber} );
    
    return arr;
  }

  const objKey: keyof IContract = 'id';

  return {
    title: t('Contract'), headCells: headContractCells, objKey,
    filterElements, rows: filteredContracts, 
    onFilterButtonClick, onRowDoubleClick
  }
}

