import { HeadCell } from 'components/ui/EnhancedTable';
import useAxios from 'library/axios'; 
import { useState } from 'react';
import {ISmsApi,  ISmsApiSearch } from "../models/SmsApi";
import { useTranslation  } from 'react-i18next';
import { ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { useNavigate } from 'react-router-dom';


const _ = () => {

    const axios = useAxios();     

    const createSmsApi = async (smsapi: ISmsApi)  =>       
        await (await axios.post('/api/misc/smsapi/create', smsapi)).data;       
        
    const updateSmsApi = async (smsapi: ISmsApi)  =>       
        await (await axios.post('/api/misc/smsapi/update', smsapi)).data; 
    
    const getSmsApi = async (id  : number )  => {
      const {data} = (await axios.get(`/api/misc/smsapi/get-smsapi/${id}`));
      return await data;
    }

    const getSmsApis = async (criteria: ISmsApiSearch) : Promise<ISmsApi[]> => {
      const {name, description} = criteria;

      const {data} = (await axios.get(`/api/misc/smsapi/get-smsapis?name=${name}&description=${description}`));
      return await data;
    }
      
    return {    
      createSmsApi,
      updateSmsApi,

      getSmsApi,
      getSmsApis
      
    } 
}

export default _;


export const useBasicFilterSmsApi = ( onRowDoubleClick: (event: React.MouseEvent<unknown>, row: ISmsApi) => void  ) => {

  const { getSmsApis } = _();

  const { t, i18n } = useTranslation();   
  const navigate = useNavigate();
  

  const [headJobCells, setHeadJobCells]  = useState<HeadCell<ISmsApi>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', width: 5 },
    {id:'name', label : t('Name'),  display: true, type: 'string', width: 20 },
    {id:'isActive', label : t('Active ?'),  display: true, type: 'boolean', width: 15},
    {id:'description', label : t('Description'),  display: true, type: 'string', width: 25},
    {id:'urlSendMessageApi', label : t('Url message (send)'),  display: true, type: 'string', width: 35},
  ]); 

  const [filterElements, ] = useState([       
        {name: 'name', text: t('Name'), value: ''}, 
        {name: 'description', text: t('Description'), value: ''},       
      ]);

  const [filteredReports, ] = useState<ISmsApi[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]): Promise<ISmsApi[]> => {
            
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
    
    const arr = await getSmsApis( {name, description} );
    
    return arr;
  }

  const objKey: keyof ISmsApi = 'id';

  return {
    title: t('Request data'), headCells: headJobCells, objKey,
    filterElements, rows: filteredReports, 
    onFilterButtonClick, onRowDoubleClick
  }
}

