
export type MessageType = 'text' | 'image' | 'document' | 'video' ;




export interface IMessage {
    id: number,
    messageReference:string,
    contractId: number,

    validityStartDate: Date,
    validityEndDate: Date,
    recipientNumber: string,
    isSended: boolean,
    sendingDate: Date,
    quotedMessageId: string,
    lastErrorMessage: string,

    type: MessageType,

    messageBody: string,   
    fileCaption: string,
    fileName: string,
    base64File?: string | ArrayBuffer | null,

    status: number,
    statusText: string,
   
    currentRecipient: string
  }

  


 


  export interface IMessageSearch {
    messageBody: string,
    type: string,
    fileName: string,
  }

  export interface IDashboardMessage {
    countMessageYear: number,
    countMessageDeliveryYear: number,

    countMessageMonth: number,
    countMessageDeliveryMonth: number,

    countReceivededMessageMonth: number,    
    countRecipientYear: number,

    barChatLabels: string[],
    countMessages: number[],
    countDeliveryMessages: number[],

    monthMessagesByType: {label: string, value: number}[]
  }

  
  export const defaultMessage : IMessage = {

    id: 0,
    messageReference: '',
    contractId: 0,
    
    validityStartDate: new Date(),
    validityEndDate: new Date(),

    recipientNumber: '',
    isSended: false,
    sendingDate: new Date(),
    quotedMessageId: '',
    lastErrorMessage: '',

    type: 'text',

    messageBody: '',
    fileCaption: '',
    fileName: '',

    status: 0,
    statusText: '',    
    
    currentRecipient: ''
  }

  export const defaultDashboardMessage : IDashboardMessage = {
    countMessageYear: 0,
    countMessageDeliveryYear: 0,

    countMessageMonth: 0,
    countMessageDeliveryMonth: 0,

    countReceivededMessageMonth: 0,    
    countRecipientYear: 0,

    barChatLabels: [],
    countMessages: [],
    countDeliveryMessages: [],

    monthMessagesByType: []

  }

  