import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { HeadCell } from 'components/ui/EnhancedTable';
import useAxios from 'library/axios'; 
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IRole } from '../models/Role';
import {IUser, IUserSearch} from '../models/User';


const _ = () => {

    const axios = useAxios();    

    const createUser = async (user: IUser)  =>       
        await (await axios.post('/api/security/user/create', user)).data;

    const updateUser = async (user: IUser)  =>       
        await (await axios.post('/api/security/user/update', user)).data;

    const getUser = async (userId  : number )  => {        

      
      
        const {data} = (await axios.get(`/api/security/user/getUser/${userId}`));
        return await data;
      }

    const getUsers = async (criteria  : IUserSearch, pagination?: IPagination)  => {
        const pageSize = pagination?.pageSize ?? 50;
        const pageNumber = pagination?.pageNumber ?? 1;

        const { userName,userDescription} = criteria;

        const {data} = (await axios.get(`api/security/user/get-users?userName=${userName}&userDescription=${userDescription}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
        return await data;
      }

    

      
    return {    
      createUser,  
      updateUser,  
        getUser,

        getUsers,
      
    } 
}

export default _;

export const useBasicFilterUser = ( onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IUser) => void  ) => {

  const { getUsers } = _();

  const { t, i18n } = useTranslation();   
  const navigate = useNavigate();

  const [headUserCells, setHeadUserCells]  = useState<HeadCell<IUser>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    {id:'userName', label : t('User name'),  display: true, type: 'string', },
    {id:'userDescription', label : t('User description'),  display: true, type: 'string', },

  ]); 
  const [filterElements,] = useState( [       
      {name: 'userName', text: t('Name'), value: ''}, 
      {name: 'userDescription', text: t('Description'), value: ''},     
    ]);    

  const [filteredUsers, ] = useState<IUser[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination): Promise<IUser[]> => {
            
    const userName = filterElements.find( elt => elt.name === 'userName')?.value || '';
    const userDescription = filterElements.find( elt => elt.name === 'userDescription')?.value || '';
    
    const arr = await getUsers( {userName, userDescription}, pagination );
    
    return arr;
  }

  const objKey: keyof IUser = 'id';

  return {
    title: t('Users'), headCells: headUserCells, objKey,
    filterElements, rows: filteredUsers, 
    onFilterButtonClick, onRowDoubleClick
  }
}



// export const useBasicFilterCoreBusinessUser = ( getRequestTypeBusinessAppId: () => number,
//     onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IRequestDataCoreMaster) => void ) => {

//   const { t, i18n } = useTranslation();   
//   const { enqueueSnackbar } = useSnackbar();
//   const { getCoreBusinessMasters } = _();

//   const [headCoreMasterCells, ]  = useState<HeadCell<IRequestDataCoreMaster>[]>([
//       {id:'contractNumber', label : t('Contract N°'),  display: true, type: 'string', },
//       {id:'coreKey', label : t('Ref. N°'),  display: true, type: 'string', },
//       {id:'customerName', label : t('Customer'),  display: true, type: 'string', },
//       {id:'effectiveDate', label : t('Effective date'),  display: true, type: 'date', },
//       {id:'expirationDate', label : t('Expiration date'),  display: true, type: 'date', },

//   ]);  
//   const [filterElements,] = useState<ITextFilterElement[]>( [
//       {name: 'coreKey', text: t('Ref. N°'), value: ''},  
//       {name: 'contractNumber', text: t('Contract'), value: ''},       
//       {name: 'customerName', text: t('Customer'), value: ''},   
//       {name: 'productName', text: t('Product'), value: ''},      
//     ]);    
//   const [filteredCoreMasters, ] = useState<IRequestDataCoreMaster[]>([]); 

//   const onFilterButtonClick = async (filterElements: ITextFilterElement[]) : Promise<IRequestDataCoreMaster[]> => {

//     const requestTypeBusinessAppId = getRequestTypeBusinessAppId();
//     if(requestTypeBusinessAppId <= 0) return [];
    
//     const coreKey = filterElements.find( elt => elt.name === 'coreKey')?.value || '';
//     const contractNumber = filterElements.find( elt => elt.name === 'contractNumber')?.value || '';
    
//     const customerName = filterElements.find( elt => elt.name === 'customerName')?.value || '';
//     const productName = filterElements.find( elt => elt.name === 'productName')?.value || '';

//     if( (coreKey.trim() + contractNumber.trim() + customerName.trim()).length < 5) {
//       enqueueSnackbar( t('The minimum length for filter business entity is 5 !!!'), { variant: 'warning',
//             anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
//       return [];
//     }

    
//     const arr = await getCoreBusinessMasters( requestTypeBusinessAppId, {coreKey,contractNumber,customerName,productName} );
    
//     return arr;
//   }

//   const objKey: keyof IRequestDataCoreMaster = 'id';

//   return {
//     title: t('Request data'), headCells: headCoreMasterCells, objKey,
//     filterElements, rows: filteredCoreMasters, 
//     onFilterButtonClick, onRowDoubleClick
//   }
// }