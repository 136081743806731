

import React from 'react';
import { Navigate, Outlet, Route, Routes} from 'react-router-dom';


import {  useRecoilValue, } from 'recoil';

///
import { currentUserSessionAtom } from 'library/store';

import { Login } from './Login';
import { Home } from 'features/Home';

import { ApplicationSetupForm, defaultApplicationSetup,
    ApplicationQueryForm, defaultApplicationQuery, MessageAutomationForm, defaultMessageAutomation,} from 'features/setup';

import { RoleForm, defaultRole,
         UserForm, defaultUser } from 'features/security';

import { ExternalLibraryForm, defaultExternalLibrary,
         EnumerationForm, defaultEnumeration,
         ReportForm, defaultReport,
         ExtensionTypeForm, defaultExtensionType } from 'features/configuration';


import { 
         MessageForm, defaultMessage,
         ChatRoomForm, defaultChatRoom, ContactForm, defaultContact,
         ContractForm, defaultContract, TicketForm, defaultTicket, ProblemForm, defaultProblem, PersonForm, defaultPerson, ContractMessageDashboardForm, ContractDashboardForm, TicketDashboardForm, ContactDashboardForm } from 'features/production';

import { JobForm, defaultJob,
         SmsApiForm, defaultSmsApi } from 'features/misc';


import { PasswordChangeForm, defaultPasswordChange } from 'features/identity';


            

const ProtectedRoute = ( props: {redirectPath?: string} ) : React.ReactElement => {
    const {redirectPath} = props;

    const {isAuthenticated } = useRecoilValue(currentUserSessionAtom);
    
    return !isAuthenticated ? <Navigate to={redirectPath || '/login'} replace /> : <Outlet />;
}


export const AppRoute = () => {
    
    return (
    <Routes>
        <Route index element={<Login />} />
        <Route path="login" element={<Login />} />
        
        <Route element={<ProtectedRoute />}>
          <Route path="home" element={<Home />} />

          {/* ------ Setup ----- */}
          <Route path="applicationSetup/:id" element={<ApplicationSetupForm {...defaultApplicationSetup} />} />          
          <Route path="applicationQuery/:id" element={<ApplicationQueryForm {...defaultApplicationQuery} />} />

          <Route path="messageAutomation/:id" element={<MessageAutomationForm {...defaultMessageAutomation} />} />

          {/* ------ Security ----- */}
          <Route path="role/:id" element={<RoleForm {...defaultRole} />} />
          <Route path="user/:id" element={<UserForm {...defaultUser} />} />

          {/* ------ Configuration ----- */}
          <Route path="externalLibrary/:id" element={<ExternalLibraryForm {...defaultExternalLibrary} />} />
          <Route path="extensionType/:id" element={<ExtensionTypeForm {...defaultExtensionType} />} />
          <Route path="enumeration/:id" element={<EnumerationForm {...defaultEnumeration} />} />
          <Route path="report/:id" element={<ReportForm {...defaultReport} />} />
        
          {/* ------ Production ----- */}
          
          <Route path="contract/:id" element={<ContractForm {...defaultContract} />} />
          <Route path="message/:id" element={<MessageForm {...defaultMessage} />} />
          <Route path="chatroom/:id" element={<ChatRoomForm {...defaultChatRoom} />} />
          <Route path="contact/:id" element={<ContactForm {...defaultContact} />} />

          <Route path="ticket/:id" element={<TicketForm {...defaultTicket} />} />
          <Route path="problem/:id" element={<ProblemForm {...defaultProblem} />} />

          <Route path="person/:id" element={<PersonForm {...defaultPerson} />} />

          {/* ------ Production ----- */}
          <Route path="contractDashboard/:id" element={<ContractDashboardForm  />} />
          <Route path="contractMessageDashboard/:id" element={<ContractMessageDashboardForm  />} />
          <Route path="ticketDashboard/:id" element={<TicketDashboardForm  />} />
          <Route path="contactDashboard/:id" element={<ContactDashboardForm  />} />


          {/* ------ Misc ----- */}
          <Route path="job/:id" element={<JobForm {...defaultJob} />} />
          <Route path="smsApi/:id" element={<SmsApiForm {...defaultSmsApi} />} />

          {/* ------ User profile ----- */}
          <Route path="passwordChange" element={<PasswordChangeForm {...defaultPasswordChange} />} />
          
        </Route>
        
        <Route path="*" element={<p>There's nothing here: 404!</p>} />
    </Routes>
    );
    
}


