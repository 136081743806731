import { HeadCell } from 'components/ui/EnhancedTable';
import useAxios from 'library/axios'; 
import { useState } from 'react';
import {ITicket,  ITicketDashboardDay, INyxisDashboardDay,  ITicketSearch } from "../models/Ticket";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { useNavigate } from 'react-router-dom';


const _ = () => {

    const axios = useAxios();     

    const createTicket = async (ticket: ITicket)  =>       
        await (await axios.post('/api/production/ticket/create', ticket)).data;       
        
    const updateTicket = async (ticket: ITicket)  =>       
        await (await axios.post('/api/production/ticket/update', ticket)).data; 

    const attachMessageToTicket = async (request: any)  =>       
        await (await axios.post('/api/production/ticket/attach-message-to-ticket', request)).data;  
    
    const getTicket = async (id  : number )  => {
      const {data} = (await axios.get(`/api/production/ticket/get-ticket/${id}`));
      return await data;
    }

    const getTickets = async (criteria: ITicketSearch, pagination?: IPagination) : Promise<ITicket[]> => {
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {title, description, contactName, contactWhatsAppId, assignedUserName} = criteria;

      const {data} = (await axios.get(`/api/production/ticket/get-tickets?description=${description}&title=${title}&` + 
                      `contactName=${contactName}&contactWhatsAppId=${contactWhatsAppId}&assignedUserName=${assignedUserName}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

    const getLastActiveTicketsForMessage = async (contractMessageId: number) : Promise<ITicket[]> => {
      
      const {data} = (await axios.get(`/api/production/ticket/get-last-active-tickets-for-message?contractMessageId=${contractMessageId}`));
      return await data;
    }

    const getDayDashboard = async (dateFrom: Date, dateTo: Date) : Promise<ITicketDashboardDay> => {
            
      const sDateFrom = encodeURIComponent(dateFrom.toISOString().split('T')[0]);
      const sDateTo = encodeURIComponent(dateTo.toISOString().split('T')[0]);

      const {data} = (await axios.get(`/api/production/ticket/get-ticket-day-dashboard?sDateFrom=${sDateFrom}&sDateTo=${sDateTo}`));
      return await data;
    }

    const getNyxisDayDashboard = async (dateFrom: Date, dateTo: Date) : Promise<INyxisDashboardDay> => {
            
      const sDateFrom = encodeURIComponent(dateFrom.toISOString().split('T')[0]);
      const sDateTo = encodeURIComponent(dateTo.toISOString().split('T')[0]);

      const {data} = (await axios.get(`/api/production/ticket/get-nyxis-day-dashboard?sDateFrom=${sDateFrom}&sDateTo=${sDateTo}`));
      return await data;
    }
      
    return {    
      createTicket,
      updateTicket,

      attachMessageToTicket,

      getTicket,
      getTickets,
      
      getLastActiveTicketsForMessage,

      getDayDashboard,
      getNyxisDayDashboard
    } 
}

export default _;


export const useBasicFilterTicket = ( onRowDoubleClick: (event: React.MouseEvent<unknown>, row: ITicket) => void  ) => {

  const { getTickets } = _();

  const { t, i18n } = useTranslation();   
  const navigate = useNavigate();

  

  const [headTicketCells, setHeadTicketCells]  = useState<HeadCell<ITicket>[]>([
    {id:'title', label : t('Title'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },
    {id:'issueDate', label : t('Date'),  display: true, type: 'date', },
  ]); 

  const [filterElements, ] = useState([       
        {name: 'title', text: t('Title'), value: ''}, 
        {name: 'description', text: t('Description'), value: ''}, 
        {name: 'contactName', text: t('Contact'), value: ''}, 
        {name: 'contactWhatsAppId',text: 'WhatsApp', value: ''}, 
        {name: 'assignedUserName', text: t('User name'), value: ''}, 
         
      ]);

  const [filteredTickets, ] = useState<ITicket[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination): Promise<ITicket[]> => {
            
    const title = filterElements.find( elt => elt.name === 'title')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
    const contactName = filterElements.find( elt => elt.name === 'contactName')?.value || '';
    const contactWhatsAppId = filterElements.find( elt => elt.name === 'contactWhatsAppId')?.value || '';
    const assignedUserName = filterElements.find( elt => elt.name === 'assignedUserName')?.value || '';

    const arr = await getTickets( {title, description, contactName, contactWhatsAppId, assignedUserName}, pagination );
    
    return arr;
  }

  const objKey: keyof ITicket = 'id';

  return {
    title: t('Ticket'), headCells: headTicketCells, objKey,
    filterElements, rows: filteredTickets, 
    onFilterButtonClick, onRowDoubleClick
  }
}

