
export type ContractMessageType = 'text' | 'image' | 'document' | 'video' ;

export type MessageFilter = 'all' | 'new' | 'ticketable' | 'non-ticketable' | 'assigned-to-ticket';

  export interface IContract {
    id: number,

    description: string,
    whatsAppId: string,

    instanceId: string,
    token: string,
    status: string,
    statusDate: Date,
    identityToken: string,
    whatsAppNumber: string,
    login: string,
    webhookUrl: string,

    isActive: boolean,
    isActivated: boolean,

    contractType: string,
    initialCountryNumber: string,
    allowExternalSending: boolean,
    allowMessageManagement: boolean,

    password: string,
    passwordMD5: string,
    isPasswordChanged: boolean,

    whatsAppImageUrl: string,
    publicWhatsAppImageUrl: string,

    contractInnerStatus: string,
    contractInnerStatusDescription: string,

    hookDatas: any[],    

    profilePicture: string
  }
 

  export interface IContractMessage {
    id: number,
    contractId: number,
    messageId: string,
    fromWhatsAppId: string,
    toWhatsAppId: string,
    messageAck: string,
    body: string,

    type: ContractMessageType,

    ticketMessageCount: number,
    replyCount: number,
    canBeTicketed?: boolean,

    messageDate: Date,

    mediaUrl: string,

    contractWhatsAppId: string
  }

  export interface IContractDashboardDay {
    
    contractMessageCount: number,
    contractNonTicketableMessageCount: number,
    contractTicketableMessageCount: number,
    contractNewMessageCount: number,
              
    messageCountPerContract: {label: string, value: number}[],
    newMessageCountPerContract: {label: string, value: number}[],
  }

  export interface IContractMessageDashboardDay {
    
    messageCount: number,
    nonTicketableMessageCount: number,
    ticketableMessageCount: number,
    newMessageCount: number,
              
    messageCountPerTicketable: {label: string, value: number}[],
    messageCountPerTicketAssign: {label: string, value: number}[],
  }


  export const defaultContract : IContract = {
    id: 0,
    
    description: '',
    whatsAppId: '',

    instanceId: '',
    token: '',
    status: '',
    statusDate: new Date(),
    identityToken: '',
    whatsAppNumber: '',
    login: '',
    webhookUrl: '',

    isActive: true,
    isActivated: false,
    
    contractType: '',
    initialCountryNumber: '',
    allowExternalSending: true,
    allowMessageManagement: false,

    password: '',
    passwordMD5: '',
    isPasswordChanged: false,  

    whatsAppImageUrl: '',
    publicWhatsAppImageUrl: '',

    contractInnerStatus: '',
    contractInnerStatusDescription: '',

    hookDatas: [],    

    profilePicture: ''
  }

  export const defaultContractMessage : IContractMessage = {
    id: 0,
    
    contractId: 0,
    messageId: '',
    fromWhatsAppId: '',
    toWhatsAppId: '',
    messageAck: '',
    body: '',

    type: 'text',

    ticketMessageCount: 0,
    replyCount: 0,

    messageDate: new Date(),

    mediaUrl: '',

    contractWhatsAppId: ''
  }

  export const defaultContractDashboardDay : IContractDashboardDay = {
    
    contractMessageCount: 0,
    contractNonTicketableMessageCount: 0,
    contractTicketableMessageCount: 0,
    contractNewMessageCount: 0,
              
    messageCountPerContract: [],
    newMessageCountPerContract: []
  }

  export const defaultContractMessageDashboardDay : IContractMessageDashboardDay = {
    
    messageCount: 0,
    nonTicketableMessageCount: 0,
    ticketableMessageCount: 0,
    newMessageCount: 0,
              
    messageCountPerTicketable: [],
    messageCountPerTicketAssign: []
  }

  export interface IPhoneInformation {
    phoneNumber: string,
    contactName: string,
    profilePicture: string,
  }


  export interface IContractSearch {
  
    description: string,
    whatsAppNumber: string
  }