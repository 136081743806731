
export type TicketType = 'whatsApp' | 'email' | 'facebook' | 'twitter' | 'phoneCall' | 'mail' | 'linkedIn' | 'instagram' ;

export interface ITicket {
  id: number,
  
  contactId: number,
  assignedUserId: number,
  problemId: number,
  title: string,
  description: string,
  issueDate: Date,

  effectiveDate: Date,
  dueDate: Date,

  categoryCode: string,
  severityCode: string,
  resolutionCode: string,
  ticketClassCode: string,
  targetServiceCode: string,
  ticketEntityCode: string,

  status: string,
  statusDate: Date,
  statusPurpose: string,

  type: TicketType,

  contactName: string,
  contactDescription: string,
  contactWhatsApp: string,
  assignedUserName: string,
  fullContactId: string,

  problemTitle: string,

  initialContractMessageId: number,
  validateDuringCreation: boolean,

  newAttachedContractMessageId: number,

  ticketComments: ITicketComment[],
  ticketResolutions: ITicketResolution[],
  
  ticketMessages: ITicketMessage[]
}

export interface ITicketComment {
  id: number,
  ticketId: number,
  commentText: string,
  date: Date,
}

export interface ITicketResolution {
  id: number,
  ticketId: number,
  
  previousResolutionCode: string,
  nextResolutionCode: string,
  date: Date,
}

export interface ITicketMessage {
  id: number,

  ticketId: number,
  contractMessageId: number,

  contractMessageBody: string,
}

export interface INyxisDashboardDay {
  messageCount: number,
  contactCount: number,
  ticketCount: number,
  contractCount: number,
  
  classChatLabels: string[],
  categoryTicketCounts: number[],
  
  ticketCountPerUser: {label: string, value: number}[],

  avgMessageResponseTime: number
}

export interface ITicketDashboardDay {
    
  ticketCount: number,
  validatedTicketCount: number,
  unvalidatedTicketCount: number,
  cancelledTicketCount: number,
    
  validatedTicketCountPerCategory: {label: string, value: number}[],
  validatedTicketCountPerResolution: {label: string, value: number}[],

}

export const defaultTicketDashboardDay : ITicketDashboardDay = {
    
  ticketCount: 0,
  validatedTicketCount: 0,
  unvalidatedTicketCount: 0,
  cancelledTicketCount: 0,

  validatedTicketCountPerCategory: [],
  validatedTicketCountPerResolution: [],
}

export const defaultNyxisDashboardDay : INyxisDashboardDay = {
  messageCount: 0,
  contactCount: 0,
  ticketCount: 0,
  contractCount: 0,
  
  classChatLabels: [],
  categoryTicketCounts: [],
  
  ticketCountPerUser: [],

  avgMessageResponseTime: 0
}



  export const defaultTicket : ITicket = {
    id: 0,
    
    contactId: 0,
    assignedUserId: 0,
    problemId: 0,
    title: '',
    description: '',

    issueDate: new Date(),
    effectiveDate: new Date(),
    dueDate: new Date(),

    categoryCode: '',
    severityCode: '',
    resolutionCode: '',
    ticketClassCode: '',
    targetServiceCode: '',
    ticketEntityCode: '',

    status: '95',
    statusDate: new Date(),
    statusPurpose: '',

    type: 'whatsApp',

    contactName: '',
    contactDescription: '',
    contactWhatsApp: '',
    assignedUserName: '',
    fullContactId: '',

    problemTitle: '',

    initialContractMessageId: 0,
    validateDuringCreation: false,

    newAttachedContractMessageId: 0,



    ticketComments: [],
    ticketResolutions: [],

    ticketMessages: []

  }

  
  export interface ITicketSearch {    
    title: string,
    description: string,

    contactName: string,
    contactWhatsAppId: string,

    assignedUserName: string
  }