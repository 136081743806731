import { useState } from 'react';
import useAxios from 'library/axios'; 

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {IRole, IRoleSearch } from "../models/Role";
import { HeadCell } from 'components/ui/EnhancedTable';
import { ITextFilterElement } from 'components/ui/BasicTextFilterForm';


const _ = () => {

    const axios = useAxios();    

    const createRole = async (user: IRole)  =>       
        await (await axios.post('/api/security/role/create', user)).data;

    const updateRole = async (user: IRole)  =>       
        await (await axios.post('/api/security/role/update', user)).data;

    const getRole = async (userId  : number )  => {        
        const {data} = (await axios.get(`/api/security/role/get-role/${userId}`));
        return await data;
      }

    const getRoles = async (criteria  : IRoleSearch)  => {
        const { name, description} = criteria;

        const {data} = (await axios.get(`api/security/role/get-roles?name=${name}&description=${description}`));
        return await data;
      }
      
    return {    
      createRole,  
      updateRole,  
      getRole,

      getRoles,
      
    } 
}

export default _;



export const useBasicFilterRole = ( onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IRole) => void  ) => {

  const { getRoles } = _();

  const { t, i18n } = useTranslation();   
  
  const [headRoleCells, setHeadRoleCells]  = useState<HeadCell<IRole>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    {id:'name', label : t('Name'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },

  ]); 
  const [filterElements,] = useState( [       
      {name: 'name', text: t('Name'), value: ''}, 
      {name: 'description', text: t('Description'), value: ''},     
    ]);    

  const [filteredRoles, setFilteredRoles] = useState<IRole[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]): Promise<IRole[]> => {
            
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
    
    const arr = await getRoles( {name, description} );
    
    return arr;
  }

  const objKey: keyof IRole = 'id';

  return {
    title: t('Roles'), headCells: headRoleCells, objKey,
    filterElements, rows: filteredRoles, 
    onFilterButtonClick, onRowDoubleClick
  }
}


