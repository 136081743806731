import { HeadCell } from 'components/ui/EnhancedTable';
import useAxios from 'library/axios'; 
import { useState } from 'react';
import {IContact,  IContactDashboardDay,  IContactSearch  } from "../models/Contact";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { useNavigate } from 'react-router-dom';


const _ = () => {

    const axios = useAxios();     

    const createContact = async (contact: IContact)  =>       
        await (await axios.post('/api/production/contact/create', contact)).data;       
        
    const updateContact = async (contact: IContact)  =>       
        await (await axios.post('/api/production/contact/update', contact)).data; 
    
    const getContact = async (id  : number )  => {
      const {data} = (await axios.get(`/api/production/contact/get-contact/${id}`));
      return await data;
    }

    const getContacts = async (type: number,criteria: IContactSearch , pagination?: IPagination) : Promise<IContact[]> => {
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {name, description, portable, personName} = criteria;

      const {data} = (await axios.get(`/api/production/contact/get-contacts?type=${type}&name=${name}&description=${description}&portable=${portable}&personName=${personName}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

    const getContactsByPage = async (pagination?: IPagination) : Promise<IContact[]> => {
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/production/contact/get-contacts-by-page?pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

    const getDayDashboard = async (dateFrom: Date, dateTo: Date) : Promise<IContactDashboardDay> => {
            
      const sDateFrom = encodeURIComponent(dateFrom.toISOString().split('T')[0]);
      const sDateTo = encodeURIComponent(dateTo.toISOString().split('T')[0]);

      const {data} = (await axios.get(`/api/production/contact/get-contact-day-dashboard?sDateFrom=${sDateFrom}&sDateTo=${sDateTo}`));
      return await data;
    }
      
    return {    
      createContact,
      updateContact,

      getContact,
      getContacts,
      getContactsByPage,

      getDayDashboard

    } 
}

export default _;


export const useBasicFilterContact = ( type: number, onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IContact) => void  ) => {

  const { getContacts } = _();

  const { t, i18n } = useTranslation();   
  const navigate = useNavigate();

  

  const [headContactCells, setHeadContactCells]  = useState<HeadCell<IContact>[]>([
    {id:'personFullName', label : `${t('Person')} - ${t('Name')}`,  display: true, type: 'string', },

    {id:'name', label : t('Name'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },
    {id:'portable1', label : t('Portable'),  display: true, type: 'string', },
    {id:'portable2', label : t('Portable'),  display: true, type: 'string', },
    
  ]); 

  const [filterElements, ] = useState([       
        {name: 'name', text: t('Name'), value: ''}, 
        {name: 'description', text: t('Description'), value: ''}, 
        {name: 'portable', text: t('Portable'), value: ''}, 
        {name: 'personName', text: `${t('Person')} - ${t('Name')}`, value: ''}, 
      ]);

  const [filteredContacts, ] = useState<IContact[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination): Promise<IContact[]> => {
            
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
    const portable = filterElements.find( elt => elt.name === 'portable')?.value || '';
    const personName = filterElements.find( elt => elt.name === 'personName')?.value || '';

    const arr = await getContacts( type, {name,description,portable,personName}, pagination );
    
    return arr;
  }

  const objKey: keyof IContact = 'id';

  return {
    title: 'Contact', headCells: headContactCells, objKey,
    filterElements, rows: filteredContacts, 
    onFilterButtonClick, onRowDoubleClick
  }
}

