
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import {addDays} from 'date-fns';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import LinkIcon from '@mui/icons-material/Link';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import ReplyAllIcon from '@mui/icons-material/ReplyAll';

import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';


import { IMessage, defaultMessage, MessageType } from './models/Message';

import { IChatRoom, IContact, defaultChatRoom } from './models/Contact';

import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, 
    currentUserSessionAtom, colorsAtom, newContractMessagesAtom } from 'library/store';
import useMessageService, { useBasicFilterMessage } from './services/Message';
import useContactService, { useBasicFilterContact } from './services/Contact';
import useContractService from './services/Contract';

import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Avatar, Badge, Checkbox, Chip, CircularProgress, Container, Divider, FormControlLabel, IconButton, InputAdornment, 
    List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Menu, MenuItem, ToggleButton, ToggleButtonGroup, Tooltip, Typography, useTheme } from '@mui/material';
import NumberFormat from 'react-number-format';

import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';
import IEnumeration from 'features/configuration/models/Enumeration';
import { isFalsy } from 'utility-types';
import { justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import SearchIcon from '@mui/icons-material/Search';

import { globalConfig } from 'config';

import InfiniteScroll from 'react-infinite-scroll-component';
import { IContract, IContractMessage, MessageFilter } from './models/Contract';
import { MdOutlineAdd } from 'react-icons/md';

import { AiOutlineUserSwitch } from "react-icons/ai";
import { Delete, Favorite, Reply } from '@mui/icons-material';
import { ITicket,  defaultTicket } from './models/Ticket';
import useTicketService, {useBasicFilterTicket} from 'features/production/services/Ticket';
import { BasicTicketForm } from './BasicTicketForm';
import EnhancedTable from 'components/ui/EnhancedTable';
import { FaBars, FaFilter, FaUsers, FaTicketAlt, FaEnvelope, FaBell, FaLink, FaList,   } from 'react-icons/fa';



export const ChatRoomForm: FC<IChatRoom> = (props: IChatRoom = defaultChatRoom) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();

  const [Colors, setColors] = useRecoilState(colorsAtom);

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer } = entityService();

  const { createMessage, updateMessage } = useMessageService();
  const { getContracts, getContractMessagesByPage, setContractMessageNonTicketable, 
      setContractMessageTicketable, scheduleRefreshContractMessagesForChatId, getContractMessagesByQuotedMessageId } = useContractService();

  const { createTicket, attachMessageToTicket, getLastActiveTicketsForMessage } = useTicketService();

  const { getContactsByPage } = useContactService();

  const {language: lg, contractIds, userContracts , roleEntities, applicationSetup} = useRecoilValue(currentUserSessionAtom);

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);

  const [newContractMessages, setNewContractMessages] = useRecoilState(newContractMessagesAtom);
    
  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterMessage = useBasicFilterMessage( 
      (event: React.MouseEvent<unknown>, row: IMessage) => {
          
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const refCurrentMessageFilter = useRef<MessageFilter>('new');
  const refCurrentContractId = useRef<number>(0);
  const refOpenedMessageId = useRef<number>(0);

  const [openContactFilter, setOpenContactFilter] = useState(false);
  const basicFilterContact = useBasicFilterContact( 1,
      async (event: React.MouseEvent<unknown>, row: IContact) => {
          const {id, name, description, whatsAppId} = row;

          setCurrentWhatsAppId(whatsAppId);
          setCurrentWhatsAppName(name);

          if(refCurrentContractId.current > 0)
            await scheduleRefreshContractMessagesForChatId(refCurrentContractId.current, whatsAppId);

          await displayMessages(refCurrentContractId.current, whatsAppId, refCurrentMessageFilter.current);   
                           
          setOpenContactFilter(false);
      }
  );

  const methods = useForm<IMessage>({defaultValues:{...defaultMessage,validityStartDate:new Date(), validityEndDate: addDays(new Date(), 1)}});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;
  
  const { register: registerTicket, setValue: setValueTicket, getValues: getValuesTicket, 
      watch: watchTicket, reset: resetTicket, control: controlTicket  } = useForm<ITicket>({defaultValues:defaultTicket});

  const { register: registerMessage, setValue: setValueMessage, getValues: getValuesMessage, 
    watch: watchMessage, reset: resetMessage, control: controlMessage  } = useForm<IMessage>({defaultValues:defaultMessage});

  const watchMessageType = watchMessage('type');
  const watchMessageFileName = watchMessage('fileName');
  const watchMessageBase64File = watchMessage('base64File');

  // const watchType = watch('type');
  // const watchRecipientNumber = watch('recipientNumber');
  // const watchFileName = watch('fileName');
  // const watchBase64File = watch('base64File');

  const queryClient = useQueryClient();
  // const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IMessage>,Error,IMessage>(
  //     _id>0?updateMessage:createMessage, {   
  //       onSuccess: (data: IResult<IMessage>) => {
  //         enqueueSnackbar( '_Operation_done', { variant: 'success',
  //               anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
  //         //reset(data.data);
  //         _setId(data.data.id);
  //         //setCurrentEntityIdForAction(data.data.id);
          
  //         queryClient.invalidateQueries(['Message',data.data.id]);
  //         const btn = document.getElementById(`btnNew`);
  //         btn?.click();
  //       },
  //       onError: (err: Error) => {          
          
  //         enqueueSnackbar( error?.message, { variant: 'error',
  //               anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
  //       }
  //     });

    const {mutate : mutateCreateTicket } = useMutation<IResult<ITicket>,Error,ITicket>(
      createTicket, {   
        onSuccess: (data: IResult<ITicket>) => {
          enqueueSnackbar( `${t('Operation done !!!')} - ${t('Ticket')} - # ${data.data.id} # `, { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 3000 }); 

          const updatedMsgs = messages.map(msg => {              
            if (msg.id === data.data.newAttachedContractMessageId) {
                return { ...msg, ticketMessageCount: msg.ticketMessageCount+1 };
            }
            return msg;
          });
          setMessages(updatedMsgs);    

          setOpenCreateNewTicket(false);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( err?.message, { variant: 'error',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });            
        }
      });

      const {mutate : mutateAttachMessageToTicket } = useMutation<IResult<ITicket>,Error,{id: number, contractMessageId: number}>(
        attachMessageToTicket, {   
          onSuccess: (data: IResult<ITicket>) => {
            enqueueSnackbar( `${t('Operation done !!!')} - ${t('Ticket')} - # ${data.data.id} # `, { variant: 'success',
                  anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 3000 }); 
            
            const updatedMsgs = messages.map(msg => {              
              if (msg.id === data.data.newAttachedContractMessageId) {
                  return { ...msg, ticketMessageCount: msg.ticketMessageCount+1 };
              }
              return msg;
            });
            setMessages(updatedMsgs);    

            setOpenLastActiveTickectsForMessage(false);
          },
          onError: (err: Error) => {          
            enqueueSnackbar( err?.message, { variant: 'error',
                      anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });            
          }
        });

      // here id is contract message id
      const {mutate : mutateSetContractMessageNonTicketable } = useMutation<IResult<IContractMessage>,Error,{id: number}>(
        setContractMessageNonTicketable, {   
          onSuccess: (data: IResult<IContractMessage>) => {
            enqueueSnackbar( `${t('Operation done !!!')} - ${t('Ticket')} - # ${data.data.id} # `, { variant: 'success',
                  anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 3000 }); 

            const updatedMsgs = messages.map(msg => {              
              if (msg.id === data.data.id) {
                  return { ...msg, canBeTicketed: false };
              }
              return msg;
            });
            
            setMessages(updatedMsgs);            
          },
          onError: (err: Error) => {          
            enqueueSnackbar( err?.message, { variant: 'error',
                  anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });            
          }
        });

      // here id is contract message id
      const {mutate : mutateSetContractMessageTicketable } = useMutation<IResult<IContractMessage>,Error,{id: number}>(
        setContractMessageTicketable, {   
          onSuccess: (data: IResult<IContractMessage>) => {
            enqueueSnackbar( `${t('Operation done !!!')} - ${t('Ticket')} - # ${data.data.id} # `, { variant: 'success',
                  anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 3000 }); 

              const updatedMsgs = messages.map(msg => {              
                if (msg.id === data.data.id) {
                    return { ...msg, canBeTicketed: true };
                }
                return msg;
              });
              
              setMessages(updatedMsgs);            
          },
          onError: (err: Error) => {          
            enqueueSnackbar( err?.message, { variant: 'error',
                  anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });            
          }
        });


    const {mutate: mutateReplyMessage } = useMutation<IResult<IMessage>,Error,IMessage>(
      createMessage, {   
        onSuccess: (data: IResult<IMessage>) => {
          enqueueSnackbar( `${t('Operation done !!!')} - ${t('Message')} - # ${data.data.id} # `, { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 3000 }); 

              const updatedMsgs = messages.map(msg => {              
                if (msg.id === refOpenedMessageId.current) {
                    return { ...msg, replyCount: msg.replyCount+1 };
                }
                return msg;
              });
              setMessages(updatedMsgs);

          setOpenReplyMessage(false);                    
        },
        onError: (err: Error) => {          
          enqueueSnackbar( err?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });            
        }
      });

    const {data: _data, refetch} = useQuery<IMessage>(['Message', _id], () => retrieveEntity('Message',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );


    // const {data: contracts } = useQuery<IContract[]>(['Contracts'], () => getContracts( {description: '', whatsAppNumber: ''}), 
    //   {refetchOnWindowFocus: false ,enabled: true } );

      const [messageType,] = useState( [       
        {name: 'text', text: t('Text'), value: ''}, 
        {name: 'image', text: t('Image'), value: ''},     
        {name: 'document', text: t('Document'), value: ''},     
           
      ]);    

      function openFileDialog() {
        (document as any).getElementById("file-upload").click();
      }
    
      const setFile = (_event: any) => {
        let f = _event.target.files![0];
        var reader = new FileReader();
    
        reader.onload = function () {
    
            var binaryString = reader.result as string;
        
            const base64String = binaryString
                                        .replace('data:', '')
                                        .replace(/^.+,/, '');
    
            setValueMessage("base64File", base64String);
            setValueMessage("fileName", f.name);
          };
      
          reader.onerror = function () {
            console.log("File load failed");
          };
      
          reader.readAsDataURL(f);    
      };

      
      const [currentContractId, setCurrentContractId] = useState<number>(0);
      const [currentContractDescription, setCurrentContractDescription] = useState<string>('');
      const [currentContractPhoneNumber, setCurrentContractPhoneNumber] = useState<string>('');
      const [currentContractPublicWhatsAppImageUrl, setCurrentContractPublicWhatsAppImageUrl] = useState<string>('');

      const [contacts, setContacts] = useState<IContact[]>([]);
      const [hasMoreContacts, setHasMoreContacts] = useState(true);
      const [pageContacts, setPageContacts] = useState(1);

      const [searchContactQuery, setSearchContactQuery] = useState('');
      const [filteredContacts, setFilteredContacts] = useState<IContact[]>(contacts);

      const expectedBatchSize = 100;

      const fetchContacts = async () => {
        try {
            const newContacts = await getContactsByPage( {pageNumber: pageContacts, pageSize: expectedBatchSize} ); // Replace with your API call
            //const newData = response.data; // Adjust according to your API response structure

            setContacts((prevItems) => [...prevItems, ...newContacts]);
            setPageContacts((prevPage) => prevPage + 1);

            if (newContacts.length === 0 || newContacts.length < expectedBatchSize) {
              
              setHasMoreContacts(false);
            }
        } catch (error) {
            console.error('Error fetching data: ', error);
        }
    }

    const displayMessages = async (contractId: number, whatsAppId: string, messageFilter: MessageFilter) => {
      setCurrentWhatsAppId(whatsAppId);
      setCurrentContractId(contractId);
      setCurrentMessageFilter(messageFilter);
      
      setPageMessages(1);
      const newMessages = await getContractMessagesByPage(contractId === 0 ? contractIds: [contractId] , whatsAppId, messageFilter, 
                                                    {pageNumber: 1, pageSize: expectedBatchSize} );
      setMessages([...newMessages]); 

      const userContract = userContracts.find(c => c.contractId === contractId);
      const roleEntityTicket = roleEntities.find(re => re.entityName === 'Ticket');

      const bCancreateNewTicket = userContract !== undefined && userContract!.canAssociateMessageToTicket && 
                                    roleEntityTicket !== undefined && roleEntityTicket.canCreate;

      setCancreateNewTicket(bCancreateNewTicket);
      setCanCreateNewValidatedTicket(bCancreateNewTicket && 
                  roleEntityTicket.roleEntityFeatures.some(f => f.featureName === 'ValidateTicketHandler') );
      setCanAttachContractMessageToTicket( roleEntityTicket !== undefined && 
                  roleEntityTicket.roleEntityFeatures.some(f => f.featureName === 'AttachMessageToTicketHandler') );

      const roleEntityContractMessage = roleEntities.find(re => re.entityName === 'ContractMessage');
      
      setCanSetContractMessageNonTicketable( roleEntityContractMessage !== undefined && 
                  roleEntityContractMessage.roleEntityFeatures.some(f => f.featureName === 'SetNonTicketableContractMessageHandler'));
      setCanSetContractMessageTicketable( roleEntityContractMessage !== undefined && 
                  roleEntityContractMessage.roleEntityFeatures.some(f => f.featureName === 'SetTicketableContractMessageHandler'));

    }

    const [anchorElContract, setAnchorElContract] = useState<null | HTMLElement>(null);
    const handleContractSwitch = (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorElContract(event.currentTarget);
    };


    const [anchorElContact, setAnchorElContact] = useState<null | HTMLElement>(null);
    const handleContactSwitch = (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorElContact(event.currentTarget);
    };


    const [anchorElMessage, setAnchorElMessage] = useState<null | HTMLElement>(null);
    const handleMessageSwitch = (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorElMessage(event.currentTarget);
    };


    const handleContactClick = async ( whatsAppId : string ) => {
      setCurrentWhatsAppId(whatsAppId);

      const contact = contacts.find(c => c.whatsAppId === whatsAppId);
      if(!isFalsy(contact))
        setCurrentWhatsAppName(contact.name);

      if(currentContractId>0)
        await displayMessages(currentContractId, whatsAppId, currentMessageFilter);          
    }

    const handleContractChange = async (newContractId: number) => {              
      // if(newContractId === null || newContractId === undefined)
      //   return;

      setCurrentContractId(newContractId);  
      
      if(newContractId === 0) {
        setCurrentContractDescription(`${t('All account')}`);
        setCurrentContractPhoneNumber(`X`);
        setCurrentContractPublicWhatsAppImageUrl('');
      } else {
        const userContract = userContracts.find(c => c.contractId === newContractId);
        if(!isFalsy(userContract)) {
          setCurrentContractDescription(userContract.contractDescription);
          setCurrentContractPhoneNumber(userContract.contractPhoneNumber);
          setCurrentContractPublicWhatsAppImageUrl(userContract.contractPublicWhatsAppImageUrl);
        }
      }
      
      setAnchorElContract(null);

      //if(!isFalsy(currentWhatsAppId))
      await displayMessages(newContractId, currentWhatsAppId, currentMessageFilter);
    }

    type ContactFilter = 'all' | 'contact';
    

    //const [currentContactFilter, setCurrentContactFilter] = useState<ContactFilter>('all');
    const [currentWhatsAppName, setCurrentWhatsAppName] = useState<string>(t('All'));
    const [currentWhatsAppId, setCurrentWhatsAppId] = useState<string>('');

    const [currentMessageFilter, setCurrentMessageFilter] = useState<MessageFilter>('new');
    const [currentMessageFilterDescription, setCurrentMessageFilterDescription] = useState<string>(t('New message'));

    const [messages, setMessages] = useState<IContractMessage[]>([]);
    const [hasMoreMessages, setHasMoreMessages] = useState(true);
    const [pageMessages, setPageMessages] = useState(1);

    const fetchMessages = async () => {
      try {
          const newMessages = await getContractMessagesByPage(currentContractId === 0 ? contractIds: [currentContractId] , currentWhatsAppId, currentMessageFilter, {pageNumber: pageMessages, pageSize: expectedBatchSize} ); // Replace with your API call
          //const newData = response.data; // Adjust according to your API response structure

          setMessages((prevItems) => [...prevItems, ...newMessages]);
          setPageMessages((prevPage) => prevPage + 1);
          
          if (newMessages.length === 0 || newMessages.length < expectedBatchSize) {
            console.log('here no more');
            setHasMoreMessages(false);
          }
      } catch (error) {
          console.error('Error fetching data: ', error);
      }
  }


  const [canCreateNewTicket, setCancreateNewTicket] = useState<boolean>(false);
  const [canCreateNewValidatedTicket, setCanCreateNewValidatedTicket] = useState<boolean>(false);

  const [canAttachContractMessageToTicket, setCanAttachContractMessageToTicket] = useState<boolean>(false);
  const [canSetContractMessageNonTicketable, setCanSetContractMessageNonTicketable] = useState<boolean>(false);
  const [canSetContractMessageTicketable, setCanSetContractMessageTicketable] = useState<boolean>(false);

  const [canReplyMessage, setCanReplyMessage] = useState<boolean>(true);


  const [openQuotedMessages, setOpenQuotedMessages] = useState<boolean>(false);
  const [quotedMessages, setQuotedMessages] = useState<IContractMessage[]>([]);
  const handleOpenQuotedMessages = async (quotedMessageId: string) => {
    const arr = await getContractMessagesByQuotedMessageId(quotedMessageId);

    if(arr.length === 0) return;

    setQuotedMessages(arr);
    setOpenQuotedMessages(true);
  }
  
  // this is id as number, there is another messageId which is gerenerated by ultramsg as string
  const [openedMessageId, setOpenedMessageId] = useState<number>(0); 
  const [openCreateNewTicket, setOpenCreateNewTicket] = useState<boolean>(false);
  const handleCreateNewTicket  =  (event : any,idMessage: number, validateDuringCreation: boolean) => {
    event.stopPropagation();

    const msg = messages.find(x => x.id === idMessage);
    resetTicket({...defaultTicket, type: 'whatsApp', validateDuringCreation, description: msg?.body||'', });
    setOpenedMessageId(idMessage);
    setOpenCreateNewTicket(true);    
  }

  const handleOkCreateNewTicket = async () => {   
    
    mutateCreateTicket({...getValuesTicket(), initialContractMessageId: openedMessageId });
  }

  const [openLastActiveTickectsForMessage, setOpenLastActiveTickectsForMessage] = useState<boolean>(false);
  const [lastActiveTickectsForMessage, setLastActiveTickectsForMessage] = useState<ITicket[]>([]);
  const handleAttachToTicket = async (event : any,idMessage: number) => {

    event.stopPropagation();

    setOpenedMessageId(idMessage);

    const arr = await getLastActiveTicketsForMessage(idMessage);
    setLastActiveTickectsForMessage(arr);
    setOpenLastActiveTickectsForMessage(true);
  }

  const handleLastActiveTicketRowDoubleClick = (event: React.MouseEvent<unknown>, row: ITicket) => {
    mutateAttachMessageToTicket({id: row.id, contractMessageId: openedMessageId });
  }

  const handleNonTicketable = async (event : any,idMessage: number) => {
    event.stopPropagation();

    setOpenedMessageId(idMessage);
    mutateSetContractMessageNonTicketable({id: idMessage });
  }

  const handleTicketable = async (event : any,idMessage: number) => {
    event.stopPropagation();

    setOpenedMessageId(idMessage);
    mutateSetContractMessageTicketable({id: idMessage });
  }

  const [openReplyMessage, setOpenReplyMessage] = useState<boolean>(false);
  const handleReplyMessage = async (event : any, idMessage: number) => {

    event.stopPropagation();

    resetMessage({...defaultMessage, type: 'text'});
    setOpenedMessageId(idMessage);    
    setOpenReplyMessage(true);
  }
  
  const handleOkReplyMessage = async () => {   
  
    var msg = messages.find(x => x.id === openedMessageId);
    if(isFalsy(msg)) return;

    mutateReplyMessage({...getValuesMessage(), contractId: msg.contractId, 
      validityStartDate: new Date(), validityEndDate: addDays(new Date(), 7,),
      quotedMessageId: msg.messageId, recipientNumber: msg.fromWhatsAppId.split('@')[0] });
  }

  const handleChangeMessageType = ( event: React.MouseEvent<HTMLElement>, newType: MessageType ) => {
    if (newType === null) 
      return;

    setValueMessage('type', newType);              
  }


  const msgBgColor = (msg: IContractMessage) : string => {

    
    if(msg.canBeTicketed === null || msg.canBeTicketed === undefined) return applicationSetup.newMessageColor;
    else if(!msg.canBeTicketed) return applicationSetup.nonTicketableMessageColor;
    else if(msg.canBeTicketed) return applicationSetup.ticketableMessageColor;
    else if(msg.ticketMessageCount > 0) return applicationSetup.joinedMessageColor;
    return '#e6f7ff';
  }

  const [msgKey, setMsgKey] = useState<string>('');
  useEffect(() => {
    setMsgKey(messages.map(x=> String(x.canBeTicketed) + x.ticketMessageCount ).join('-'));
  }, [messages]);

  useEffect(() => {
    
    const userContract = userContracts.find(c => c.isActive);
    if(!isFalsy(userContract)) {
      setCurrentContractDescription(userContract.contractDescription);
      setCurrentContractPhoneNumber(userContract.contractPhoneNumber)
      setCurrentContractPublicWhatsAppImageUrl(userContract.contractPublicWhatsAppImageUrl);
      setCurrentContractId(userContract.contractId);

      setCurrentWhatsAppName(t('All contacts'));
      setCurrentWhatsAppId('');

      setCurrentMessageFilter('new');
      setCurrentMessageFilterDescription(t('New message'));

      displayMessages(userContract.contractId, '', 'new');
    }
      
    
  }, []);

  useEffect(() => {
    if (searchContactQuery) {
      const filtered = contacts.filter(x =>
        x.name.toLowerCase().includes(searchContactQuery.toLowerCase()) || x.whatsAppId.toLowerCase().includes(searchContactQuery.toLowerCase())
      );
      setFilteredContacts(filtered);
    } else {
      setFilteredContacts(contacts);
    }
  }, [searchContactQuery, contacts]);

  
  const handleClickContactFilter = (event: any) => {
    setOpenContactFilter(true);
    setAnchorElContact(null);

    
  }

  const handleClickContactFilterAll = async (event: any) => {
    setCurrentWhatsAppId('');
    setCurrentWhatsAppName(t('All contacts'));

    await displayMessages(currentContractId, '', currentMessageFilter);
    setAnchorElContact(null);
  }


  const handleClickMessageFilter = async (msgFilter: MessageFilter, msgFilterDesc: string) => {
    setCurrentMessageFilter(msgFilter);
    setCurrentMessageFilterDescription(msgFilterDesc);

    await displayMessages(currentContractId, currentWhatsAppId, msgFilter);
    setAnchorElMessage(null);
  }

  useEffect( () => {
      
    if(newContractMessages.length === 0) return;

    const msgs = newContractMessages.filter( m => contractIds.includes(m.contractId) && messages.findIndex(x => x.messageId === m.messageId) < 0 &&
                                                  (currentContractId === 0 || m.contractId === currentContractId) &&
                                                  ( ['new', 'all'].includes(currentMessageFilter) )  );
        
    setMessages( [...msgs, ...messages] );                                              

  }, [newContractMessages] );

  useEffect( () => {        
    refCurrentMessageFilter.current = currentMessageFilter;
  }, [currentMessageFilter]);


  useEffect( () => {        
    refCurrentContractId.current = currentContractId;
  }, [currentContractId]);

  useEffect( () => {        
    refOpenedMessageId.current = openedMessageId;
  }, [openedMessageId]);
  

    useEffect(() => {
      fetchContacts();
    }, []);

    useEffect( () => {        
      setCurrentFormNameAtom(t('Chat room'));
      setCurrentBasicTextFilterProps(basicFilterMessage);
    }, []);

      
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('Message',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {        
        _setId(0);  
        reset({...defaultMessage, validityStartDate:new Date(), validityEndDate: addDays(new Date(), 1)  });         
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {        
          const data = getValues();
          
          //const {id} = currentUserSession;
          if(data.recipientNumber.trim() === '' ) {
              enqueueSnackbar( t('There is no recipient'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
              return;
            }

          if(data.type.trim() === 'text' && data.messageBody.trim() === '') {
            enqueueSnackbar( t('Message body is not specified'), { variant: 'warning',
                  anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
            return;
          }

          if(data.type.trim() === 'image' && data.fileCaption.trim() === '') {
            enqueueSnackbar( t('File caption is not specified'), { variant: 'warning',
                  anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
            return;
          }

          if( ['document','video'].includes(data.type.trim())  && data.fileName.trim() === '') {
            enqueueSnackbar( t('File name is not specified'), { variant: 'warning',
                  anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
            return;
          }
      
          //mutate({...data, contractId: id});
      }

    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Message', _id);
      }

     
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={1} columnSpacing={2}>
                      
                    <Grid item xs={4} component={Paper} sx={{ borderRadius: 2, ml: 0, backgroundColor: '#f0f0f0' }} >                        
                        <Stack flexDirection='column'  >
                          <Box sx={{ mt: 1, width: '100%' }} display="flex" alignItems="center" p={2} borderBottom={1} borderColor="divider">
                            
                            <Avatar src={`${globalConfig.get().apiUrl}/Download/${currentContractPublicWhatsAppImageUrl}`} alt={currentContractDescription} />
                            <Typography variant="h6" style={{ marginLeft: 10, flexGrow: 1 }}>
                              {currentContractDescription} - {currentContractPhoneNumber}
                            </Typography>
                            <IconButton onClick={handleContractSwitch}>
                              <SwitchAccountIcon />
                            </IconButton>
                            <Menu anchorEl={anchorElContract} keepMounted open={Boolean(anchorElContract)} onClose={() => {setAnchorElContract(null); }} >
                              {userContracts.map((userContract) => (
                                <MenuItem key={userContract.contractId} onClick={() => handleContractChange(userContract.contractId)}>
                                  <Avatar src={`${globalConfig.get().apiUrl}/Download/${userContract.contractPublicWhatsAppImageUrl}`} 
                                      alt={userContract.contractDescription} style={{ marginRight: 10 }} />
                                  {userContract.contractDescription}
                                </MenuItem>
                              ))}
                              <MenuItem key='contrarId -1' onClick={() => handleContractChange(0)}>
                                  <Avatar src={`/images/product.jpg`} 
                                      alt={'contract desc.'} style={{ marginRight: 10 }} />
                                  {`${t('All account')}`}
                                </MenuItem>
                            </Menu>
                          </Box>
                          <Box sx={{ mt: 1, width: '100%' }} >
                            <TextField label="" fullWidth value={searchContactQuery}
                              variant="standard"
                              placeholder={t('Filter')}
                              onChange={(e) => setSearchContactQuery(e.target.value)}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                                disableUnderline: true, // Removes the underline
                              }}
                              sx={{
                                width:'calc(90% - 8px)',
                                '& .MuiInputBase-input': {
                                  borderRadius: 1, // Adjust for rounded corners if needed
                                  backgroundColor: '#f0f2f5', // Light grey background
                                  padding: '8px 12px', // Adjust padding as needed
                                },
                                '& .MuiSvgIcon-root': {
                                  color: 'rgba(0, 0, 0, 0.54)', // Icon color
                                },
                              }}
                            />
                          </Box>
                          <Box sx={{ mt: 1, width: '100%' }} >    
                            <Container maxWidth="xl" >                          
                              <InfiniteScroll
                                dataLength={contacts.length}
                                next={fetchContacts}
                                hasMore={hasMoreContacts}
                                loader={hasMoreContacts ? `${t('Loading')} ...` : null}
                                //loader={<CircularProgress />}
                                scrollableTarget="scrollableDiv"
                                endMessage={
                                    <p style={{ textAlign: 'center' }}>
                                        <b>You have seen it all</b>
                                    </p>
                                }
                                style={{ overflow: 'hidden' }}
                              >
                                <List style={{ height: '400px', overflow: 'auto' }} id="scrollableDiv">
                                    {filteredContacts.map(({whatsAppId,name,whatsAppNumber,publicWhatsAppImageUrl }, index) => ( 
                                      <React.Fragment key={`${index} - ${whatsAppId}`}>
                                        <ListItemButton onClick={() => handleContactClick(whatsAppId)}>
                                            <ListItemAvatar>
                                                <Avatar src={`${globalConfig.get().apiUrl}/Download/${publicWhatsAppImageUrl}`} />                                              
                                            </ListItemAvatar>
                                            <ListItemText primary={name} secondary={`${whatsAppNumber}`} />
                                        </ListItemButton>
                                        <Divider variant="inset" component="li" />
                                    </React.Fragment>
                                    ))}
                                </List>
                              </InfiniteScroll>
                            </Container>           
                          </Box>
                          
                          
                        </Stack>                  
                    </Grid>
                    <Grid item xs={8} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                          <Box sx={{ mt: 4, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <Button>
                                {`${t('Contact')} : ${currentWhatsAppName}`}
                              </Button>
                              <Box sx={{ ...justifyCenter, ml: 10 }}>
                                <IconButton onClick={handleContactSwitch}>
                                  <FaFilter size={16} color={Colors.menuButton}/>
                                </IconButton>                                 
                              </Box>
                            </div>
                            {/* <Button>
                              {`${t('Contact')} : ${currentWhatsAppName}`}
                              <Box sx={{ ...justifyCenter, ml: 10 }}>
                                <IconButton onClick={handleContactSwitch}>
                                  <FaBars size={16} color={Colors.menuButton} />
                                </IconButton>                                 
                              </Box>
                            </Button> */}
                            <Menu anchorEl={anchorElContact} keepMounted open={Boolean(anchorElContact)} onClose={() => {setAnchorElContact(null); }} >                              
                              <MenuItem onClick={handleClickContactFilter}>
                                <Avatar sx={{ width: 24, height: 24 }} style={{ marginRight: 10 }} >
                                  <FaUsers size={16} color={Colors.menuButton}/>
                                </Avatar>
                                {`${t('Contact filter')}`}
                              </MenuItem>
                              <MenuItem  onClick={handleClickContactFilterAll}>
                                <Avatar sx={{ width: 24, height: 24 }} style={{ marginRight: 10 }} >
                                  <FaFilter size={16} color={Colors.menuButton}/>
                                </Avatar>
                                {`${t('All contacts')}`}
                              </MenuItem>
                            </Menu>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <Button>
                                {`${t('Filter')} : ${currentMessageFilterDescription}`}
                              </Button>
                              <Box sx={{ ...justifyCenter, ml: 10 }}>
                                <IconButton onClick={handleMessageSwitch}>
                                  <FaFilter size={16} color={Colors.menuButton}/>
                                </IconButton>                                 
                              </Box>
                            </div>
                            {/* <Button>
                              {`${t('Filter')} : ${currentMessageFilterDescription}`}
                              <Box sx={{ ...justifyCenter, ml: 10 }}>
                                <IconButton onClick={handleMessageSwitch}>
                                  <FaFilter size={16} color={Colors.menuButton}/>
                                </IconButton>                                 
                              </Box>
                            </Button> */}
                            <Menu anchorEl={anchorElMessage} keepMounted open={Boolean(anchorElMessage)} onClose={() => {setAnchorElMessage(null); }} >                              
                              <MenuItem  onClick={() => handleClickMessageFilter('new', `${t('New message')}`)}>
                                <Avatar sx={{ width: 24, height: 24 }} style={{ marginRight: 10 }} >
                                  <FaBell size={16} color={Colors.menuButton}/>
                                </Avatar>
                                  {`${t('New messages')}`}
                              </MenuItem>                              
                              <MenuItem  onClick={() => handleClickMessageFilter('ticketable', `${t('Ticketable message')}`)}>
                                <Avatar sx={{ width: 24, height: 24 }}  style={{ marginRight: 10 }} >
                                  <FaTicketAlt size={16} color={Colors.menuButton} />
                                </Avatar>
                                  {`${t('Ticketable messages')}`}
                              </MenuItem>
                              <Divider />      
                              <MenuItem  onClick={() => handleClickMessageFilter('non-ticketable', `${t('Non ticketable message')}`)}>
                                <Avatar sx={{ width: 24, height: 24 }} style={{ marginRight: 10 }} >
                                  <FaEnvelope size={16} color={Colors.menuButton} />
                                </Avatar>
                                  {`${t('Non ticketable messages')}`}
                              </MenuItem>
                              <MenuItem  onClick={() => handleClickMessageFilter('assigned-to-ticket', `${t('Message assigned to tiket')}`)}>
                                <Avatar sx={{ width: 24, height: 24 }} style={{ marginRight: 10 }} >
                                  <FaLink size={16} color={Colors.menuButton}/>
                                </Avatar>
                                  {`${t('Messages assigned to tiket')}`}
                              </MenuItem>
                              <Divider />      
                              <MenuItem  onClick={() => handleClickMessageFilter('all', `${t('All messages')}`)}>
                                <Avatar sx={{ width: 24, height: 24 }} style={{ marginRight: 10 }} >
                                  <FaList size={16} color={Colors.menuButton}/>
                                </Avatar>
                                  {`${t('All messages')}`}
                              </MenuItem>
                            </Menu>
                          </Box>
                          <Box sx={{ mt: 1, width: '100%' }} >  
                            <Container maxWidth="xl" >                          
                              <InfiniteScroll
                                dataLength={messages.length}
                                next={fetchMessages}
                                hasMore={hasMoreMessages}
                                loader={hasMoreMessages ? `${t('Loading')} ...` : null}
                                //loader={<CircularProgress />}
                                scrollableTarget="scrollableMessageDiv"
                                endMessage={
                                    <p style={{ textAlign: 'center' }}>
                                        <b>You have seen it all</b>
                                    </p>
                                }
                                key={`${getValues().recipientNumber} - ${msgKey}`}
                                style={{ overflow: 'hidden' }}
                              >
                                <List style={{ maxHeight: '400px', overflow: 'auto' }} id="scrollableMessageDiv" 
                                  key={`${currentWhatsAppId} ${msgKey}   `}>
                                  {messages.map((msg, index) => { 
                                    const bg = msg.fromWhatsAppId===msg.contractWhatsAppId ? '#f3f3f3' : msgBgColor(msg);
                                    const col = theme.palette.getContrastText(bg);
                                    return (
                                    <ListItem style={{ justifyContent: msg.fromWhatsAppId===msg.contractWhatsAppId ? 'flex-start' : 'flex-end'}}
                                      key={`msg: ${index}-${msg.messageId}-${msg.canBeTicketed}-${msg.ticketMessageCount} ${bg} `}                                      
                                      >                                      
                                        <Paper 
                                          onClick={() => handleOpenQuotedMessages(msg.messageId)}
                                          key={`${msg.ticketMessageCount} - ${msg.canBeTicketed}`}
                                          style={{ 
                                            backgroundColor: bg, 
                                            color: col, 
                                            padding: '10px 20px', 
                                            borderRadius: '20px',
                                            maxWidth: '80%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexDirection: 'column', // To align items vertically
                                            wordBreak: 'break-word', // This ensures long words will wrap
                                            overflowWrap: 'break-word' // Breaks long words if necessary                                        
                                        }}>
                                          <div style={{ marginBottom: '5px' }}>
                                              <small>{new Date(msg.messageDate).toLocaleString()} - {msg.fromWhatsAppId.split('@')[0]}</small>
                                          </div>
                                          <ListItemText primary={msg.body} style={{ wordWrap: 'break-word' }} key={`${msg.ticketMessageCount} - ${msg.canBeTicketed}`} />
                                          { (msg.fromWhatsAppId!==msg.contractWhatsAppId) &&
                                          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                                            { canCreateNewTicket && <Tooltip title={t('Create new ticket from this message')}>
                                              <IconButton size="small" onClick={(event) => handleCreateNewTicket(event, msg.id, false)}>                                            
                                                <PlaylistAddIcon style={{ color: Colors.menuButton }} fontSize="small" />                                            
                                              </IconButton>
                                            </Tooltip> }
                                            { canCreateNewValidatedTicket && <Tooltip title={t('Create new validated ticket from this message')}>
                                              <IconButton size="small" onClick={(event) => handleCreateNewTicket(event, msg.id, true)}>                                            
                                                <PlaylistAddCheckIcon style={{ color: Colors.menuButton }} fontSize="small" />                                            
                                              </IconButton>
                                            </Tooltip> }
                                            { canAttachContractMessageToTicket && (msg.canBeTicketed === null || msg.canBeTicketed) &&
                                            <Tooltip title={t('Attach this message to a ticket')}>
                                              <IconButton style={{ color: Colors.menuButton }} size="small" onClick={(event) => handleAttachToTicket(event, msg.id)}>
                                                <Badge style={{ color: Colors.info }} badgeContent={msg.ticketMessageCount}>
                                                  <AttachFileIcon style={{ color: Colors.menuButton }} fontSize="small" />
                                                </Badge> 
                                              </IconButton>
                                            </Tooltip> }
                                            { canSetContractMessageNonTicketable && msg.ticketMessageCount===0  && (msg.canBeTicketed === null || msg.canBeTicketed) && 
                                            <Tooltip title={t('Identity this message as non ticketable')}>
                                              <IconButton  size="small" onClick={(event) => handleNonTicketable(event, msg.id)}>
                                                <CancelIcon style={{ color: Colors.danger }} fontSize="small" />
                                              </IconButton>
                                            </Tooltip> } 
                                            { canSetContractMessageTicketable && msg.ticketMessageCount===0 && (msg.canBeTicketed === null || !msg.canBeTicketed) && 
                                            <Tooltip title={t('Identity this message as ticketable')}>
                                              <IconButton size="small" onClick={(event) => handleTicketable(event, msg.id)}>
                                                <CheckCircleIcon style={{ color: Colors.success }} fontSize="small" />
                                              </IconButton>
                                            </Tooltip> }
                                            { canReplyMessage && 
                                            <Tooltip title={t('Reply to this message')}>
                                              <IconButton size="small" onClick={(event) => handleReplyMessage(event, msg.id)}>
                                                <Badge style={{ color: Colors.info }} badgeContent={msg.replyCount}>
                                                  <ReplyAllIcon style={{ color: Colors.success }} fontSize="small" />
                                                </Badge>
                                              </IconButton>
                                            </Tooltip> }                                          
                                          </div>}
                                          
                                        </Paper>
                                        
                                      
                                    </ListItem>                                    
                                  )})}
                                </List>
                              </InfiniteScroll>
                            </Container>
                          </Box> 
                          { openContactFilter && <FormDialog open={openContactFilter} maxWidth='md'
                                  okText='' cancelText='' title={t('Contact filter')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenContactFilter(false);}} onOk={()=> {setOpenContactFilter(false);}}  >
                                      <BasicTextFilterForm<IContact> {...basicFilterContact } />
                              </FormDialog> }
                          { openCreateNewTicket && <FormDialog open={openCreateNewTicket} maxWidth='md' height='70vh'
                              okText={t('Validate')} cancelText={t('Cancel')} title={t('Create new ticket')} onCancel={()=> {setOpenCreateNewTicket(false);}} 
                              onClose={()=> {setOpenCreateNewTicket(false);}} onOk={handleOkCreateNewTicket}  >
                                  <BasicTicketForm setValue={setValueTicket} getValues={getValuesTicket} watch={watchTicket} control={controlTicket} />
                          </FormDialog> }  
                          { openLastActiveTickectsForMessage && <FormDialog open={openLastActiveTickectsForMessage} maxWidth='md' height='60vh'
                              okText='' cancelText='' title={t('Double click to select attach message to a ticket')} onCancel={()=> {}} 
                              onClose={()=> {setOpenLastActiveTickectsForMessage(false);}} onOk={()=> {}}  >
                                  <Box sx={{ mt: 0.25, width: '100%' }} >
                                      <EnhancedTable<ITicket> 
                                        rows={lastActiveTickectsForMessage} 
                                        headCells={[
                                          {id:'title', label : t('Title'),  display: true, type: 'string', },
                                          {id:'description', label : t('Description'),  display: true, type: 'string', },
                                          {id:'issueDate', label : t('Date'),  display: true, type: 'date', },
                                        ]} 
                                        title={t(`Last active tickets for the contact`)} objKey='id' 
                                          
                                        stateSelected={undefined}
                                        onRowSelected={undefined} onRowDoubleClick={handleLastActiveTicketRowDoubleClick} rowCheckedMode='single'
                                        onRowCheckedSelectChange={undefined} order='desc' orderBy='id'
                                        
                                        toolbarActions={[                                                                  
                                        ]}
                                    />
                                  </Box>
                          </FormDialog> }
                          { openReplyMessage && <FormDialog open={openReplyMessage} maxWidth='md' height='70vh'
                              okText={t('OK')} cancelText={t('Cancel')} title={`${t('Reply')} ...`} onCancel={()=> {setOpenReplyMessage(false);}} 
                              onClose={()=> {setOpenReplyMessage(false);}} onOk={handleOkReplyMessage}  >
                                <Stack flexDirection='column'  >
                                <Box sx={{ mt: 1, width: '100%' }} >      
                                    <ToggleButtonGroup size="small"
                                      color="primary" value={getValuesMessage().type} exclusive
                                      onChange={ (event, newType) => handleChangeMessageType(event, newType,)}
                                      aria-label="Small sizes" fullWidth
                                    >
                                      <ToggleButton value="text">{t('Text')}</ToggleButton>
                                      <ToggleButton value="image">{t('Image')} </ToggleButton>
                                      <ToggleButton value="document">{t('Document')} </ToggleButton>
                                      <ToggleButton value="video">{t('Video')} </ToggleButton>
                                    </ToggleButtonGroup>  
                                  </Box>
                                  { watchMessageType === 'text' && <Box sx={{ mt: 0.25, width: '100%' }} >
                                    <TextField sx={{width:'calc(100% - 8px)'}} id="messageBody" label={t('MessageBody')} multiline rows={3}
                                    {...registerMessage('messageBody')} 
                                    inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                    />
                                  </Box> }
                                  { ['image','document','video'].includes(watchMessageType) && <Box sx={{ mt: 1, width: '100%' }} >                                
                                    <TextField sx={{width:'calc(100% - 8px)'}} id="fileCaption" label={t('File caption')} multiline rows={3}
                                      {...registerMessage('fileCaption')} 
                                      inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                    />   
                                  </Box> }
                                  { ['document','video'].includes(watchMessageType) && <Box sx={{ mt: 1, width: '100%' }} >                                
                                      <TextField sx={{width:'calc(100% - 8px)'}} id="fileName" label={t('File name')}
                                        {...registerMessage('fileName')} 
                                        inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: isFalsy(getValuesMessage().base64File) } }
                                      />   
                                  </Box> }
                                  { watchMessageType !== 'text' && <Box key={`${watchMessageFileName} - key`} sx={{ mt: 1, width: '100%' }} >  
                                      <input type="file" id="file-upload" style={{ display: "none" }} onChange={setFile}
                                        accept={ (watchMessageType === 'image') ? 'image/*' : 
                                                  (watchMessageType === 'video') ? 'video/*' :
                                                    '.pdf,.csv,.doc,.docx,.xls,.xlsx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'} />
                                      <Button onClick={openFileDialog}>
                                          {isFalsy(watchMessageFileName) ? `${t('Click to toad file')} ...`: `${watchMessageFileName} -> ${t('Click to change')}`}
                                      </Button> 
                                  </Box>}
                                  { watchMessageBase64File && watchMessageType === 'image'  &&<Box sx={{ mt: 1, ml: 2, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}  >                                          
                                      <img src={`data:image/png;base64,${watchMessageBase64File}`} width='100%' />
                                  </Box> }
                                </Stack>
                          </FormDialog>}  
                          { openQuotedMessages && <FormDialog open={openQuotedMessages} maxWidth='md' height='60vh'
                              okText='' cancelText='' title={t('List of messages')} onCancel={()=> {}} 
                              onClose={()=> {setOpenQuotedMessages(false);}} onOk={()=> {}}  >
                                  <Box sx={{ mt: 0.25, width: '100%' }} >
                                      <EnhancedTable<IContractMessage> 
                                        rows={quotedMessages} 
                                        headCells={[
                                          {id:'id', label : t('Id'),  display: true, type: 'string', },
                                          {id:'body', label : t('Message'),  display: true, type: 'string', },                                          
                                        ]} 
                                        title={t(`Replied messages`)} objKey='id' 
                                          
                                        stateSelected={undefined}
                                        onRowSelected={undefined} onRowDoubleClick={undefined} rowCheckedMode='single'
                                        onRowCheckedSelectChange={undefined} order='desc' orderBy='id'
                                        
                                        toolbarActions={[                                                                  
                                        ]}
                                    />
                                  </Box>
                          </FormDialog> }                     
                        </Stack>                  
                    </Grid>
                    
                </Grid>
            </Box>
        </FormProvider> 
  )
}

