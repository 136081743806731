
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import {addDays} from 'date-fns';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';


import { globalConfig } from 'config';

import { IMessage, defaultMessage, MessageType } from './models/Message';

import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, currentUserSessionAtom, isSaveLoadingAtom } from 'library/store';
import useMessageService, { useBasicFilterMessage } from './services/Message';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Avatar, Checkbox, Chip, FormControlLabel, IconButton, InputAdornment, Menu, MenuItem, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import NumberFormat from 'react-number-format';



import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';
import IEnumeration from 'features/configuration/models/Enumeration';
import { isFalsy } from 'utility-types';
import { typographyGroupBoxStyling } from 'themes/commonStyles';


export const MessageForm: FC<IMessage> = (props: IMessage = {...defaultMessage,
       validityStartDate:new Date(), validityEndDate: addDays(new Date(), 1)}) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, checkEntitySaveAuthorization } = entityService();

  const { createMessage, updateMessage } = useMessageService();

  const [currentUserSession, setCurrentUserSession] = useRecoilState(currentUserSessionAtom);
  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const {language: lg, contractIds, userContracts , roleEntities, applicationSetup} = useRecoilValue(currentUserSessionAtom);
    
  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterMessage = useBasicFilterMessage( 
      (event: React.MouseEvent<unknown>, row: IMessage) => {
          
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );

  const methods = useForm<IMessage>({defaultValues:{...defaultMessage,validityStartDate:new Date(), validityEndDate: addDays(new Date(), 1)}});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchType = watch('type');
  const watchRecipientNumber = watch('recipientNumber');
  const watchFileName = watch('fileName');
  const watchBase64File = watch('base64File');

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IMessage>,Error,IMessage>(
      _id>0?updateMessage:createMessage, {   
        onSuccess: (data: IResult<IMessage>) => {
          enqueueSnackbar( '_Operation_done', { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['Message',data.data.id]);
          // const btn = document.getElementById(`btnNew`);
          // btn?.click();
          newData(undefined);
        },
        onError: (err: Error) => {          
          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<IMessage>(['Message', _id], () => retrieveEntity('Message',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const [anchorElContract, setAnchorElContract] = useState<null | HTMLElement>(null);
    const handleContractSwitch = (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorElContract(event.currentTarget);
    };

    const handleContractChange = async (newContractId: number) => {              
      
      setValue('contractId',newContractId);        
      
      const userContract = userContracts.find(c => c.contractId === newContractId);
      if(!isFalsy(userContract)) {
        setCurrentContractId(newContractId);
        setCurrentContractDescription(userContract.contractDescription);
        setCurrentContractPhoneNumber(userContract.contractPhoneNumber);
        setCurrentContractPublicWhatsAppImageUrl(userContract.contractPublicWhatsAppImageUrl);
      }      
      
      setAnchorElContract(null);

    }

    const [currentContractId, setCurrentContractId] = useState<number>(0);
    const [currentContractDescription, setCurrentContractDescription] = useState<string>('');
    const [currentContractPhoneNumber, setCurrentContractPhoneNumber] = useState<string>('');
    const [currentContractPublicWhatsAppImageUrl, setCurrentContractPublicWhatsAppImageUrl] = useState<string>('');

      const [messageType,] = useState( [       
        {name: 'text', text: t('Text'), value: ''}, 
        {name: 'image', text: t('Image'), value: ''},     
        {name: 'document', text: t('Document'), value: ''},     
           
      ]);    

      const handleChangeMessageType = ( event: React.MouseEvent<HTMLElement>, newType: MessageType ) => {
        if (newType === null) 
          return;

        setValue('type', newType);              
      }

      const [recipients, setRecipients] = useState<string[]>([]);


      function openFileDialog() {
        (document as any).getElementById("file-upload").click();
      }
    
      const setFile = (_event: any) => {
        let f = _event.target.files![0];
        var reader = new FileReader();
    
        reader.onload = function () {
    
            var binaryString = reader.result as string;
        
            const base64String = binaryString
                                        .replace('data:', '')
                                        .replace(/^.+,/, '');
    
            setValue("base64File", base64String);
            setValue("fileName", f.name);
          };
      
          reader.onerror = function () {
            console.log("File load failed");
          };
      
          reader.readAsDataURL(f);    
      };

      useEffect(() => {
    
        const userContract = userContracts.find(c => c.isActive);
        if(!isFalsy(userContract)) {
          setCurrentContractId(userContract.contractId);
          setCurrentContractDescription(userContract.contractDescription);
          setCurrentContractPhoneNumber(userContract.contractPhoneNumber);
          setCurrentContractPublicWhatsAppImageUrl(userContract.contractPublicWhatsAppImageUrl);
          setValue('contractId',userContract.contractId);
        }
          
        
      }, []);

      useEffect( () => {        
        setCurrentFormNameAtom(t('Message'));
        setCurrentBasicTextFilterProps(basicFilterMessage);
      }, []);

      useEffect( () => {        
        setRecipients([...watchRecipientNumber.split(',')].filter(n => n.trim() !== '') );
      }, [watchRecipientNumber]);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('Message',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);
        }
        }, [_data]);
    
      const newData = async (event?: MouseEvent<HTMLButtonElement>) => {        
        _setId(0);  
        reset({...defaultMessage, contractId: currentContractId , validityStartDate:new Date(), validityEndDate: addDays(new Date(), 1)  });         
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {    
        
        if(!checkEntitySaveAuthorization('Message', _id)){
          setIsSaveLoading(false);
          return;
        }

        const data = getValues(); 
          
        if(data.contractId <= 0 ) {
          enqueueSnackbar( t('There is no contract selected !!!'), { variant: 'warning',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          setIsSaveLoading(false);
          return;
        }

          //const {id} = currentUserSession;
          if(data.recipientNumber.trim() === '' ) {
              enqueueSnackbar( t('There is no recipient'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
              setIsSaveLoading(false);
              return;
            }

          if(data.type.trim() === 'text' && data.messageBody.trim() === '') {
            enqueueSnackbar( t('Message body is not specified'), { variant: 'warning',
                  anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
            setIsSaveLoading(false);
            return;
          }

          if(data.type.trim() === 'image' && data.fileCaption.trim() === '') {
            enqueueSnackbar( t('File caption is not specified'), { variant: 'warning',
                  anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
            setIsSaveLoading(false);
            return;
          }

          if( ['document','video'].includes(data.type.trim())  && data.fileName.trim() === '') {
            enqueueSnackbar( t('File name is not specified'), { variant: 'warning',
                  anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
            setIsSaveLoading(false);
            return;
          }
      
          mutate({...data});
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Message', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} display="flex" alignItems="center" p={2} borderBottom={1} borderColor="divider">
                              <Avatar src={`${globalConfig.get().apiUrl}/Download/${currentContractPublicWhatsAppImageUrl}`} alt={currentContractDescription} />
                              <Typography variant="h6" style={{ marginLeft: 10, flexGrow: 1 }}>
                                {currentContractDescription} - {currentContractPhoneNumber}
                              </Typography>
                              <IconButton onClick={handleContractSwitch}>
                                <SwitchAccountIcon />
                              </IconButton>
                              <Menu anchorEl={anchorElContract} keepMounted open={Boolean(anchorElContract)} onClose={() => {setAnchorElContract(null); }} >
                                {userContracts.map((userContract) => (
                                  <MenuItem key={userContract.contractId} onClick={() => handleContractChange(userContract.contractId)}>
                                    <Avatar src={`${globalConfig.get().apiUrl}/Download/${userContract.contractPublicWhatsAppImageUrl}`} 
                                        alt={userContract.contractDescription} style={{ marginRight: 10 }} />
                                    {userContract.contractDescription}
                                  </MenuItem>
                                ))}                                
                              </Menu>
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <TextField sx={{width:'calc(15% - 8px)'}} id="id" label="Id" {...register('id')} inputProps={ {readOnly: true}} /> 
                                <TextField sx={{width:'calc(85% - 8px)'}} id="messageReference" label={t('Reference')} {...register('messageReference')} inputProps={ {readOnly: false}} /> 
                                
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >                                
                                <Controller control={control}
                                  name='validityStartDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DateTimePicker label={t('Sending date')} 
                                      
                                      onChange={onChange}                        
                                      value={value}
                                      renderInput={(params) => <TextField {...params} sx={{width:'calc(25% - 8px)'}} />}
                                    /> )}
                                />
                                <Controller control={control}
                                  name='validityEndDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DateTimePicker label={t('Expiration date')} 
                                      
                                      onChange={onChange}                        
                                      value={value}
                                      renderInput={(params) => <TextField {...params} sx={{width:'calc(25% - 8px)'}} />}
                                    /> )}
                                />
                                <FormControlLabel sx={{width:'calc(25% - 8px)'}}
                                  label={t('Is Sended ?')}
                                  control={
                                  <Controller
                                      name='isSended'
                                      control={control}
                                      render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} disabled />}                        
                                />} />  
                                <Controller name={`status`} control={control}                                    
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(25% - 8px)'}} id="status"
                                      label={t('Status')} inputProps={ {readOnly: true}} > 
                                        <MenuItem value={0}>{t('Sended')}</MenuItem>                                           
                                        <MenuItem value={1}>{t('Delivered')}</MenuItem>         
                                        <MenuItem value={2}>{t('Read')}</MenuItem>                                                                                
                                    </TextField>
                                  )}
                                />                          
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >                                
                                <TextField sx={{width:'calc(100% - 8px)'}} id="lastErrorMessage" label={t('Last error message')}
                                  {...register('lastErrorMessage')} 
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: true } }
                                />   
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >      
                              <ToggleButtonGroup size="small"
                                color="primary" value={getValues().type} exclusive
                                onChange={ (event, newType) => handleChangeMessageType(event, newType,)}
                                aria-label="Small sizes" fullWidth
                              >
                                <ToggleButton value="text">{t('Text')}</ToggleButton>
                                <ToggleButton value="image">{t('Image')} </ToggleButton>
                                <ToggleButton value="document">{t('Document')} </ToggleButton>
                                <ToggleButton value="video">{t('Video')} </ToggleButton>
                              </ToggleButtonGroup>  
                            </Box>
                            { watchType === 'text' && <Box sx={{ mt: 1, width: '100%' }} >                                
                                <TextField sx={{width:'calc(100% - 8px)'}} id="messageBody" label={t('MessageBody')} multiline rows={5}
                                  {...register('messageBody')} 
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                />   
                            </Box> }
                            { ['image','document','video'].includes(watchType) && <Box sx={{ mt: 1, width: '100%' }} >                                
                                <TextField sx={{width:'calc(100% - 8px)'}} id="fileCaption" label={t('File caption')} multiline rows={5}
                                  {...register('fileCaption')} 
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                />   
                            </Box> }
                            { ['document','video'].includes(watchType) && <Box sx={{ mt: 1, width: '100%' }} >                                
                                <TextField sx={{width:'calc(100% - 8px)'}} id="fileName" label={t('File name')}
                                  {...register('fileName')} 
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: isFalsy(getValues().base64File) } }
                                />   
                            </Box> }
                            { watchType !== 'text' && <Box key={`${watchFileName} - key`} sx={{ mt: 1, width: '100%' }} >  
                                <input type="file" id="file-upload" style={{ display: "none" }} onChange={setFile}
                                  accept={ (watchType === 'image') ? 'image/*' : 
                                            (watchType === 'video') ? 'video/*' :
                                              '.pdf,.csv,.doc,.docx,.xls,.xlsx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'} />
                                <Button onClick={openFileDialog}>
                                    {isFalsy(watchFileName) ? `${t('Click to toad file')} ...`: `${watchFileName} -> Click to change`}
                                </Button> 
                            </Box>}
                            { watchBase64File && watchType === 'image'  &&<Box sx={{ mt: 1, ml: 2, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}  >                                          
                                <img src={`data:image/png;base64,${watchBase64File}`} width='100%' />
                            </Box> }
                        </Stack>                        
                    </Grid>  
                    <Grid item xs={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                          <Box sx={{ mt: 1, width: '100%' }} >                                
                              <TextField sx={{width:'calc(80% - 8px)'}} id="messageBody" label={t('Add number by pressing ENTER')} 
                                {...register('currentRecipient')} multiline maxRows={1}
                                InputProps={{
                                  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
                                    const {currentRecipient, recipientNumber} = getValues();

                                    const setNumber = new Set( currentRecipient.split('\n').filter(n => 
                                        n.trim() !== '' && !recipients.includes(n.trim()) ) );
                                    const currentRecipients = Array.from(setNumber);
                                    
                                    //const recipients = recipientNumber.split(',');
                                    
                                    if(event.key === 'Enter') {
                                      if(currentRecipients.length > 0)
                                        setValue('recipientNumber', `${recipientNumber},${currentRecipients.join(',')}`.replace(/^,+/, '') ); 

                                      setValue('currentRecipient', '');                                     
                                    }
                                  },
                                }}                                
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                              />   
                          </Box>
                          <Box sx={{ mt: 1, width: '100%' }} key={getValues().recipientNumber}>        
                            {                              
                              recipients.map( (num,idx) => 
                                (!isFalsy(num) && <Chip sx={{ ml: 1, mr:1, mb: 1 }}
                                    key={`${idx} - ${num}`} label={num} color="primary" variant="outlined"
                                    onDelete={() => {setValue('recipientNumber', recipients.filter(recpt => recpt !== num).join(',')   )}} />) )
                            }
                          </Box>
                          <Box sx={{ mt: 1, width: '100%' }} key={recipients.length}>
                            <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                {` ${recipients.length} ${t(('Recipient'))}(s) `}
                            </Typography> 
                          </Box>
                        </Stack>                  
                    </Grid>
                    
                </Grid>
            </Box>
        </FormProvider> 
  )
}

