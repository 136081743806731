import { HeadCell } from 'components/ui/EnhancedTable';
import useAxios from 'library/axios'; 
import { useState } from 'react';
import {IExternalLibrary,  IExternalLibrarySearch } from "../models/ExternalLibrary";
import { useTranslation  } from 'react-i18next';
import { ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { useNavigate } from 'react-router-dom';


const _ = () => {

    const axios = useAxios();     

    const createExternalLibrary = async (externalLibrary: IExternalLibrary)  =>       
        await (await axios.post('/api/configuration/externalLibrary/create', externalLibrary)).data;       
        
    const updateExternalLibrary = async (externalLibrary: IExternalLibrary)  =>       
        await (await axios.post('/api/configuration/externalLibrary/update', externalLibrary)).data; 
    
    const getExternalLibrary = async (id  : number )  => {
      const {data} = (await axios.get(`/api/configuration/externalLibrary/get-externalLibrary/${id}`));
      return await data;
    }

    const getExternalLibraries = async (criteria: IExternalLibrarySearch) : Promise<IExternalLibrary[]> => {
    const {name, description} = criteria;

    const {data} = (await axios.get(`/api/configuration/externalLibrary/get-externalLibraries?name=${name}&description=${description}`));
    return await data;
    }
      
    return {    
      createExternalLibrary,
      updateExternalLibrary,

      getExternalLibrary,
      getExternalLibraries
      
    } 
}

export default _;


export const useBasicFilterExternalLibrary = ( onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IExternalLibrary) => void  ) => {

  const { getExternalLibraries } = _();

  const { t, i18n } = useTranslation();   
  const navigate = useNavigate();

  

  const [headExternalLibraryCells, setHeadExternalLibraryCells]  = useState<HeadCell<IExternalLibrary>[]>([
    {id:'name', label : t('Name'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },

  ]); 

  const [filterElements, ] = useState([       
        {name: 'name', text: t('Name'), value: ''}, 
        {name: 'description', text: t('Description'), value: ''},       
      ]);

  const [filteredExternalLibraries, ] = useState<IExternalLibrary[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]): Promise<IExternalLibrary[]> => {
            
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
    
    const arr = await getExternalLibraries( {name, description} );
    
    return arr;
  }

  const objKey: keyof IExternalLibrary = 'id';

  return {
    title: t('Request data'), headCells: headExternalLibraryCells, objKey,
    filterElements, rows: filteredExternalLibraries, 
    onFilterButtonClick, onRowDoubleClick
  }
}

