import { HeadCell } from 'components/ui/EnhancedTable';
import useAxios from 'library/axios'; 
import { useState } from 'react';
import {IMessageAutomation,  IMessageAutomationSearch } from "../models/MessageAutomation";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { useNavigate } from 'react-router-dom';


const _ = () => {

    const axios = useAxios();     

    const createMessageAutomation = async (messageAutomation: IMessageAutomation)  =>       
        await (await axios.post('/api/setup/messageAutomation/create', messageAutomation)).data;       
        
    const updateMessageAutomation = async (messageAutomation: IMessageAutomation)  =>       
        await (await axios.post('/api/setup/messageAutomation/update', messageAutomation)).data; 
    
    const getMessageAutomation = async (id  : number )  => {
      const {data} = (await axios.get(`/api/setup/messageAutomation/get-messageAutomation/${id}`));
      return await data;
    }

    const getMessageAutomations = async (criteria: IMessageAutomationSearch, pagination?: IPagination) : Promise<IMessageAutomation[]> => {
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {name, description, } = criteria;

      const {data} = (await axios.get(`/api/setup/messageAutomation/get-messageAutomations?description=${description}&name=${name}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }
      
    return {    
      createMessageAutomation,
      updateMessageAutomation,

      getMessageAutomation,
      getMessageAutomations 
    } 
}

export default _;


export const useBasicFilterMessageAutomation = ( onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IMessageAutomation) => void  ) => {

  const { getMessageAutomations } = _();

  const { t, i18n } = useTranslation();   
  const navigate = useNavigate();

  

  const [headMessageAutomationCells, setHeadMessageAutomationCells]  = useState<HeadCell<IMessageAutomation>[]>([
    {id:'name', label : t('Name'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },
    {id:'isActive', label : t('Active ?'),  display: true, type: 'boolean', },  
  ]); 

  const [filterElements, ] = useState([       
        {name: 'title', text: t('Title'), value: ''}, 
        {name: 'name', text: t('Name'), value: ''},                 
      ]);

  const [filteredMessageAutomations, ] = useState<IMessageAutomation[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination): Promise<IMessageAutomation[]> => {
            
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
    

    const arr = await getMessageAutomations( {name, description}, pagination );
    
    return arr;
  }

  const objKey: keyof IMessageAutomation = 'id';

  return {
    title: ` ${t('Message automation')}(s) `, headCells: headMessageAutomationCells, objKey,
    filterElements, rows: filteredMessageAutomations, 
    onFilterButtonClick, onRowDoubleClick
  }
}

