import { IMessage } from "./Message";
import { IPerson, defaultPerson } from "./Person";

export type ContactType = 'whatsApp' | 'email' | 'facebook' | 'twitter' | 'phoneCall' | 'mail' | 'linkedIn' | 'instagram' ;

export interface IContact {
  id: number,

  name: string,
  description: string,
  portable1: string,
  portable2: string,
  
  type: ContactType,

  whatsAppId: string,
  whatsAppNumber: string,  

  publicWhatsAppImageUrl: string,

  facebookAccount: string,
  emailAddress: string,
  mailTransmissionMode: string,
  twitterAccount: string,
  linkedInAccount: string,
  instagramAccount: string,

  personFullName: string,
  personFullNameFR: string,
  personFullNameEN: string,

  fullContactId: string,

  person: IPerson,
  
  messages: IMessage[]
}

export interface IChatRoom {
  id: number,

  contacts: IContact[]
}

export interface IContactDashboardDay {
    
  contactMessageCount: number,
  contactNonTicketableMessageCount: number,
  contactTicketableMessageCount: number,
  contactNewMessageCount: number,
            
  contactCountPerAge: {label: string, value: number}[],
  messageCountPerContactAge: {label: string, value: number}[],
}


export interface IContactSearch {
  name: string,
  description: string,
  portable: string,  

  personName: string
}

export const defaultContact : IContact = {
  id: 0,

  name: '',
  description: '',
  portable1: '',
  portable2: '',  
  
  type: 'whatsApp',

  whatsAppId: '',
  whatsAppNumber: '',  

  publicWhatsAppImageUrl: '',

  facebookAccount: '',
  emailAddress: '',
  mailTransmissionMode: '',
  twitterAccount: '',
  linkedInAccount: '',
  instagramAccount: '',

  personFullName: '',
  personFullNameFR: '',
  personFullNameEN: '',

  fullContactId: '',

  person: defaultPerson,
  
  messages: []
}

export const defaultContactDashboardDay : IContactDashboardDay = {
    
  contactMessageCount: 0,
  contactNonTicketableMessageCount: 0,
  contactTicketableMessageCount: 0,
  contactNewMessageCount: 0,
            
  contactCountPerAge: [],
  messageCountPerContactAge: []
}

export const defaultChatRoom : IChatRoom = {
  id: 0,

  contacts: []
}