

export interface IExternalLibrary {
    id: number,
    name: string,
    description: string,

    fileName: string,

    base64LibraryFile?: string | ArrayBuffer | null,

    externalLibraryClasses: IExternalLibraryClass[]
  }

  export interface IExternalLibraryClass {
    className: string,
    classFullName: string,
    isAbstractClass: boolean,
    hasDefaultConstructor: boolean,
    numberOfContructor: number,
    numberOfMethod: number,

    externalLibraryClassMethods: IExternalLibraryClassMethod[]
  }

  export interface IExternalLibraryClassMethod {
    methodName: string,
    paramsTextDeclaration: string,
    returnTextType: string,    
  }


  export const defaultExternalLibrary : IExternalLibrary = {
    id: 0,
    name: '',
    description: '',
    fileName: '',

    externalLibraryClasses: []
  }

  export const defaultExternalLibraryClass : IExternalLibraryClass = {
    className: '',
    classFullName: '',
    isAbstractClass: false,
    hasDefaultConstructor: false,
    numberOfContructor: 0,
    numberOfMethod: 0,

    externalLibraryClassMethods: []
  }

  export interface IExternalLibrarySearch {
    name: string,
    description: string
  }