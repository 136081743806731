export interface ISmsApi {
    id: number,
    name: string,
    description: string,
    
    isActive: boolean,
    urlSendMessageApi: string,
    urlCheckBalanceApi: string,
    returnMessageOkExpression: string,
    balanceCountExpression: string,
    recipientName: string,
    messageName: string,
    phoneNumberLength: number,
    phoneNumberPrefix: string,    

    smsApiQueryParameters: ISmsApiQueryParameter[],
    smsApiOperators: ISmsApiOperator[]
}

export interface ISmsApiQueryParameter {
  id: number,
  smsApiId: number,
  name: string,
  value: string,
}

export interface ISmsApiOperator {
  id: number,
  smsApiId: number,
  initialNumber: string,
  priority: number,  
}



  export const defaultSmsApi : ISmsApi = {
    id: 0,
    name: '',
    description: '',

    isActive: true,
    urlSendMessageApi: '',
    urlCheckBalanceApi: '',
    returnMessageOkExpression: '',
    balanceCountExpression: '',
    recipientName: '',
    messageName: '',
    phoneNumberLength: 0,
    phoneNumberPrefix: '',

    smsApiQueryParameters: [],
    smsApiOperators: []
  }


  export interface ISmsApiSearch {
    name: string,
    description: string
  }