
export type MessageAutomationType = 'nonTicketable' | 'ticketable' | 'newTicket' | 'attachedTicket';

export interface IMessageAutomation {
    id: number,

    name: string,
    description: string,

    isActive: boolean,
    priority: number,
    matchingExpression: string,
    
    type:	MessageAutomationType,

    newTicketTitleExpression: string,
    newTicketDescriptionExpression: string,
    newTicketCategoryExpression: string,
    newTicketSeverityExpression: string,
    newTicketAssignedUserExpression: string,
    
    ticketFilterExpression: string,        
  }


  export const defaultMessageAutomation : IMessageAutomation = {
    id: 0,
    name: '',
    description: '',
    
    isActive: true,
    priority: 0,
    matchingExpression: '',
    
    type:	'nonTicketable',

    newTicketTitleExpression: '',
    newTicketDescriptionExpression: '',
    newTicketCategoryExpression: '',
    newTicketSeverityExpression: '',
    newTicketAssignedUserExpression: '',
    ticketFilterExpression: '',
  }


  export interface IMessageAutomationSearch {
  
    name: string,
    description: string,  
  }
