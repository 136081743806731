
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { SnackbarAction, SnackbarKey, useSnackbar } from 'notistack';

import { useTheme } from '@mui/material/styles';

import { Control, Controller, FieldArray, FieldArrayMethodProps, FieldPath, FieldValues, FormProvider, UseFormGetValues, UseFormReturn, UseFormSetValue, UseFormWatch, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import MoreIcon from '@mui/icons-material/More';


import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom, 
      isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import usePersonService, { useBasicFilterPerson, useBasicFilterPersonSearch } from './services/Person';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip, ToggleButtonGroup, ToggleButton } from '@mui/material';
import NumberFormat from 'react-number-format';
import format from 'date-fns/format';

import { IPerson, IPersonFinancialSituation, PersonType, defaultPerson, defaultPersonFinancialSituation } from './models/Person';

import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';


import IEnumeration, { IEnumerationItem, Enum_TOWN, Enum_COUNTRY,
  Enum_DEFAULT_LANGUAGE, Enum_PERSON_GENDER, Enum_PERSON_CIVILITY, Enum_CORPORATION_TYPE,
    Enum_PERSON_IDENTIFICATION } from 'features/configuration/models/Enumeration';


import { IExtensionType  } from 'features/configuration/models/ExtensionType';


import { justifyCenter, typographyGroupBoxStyling, carouselImage } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';

//import useSchoolYearService, { useBasicFilterSchoolYear } from 'features/setup/services/SchoolYear';

import { DatePicker, DesktopDatePicker } from '@mui/x-date-pickers';


import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';

import { debounce } from 'lodash';
import { GrClose, GrSearch } from 'react-icons/gr';

import WidgetSummaryBasic from 'features/widgets/WidgetSummaryBasic';
import EnhancedTable from 'components/ui/EnhancedTable';
import { getValue } from '@testing-library/user-event/dist/utils';



export interface BasicPersonFormProps<O extends FieldValues> {
  
  //control: Control<O>,
  personKeyPath: FieldPath<O> ,  
  personPath: FieldPath<O> ,
  isIdentityOnly?: boolean
  
  // getValues: UseFormGetValues<O>,
  // setValue: UseFormSetValue<O>,
  // watch: UseFormWatch<O>,
}

//type HasPersonPath<O extends FieldValues> = O extends { personPath: IPerson } ? BasicPersonFormProps<O> : never;




export const BasicPersonForm : React.FC<BasicPersonFormProps<{ [key: string]: IPerson }>> = ({personKeyPath, personPath, isIdentityOnly }) =>  {

  //const {setValue ,getValues, watch, control} = props;

  const {control, getValues, watch, setValue} = useFormContext();

  
  const pathNameDef = String(personPath);

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {id} = useParams();
  const theme = useTheme();

  const {language: lg} = useRecoilValue(currentUserSessionAtom);
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const {getEnumerationItemsByEnumerationCodes,getExtensionEnumerationItemsBySchoolYear, getAsOptions } = useEnumerationService();
  
  const { createPerson, updatePerson, getPersonFinancialSituation, 
      getPersonsSearchCount, defineClientEmployee, updateClientEmployee, 
      getBirthDateType, getDateFormat, getDayFormat } = usePersonService();


  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  //const refSchoolYears = useRef<ISchoolYear[]>();

  
      
  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          // setValue('entityName', name || '');
          // setValue('entityDescription', description || '');
                           
          setOpenEntityFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );

  

  const refFirstName = useRef<string>('');
  const refLastName = useRef<string>('');
  const refCorporationName = useRef<string>('');
  const refParticularSign = useRef<string>('');
  const refId = useRef<number>(getValues().id);

  const refType = useRef<PersonType>('physical');
  
  
  const emptyFunc = (obj: any) => {}
  
  // const methods = useForm<IPerson>({defaultValues:defaultPerson});
  // const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchId = watch(`${pathNameDef}.id`);

  const watchType = watch(`${pathNameDef}.type`);

  
  const watchFirstName = watch(`${pathNameDef}.firstName`);
  const watchLastName = watch(`${pathNameDef}.lastName`);
  const watchCorporationName = watch(`${pathNameDef}.corporationName`);

  const watchBirthDateType = watch(`${pathNameDef}.birthDateType`);
  const watchParticularSign = watch(`${pathNameDef}.particularSign`);

  const watchCreationDateType = watch(`${pathNameDef}.creationDateType`);



  
  type SearchPersonUsage = 'person' | 'father' | 'mother' | 'tutor';
  const [currentSearchPersonUsage, setCurrentSearchPersonUsage] = useState<SearchPersonUsage>('person');

  const [openPersonSearchFilter, setOpenPersonSearchFilter] = useState(false);
  const basicFilterPersonSearch = useBasicFilterPersonSearch( 
      () => {
        // const { firstName, lastName, particularSign,
        //         fatherFirstName, fatherLastName, fatherParticularSign,
        //         motherFirstName, motherLastName, motherParticularSign,
        //         tutorFirstName, tutorLastName, tutorParticularSign} = getValues();

        // const _firstName = (currentSearchPersonUsage === 'father') ? fatherFirstName :
        //             (currentSearchPersonUsage === 'mother') ? motherFirstName :
        //               (currentSearchPersonUsage === 'tutor') ? tutorFirstName : firstName;
        // const _lastName = (currentSearchPersonUsage === 'father') ? fatherLastName :
        //             (currentSearchPersonUsage === 'mother') ? motherLastName :
        //               (currentSearchPersonUsage === 'tutor') ? tutorLastName : lastName;
        // const _particularSign = (currentSearchPersonUsage === 'father') ? fatherParticularSign :
        //               (currentSearchPersonUsage === 'mother') ? motherParticularSign :
        //                 (currentSearchPersonUsage === 'tutor') ? tutorParticularSign : particularSign;

        
        return {firstName: refFirstName.current, lastName: refType.current === 'moral' ? refCorporationName.current : refLastName.current, 
                    particularSign: refParticularSign.current};
        //return refPersonSearch.current || {firstName: '', lastName: '', particularSign: ''};
      },      
      (event: React.MouseEvent<unknown>, row: IPerson) => {
          const {id, firstName, lastName, birthDate, birthPlace, particularSign, email1, email2, corporationName, corporationType, creationDate,
              portable1, portable2, country, town, addressLine1, addressLine2} = row;
        
          setValue(personKeyPath, id);

          setValue(`${pathNameDef}.id`, id); refId.current = id;
          setValue(`${pathNameDef}.firstName`, firstName);
          setValue(`${pathNameDef}.lastName`, lastName);  
          setValue(`${pathNameDef}.birthDate`, birthDate);   
          setValue(`${pathNameDef}.birthPlace`, birthPlace);                    
          setValue(`${pathNameDef}.particularSign`, particularSign);   
          
          setValue(`${pathNameDef}.corporationName`, corporationName);
            setValue(`${pathNameDef}.corporationType`, corporationType);
            setValue(`${pathNameDef}.creationDate`, creationDate);
          
          setValue(`${pathNameDef}.email1`, email1);
          setValue(`${pathNameDef}.email2`, email2);  
          setValue(`${pathNameDef}.portable1`, portable1);   
          setValue(`${pathNameDef}.portable2`, portable2);                    
          setValue(`${pathNameDef}.country`, country);     
          setValue(`${pathNameDef}.town`, town);     
          setValue(`${pathNameDef}.addressLine1`, addressLine1);     
          setValue(`${pathNameDef}.addressLine2`, addressLine2); 
                          
        setOpenPersonSearchFilter(false);
      }
  );

  const [openPersonFilter, setOpenPersonFilter] = useState(false);
  const basicFilterPerson = useBasicFilterPerson( 
      (event: React.MouseEvent<unknown>, row: IPerson) => {
          const {id, type, firstName, lastName, birthDate, birthPlace, 
            particularSign, corporationName, corporationType, creationDate,
            portable1, portable2, email1, email2, country, town, addressLine1, addressLine2} = row;
                
            
            setValue(personKeyPath, id);
            setValue(`${pathNameDef}.id`, id); refId.current = id;
            setValue(`${pathNameDef}.firstName`, firstName);
            setValue(`${pathNameDef}.lastName`, lastName);  
            setValue(`${pathNameDef}.birthDate`, birthDate);   
            setValue(`${pathNameDef}.birthPlace`, birthPlace);                    
            setValue(`${pathNameDef}.particularSign`, particularSign);      

            setValue(`${pathNameDef}.corporationName`, corporationName);
            setValue(`${pathNameDef}.corporationType`, corporationType);
            setValue(`${pathNameDef}.creationDate`, creationDate);
            
            setValue(`${pathNameDef}.email1`, email1);
            setValue(`${pathNameDef}.email2`, email2);  
            setValue(`${pathNameDef}.portable1`, portable1);   
            setValue(`${pathNameDef}.portable2`, portable2);                    
            setValue(`${pathNameDef}.country`, country);     
            setValue(`${pathNameDef}.town`, town);     
            setValue(`${pathNameDef}.addressLine1`, addressLine1);     
            setValue(`${pathNameDef}.addressLine2`, addressLine2); 
        
       
        setOpenPersonFilter(false);
      }
  );


   // let { fields, append: appendCoverages, update: updateCoverage ,remove: removeCoverage,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
  //   name: `productCoverages`,
  //   control,            
  // });
  

  const queryClient = useQueryClient();
  
      //// Mutation for actions.....
      
    const {data: _data, refetch} = useQuery<IPerson>(['Person', _id], () => retrieveEntity('Person',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    
    const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'Person'], () => getEnumerationItemsByEnumerationCodes
        ( [Enum_TOWN, Enum_COUNTRY, Enum_DEFAULT_LANGUAGE, Enum_PERSON_IDENTIFICATION, Enum_CORPORATION_TYPE,
              Enum_PERSON_GENDER, Enum_PERSON_CIVILITY ] ));

    //const {data: schoolYears} = useQuery<ISchoolYear[]>( ['SchoolYears'], () => getSchoolYears( {year: 0, name: ''}) );

    type ParentType = 'father' | 'mother' | 'tutor';
    const [currentParentType, setCurrentParentType] = useState<ParentType>('father');
    const handleChangeCurrentParentType = ( event: React.MouseEvent<HTMLElement>, newParentType: ParentType ) => {  
      if(isFalsy(newParentType) ) 
        return;

      setCurrentParentType(newParentType);
    }

    const handleClickOpenPerson = async (event: any) => {
      setOpenPersonFilter(true);
    }

    const handleClickRemovePerson = async (event: any) => {
      
      setValue(personKeyPath, 0);
      setValue(`${pathNameDef}.id`, 0); refId.current = 0;
      setValue(`${pathNameDef}.firstName`, '');
      setValue(`${pathNameDef}.lastName`, '');  
      setValue(`${pathNameDef}.birthDate`, new Date());   
      setValue(`${pathNameDef}.birthPlace`, '');                    
      setValue(`${pathNameDef}.particularSign`, '');      

      setValue(`${pathNameDef}.corporationName`, '');
      setValue(`${pathNameDef}.corporationType`, '');
      setValue(`${pathNameDef}.creationDate`, new Date());
      
      setValue(`${pathNameDef}.email1`, '');
      setValue(`${pathNameDef}.email2`, '');  
      setValue(`${pathNameDef}.portable1`, '');   
      setValue(`${pathNameDef}.portable2`, '');                    
      setValue(`${pathNameDef}.country`, '');     
      setValue(`${pathNameDef}.town`, '');     
      setValue(`${pathNameDef}.addressLine1`, '');     
      setValue(`${pathNameDef}.addressLine2`, ''); 

         
    }

    

    const debouncedNameChange = useRef(
      debounce( async () => {      
        //const { id, firstName, lastName, particularSign} = getValues();

        
          
        if(refId.current>0 || watchId > 0) return;
          
        if( (isFalsy(refFirstName.current) || refFirstName.current.trim() === '') && 
              (isFalsy(refLastName.current) || refLastName.current.trim() === '' ) && 
              (isFalsy(refCorporationName.current) || refCorporationName.current.trim() === '' ))
         return;
  
        const count = await getPersonsSearchCount(refFirstName.current,
          refType.current === 'moral' ? refCorporationName.current : refLastName.current, refParticularSign.current);
  
        if(!isFalsy(refPersonSnackbarId.current)) closeSnackbar(refPersonSnackbarId.current!);
        
        if(count > 0) 
          refPersonSnackbarId.current = enqueueSnackbar( `${t('Identity')} --> ${t('You have to check if person exists before save, verify it in this list of')} : ${count} ${t('_person')}(s)`, { variant: 'warning',
                anchorOrigin : { horizontal: 'left', vertical: 'bottom' }, persist : true, //autoHideDuration : 5000,
                action: xActionPersonSearch('person') } );             
  
      }, 2000)
    ).current;

    
    

    

    const refPersonSnackbarId = useRef<SnackbarKey>();
    
    const xActionPersonSearch = (searchPersonUsage: SearchPersonUsage): SnackbarAction => (snackbarId: SnackbarKey) => (
      <>        
          <Button onClick={() => { 
                  // setUrlPdf(`${globalConfig.get().apiUrl}/Download/${fileToken}/${fileName}`); 
                  setCurrentSearchPersonUsage(searchPersonUsage);                  
                  setOpenPersonSearchFilter(true);
                  closeSnackbar(snackbarId); }}>   
              <GrSearch size={24} />
          </Button>
          <Button onClick={() => { closeSnackbar(snackbarId) }}>
              <GrClose size={24} />
          </Button>
      </>
  )

  
 
  useEffect( () => {     
    setCurrentSearchPersonUsage('person');    
        
    refFirstName.current = watchFirstName;
    refLastName.current = watchLastName;
    refParticularSign.current = watchParticularSign;
    refCorporationName.current = watchCorporationName;


    debouncedNameChange();      
    }, [watchFirstName, watchLastName, watchCorporationName, watchParticularSign, debouncedNameChange]);


    useEffect( () => {     
      
      refType.current = watchType;
   
      }, [watchType]);

    
       
  return (
    
            <Box sx={{ mt: 0.0, width: '100%' }}>
                <Grid container rowSpacing={0.1} columnSpacing={0.1}>
                    <Grid item xs={12} md={12} lg={12} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column' sx={{ pt: 0.25, width: '100%' }} >                           
                            
                            <Box sx={{ mt: 0.0, width: '100%' }} >                                                               
                              
                              <Controller name={`${pathNameDef}.type`} control={control}                                     
                                render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="type"
                                    label={t('Person type')} inputProps={ {readOnly: false}} focused >
                                      <MenuItem value="physical">{t('Physical')}</MenuItem>
                                      <MenuItem value="moral">{t('Moral')}</MenuItem>
                                  </TextField>
                                )}
                              />
                              { (watchType === 'physical') && <Controller 
                                  name={lg.startsWith('fr')?`${pathNameDef}.lastName`:`${pathNameDef}.firstName`} control={control}   
                                  render={({ field }) => <TextField sx={{width:`calc(35% - 8px)`}} {...field} 
                                      label={lg.startsWith('fr')?t('Last name'):t('First name')}
                                      InputProps={{                                        
                                        endAdornment: (
                                          <InputAdornment position="end">  
                                          { (isFalsy(watchId) ||  watchId<=0) ?
                                              <IconButton color="primary" onClick={handleClickOpenPerson}>
                                                <ArrowDropDownCircleIcon />
                                              </IconButton> : 
                                              <IconButton color="primary" onClick={handleClickRemovePerson}>
                                                <RemoveCircleIcon />
                                              </IconButton>
                                            }                                                                                                                                     
                                        </InputAdornment>
                                      ) 
                                    }}   />}                                  
                                  /> }   
                              { (watchType === 'physical') && <Controller name={lg.startsWith('fr')?`${pathNameDef}.firstName`:`${pathNameDef}.lastName`} control={control}                                     
                                  render={({ field }) => <TextField sx={{width:`calc(35% - 8px)`}} {...field} 
                                  label={lg.startsWith('fr')?t('First name'):t('Last name')}   />}
                                  /> } 
                              { (watchType === 'physical') && <Controller name={`${pathNameDef}.particularSign`}  control={control}                                     
                                  render={({ field }) => <TextField sx={{width:`calc(15% - 8px)`}} {...field} 
                                  label={t('Part. sign')}   />}
                                  /> }
                              { (watchType === 'moral') && <Controller name={`${pathNameDef}.corporationName`}  control={control}                                     
                                  render={({ field }) => <TextField sx={{width:`calc(30% - 8px)`}} {...field} 
                                  label={t('Name')} 
                                  InputProps={{                                        
                                    endAdornment: (
                                      <InputAdornment position="end">  
                                      { (isFalsy(watchId) ||  watchId<=0) ?
                                          <IconButton color="primary" onClick={handleClickOpenPerson}>
                                            <ArrowDropDownCircleIcon />
                                          </IconButton> : 
                                          <IconButton color="primary" onClick={handleClickRemovePerson}>
                                            <RemoveCircleIcon />
                                          </IconButton>
                                        }                                                                                                                                     
                                    </InputAdornment>
                                  ) 
                                }}  />}
                                  /> }
                              { (watchType === 'moral') && <Controller name={`${pathNameDef}.corporationType`} control={control}                                     
                                render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(20% - 8px)'}} id="corporationType"
                                    label={`Corp. ${t('Type')}`} inputProps={ {readOnly: false}} focused >
                                    {enumItems && enumItems.filter( e => 
                                            e.enumerationCode === Enum_CORPORATION_TYPE ).map( 
                                        (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                      }
                                  </TextField>
                                )} />  }
                                { (watchType === 'moral') && <Controller name={`${pathNameDef}.creationDateType`} control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="creationDateType"
                                        label={t('Date type')} inputProps={ {readOnly: false}} focused >
                                        {getBirthDateType().map( 
                                          (x,idx) => <MenuItem key={x.value} value={x.value}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                /> }
                                { (watchType === 'moral') && <Controller key={`${watchCreationDateType} dob`} control={control}
                                    name={`${pathNameDef}.creationDate`} 
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                      <DatePicker label={t('Creation date')} 
                                        onChange={onChange}                
                                        inputFormat={watchCreationDateType==='day'?getDayFormat():getDateFormat()}
                                        value={watchCreationDateType==='none'?null:value}
                                        views={watchCreationDateType==='day'?['month','day']: ['year', 'month', 'day']}
                                        disabled={watchCreationDateType==='none'}
                                        
                                        //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                        renderInput={(params) => <TextField {...params}  sx={{width:'calc(20% - 8px)'}} />}
                                      /> )}
                                  />
                                }
                              
                              { openPersonFilter && <FormDialog open={openPersonFilter} maxWidth='md'
                                  okText='' cancelText='' title={t('Person')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenPersonFilter(false);}} onOk={()=> {setOpenPersonFilter(false);}}  >
                                      <BasicTextFilterForm<IPerson> {...basicFilterPerson } />
                              </FormDialog> } 
                              { openPersonSearchFilter && <FormDialog open={openPersonSearchFilter} maxWidth='md'
                                  okText='' cancelText='' title={t('Person')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenPersonSearchFilter(false);}} onOk={()=> {setOpenPersonSearchFilter(false);}}  >
                                      <BasicTextFilterForm<IPerson> {...basicFilterPersonSearch } />
                              </FormDialog> }                          
                                
                            </Box>                                                   
                            { (watchType === 'physical') &&<Box sx={{ mt: 1, width: '100%' }} >
                              <Controller name={`${pathNameDef}.gender`} control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="gender"
                                        label={t('Gender')} inputProps={ {readOnly: false}} focused >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_PERSON_GENDER ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                /> 
                                <Controller name={`${pathNameDef}.civility`} control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="civility"
                                        label={t('Civility')} inputProps={ {readOnly: false}} focused >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_PERSON_CIVILITY ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                /> 
                                <Controller name={`${pathNameDef}.birthDateType`} control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="civility"
                                        label={t('Date type')} inputProps={ {readOnly: false}} focused >
                                        {getBirthDateType().map( 
                                          (x,idx) => <MenuItem key={x.value} value={x.value}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                              <Controller key={`${watchBirthDateType} dob`} control={control}
                                  name={`${pathNameDef}.birthDate`} 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Birth date')} 
                                      onChange={onChange}                
                                      inputFormat={watchBirthDateType==='day'?getDayFormat():getDateFormat()}
                                      value={watchBirthDateType==='none'?null:value}
                                      views={watchBirthDateType==='day'?['month','day']: ['year', 'month', 'day']}
                                      disabled={watchBirthDateType==='none'}
                                      
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                      renderInput={(params) => <TextField {...params}  sx={{width:'calc(20% - 8px)'}} />}
                                    /> )}
                                />
                              <Controller name={`${pathNameDef}.birthPlace`} control={control}   
                                    render={({ field }) => <TextField sx={{width:`calc(35% - 8px)`}} {...field} 
                                    label={t('Birth place')}   />}                                  
                                    />                              
                            </Box>  }
                            {isIdentityOnly? null : <>                      
                              <Box sx={{ mt: 0.5, width: '100%' }} > 
                                <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                      sx={{...typographyGroupBoxStyling}}>
                                  {`${t(('Contacts'))} `}
                                </Typography>                                                       
                              </Box>
                              <Box sx={{ mt: 0.2, width: '100%' }} > 
                                <Controller name={`${pathNameDef}.portable1`} control={control}   
                                    render={({ field }) => <TextField sx={{width:`calc(15% - 8px)`}} {...field} 
                                    label={`${t('Portable')} 1`}   />}                                  
                                />                              
                                <Controller name={`${pathNameDef}.portable2`} control={control}   
                                    render={({ field }) => <TextField sx={{width:`calc(15% - 8px)`}} {...field} 
                                    label={`${t('Portable')} 2`}   />}                                  
                                />
                                <Controller name={`${pathNameDef}.email1`} control={control}   
                                    render={({ field }) => <TextField sx={{width:`calc(35% - 8px)`}} {...field} 
                                    label={`${t('Email')} 1`} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }  />}                                  
                                />
                                <Controller name={`${pathNameDef}.email2`} control={control}   
                                    render={({ field }) => <TextField sx={{width:`calc(35% - 8px)`}} {...field} 
                                    label={`${t('Email')} 2`} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }  />}                                  
                                />
                                                                                    
                              </Box>
                              <Box sx={{ mt: 0.2, width: '100%' }} > 
                                <Controller name='country' control={control}                                     
                                      render={ ({field: {onChange, value}}) => (
                                        <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="country"
                                          label={t('Country')} inputProps={ {readOnly: false}} focused >
                                          {enumItems && enumItems.filter( e => 
                                                e.enumerationCode === Enum_COUNTRY ).map( 
                                            (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                          }
                                        </TextField>
                                      )}
                                  />
                                <Controller name='town' control={control}                                     
                                      render={ ({field: {onChange, value}}) => (
                                        <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="town"
                                          label={t('Town')} inputProps={ {readOnly: false}} focused >
                                          {enumItems && enumItems.filter( e => 
                                                e.enumerationCode === Enum_TOWN ).map( 
                                            (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                          }
                                        </TextField>
                                      )}
                                  />
                                <Controller name='addressLine1' control={control}   
                                    render={({ field }) => <TextField sx={{width:`calc(35% - 8px)`}} {...field} 
                                    label={`${t('Address')} 1`}   />}                                  
                                />
                                <Controller name='addressLine2' control={control}   
                                    render={({ field }) => <TextField sx={{width:`calc(35% - 8px)`}} {...field} 
                                    label={`${t('Address')} 2`}   />}                                  
                                />  
                                  
                                                                                      
                              </Box>
                            </>}
                            
                        </Stack>                        
                      </Grid>                  
                </Grid>
            </Box>
        
  )
}

