//import { DataType } from "features/configuration/models/ExtensionType";
//import { IUserBusinessApplication } from "features/security/models/User";
//import { IRequestTypeBusinessApp } from "./RequestType";
import { isFalsy } from "utility-types";
import { IEnumerationItem } from "features/configuration/models/Enumeration";
import { BaseType } from "components/ui/ArrayField";



export type ParameterDataType = 'string' | 'integer' | 'long' | 'decimal' | 'float' | 'date' | 'boolean' | 'time' ;

export const toBaseType = (paramDataType: ParameterDataType ) : BaseType => {

  switch(paramDataType) {
    case 'string': return 'string';
    case 'integer': case 'long': case 'decimal': case 'float': return 'numeric';
    case 'date': return 'date';
    case 'boolean': return 'boolean';
    case 'time': return 'time';
    default: return 'string';
  }
}

export const toDataType = (parameter: IApplicationQueryParameter ) : ('Base' | 'Enumeration' | 'Entity' | 'Period') => {

  switch(parameter.parameterDataType) {
    case 'string': return (parameter.enumerationId??0)<=0 ? 'Base' : 'Enumeration';
    case 'integer': return isFalsy(parameter.isPeriod) ? 'Base' : 'Period';
    case 'decimal': case 'float': return 'Base';
    case 'date': return 'Base';
    case 'boolean': return 'Base';
    case 'time': return 'Base';
    case 'long': return isFalsy(parameter.entityName) ? 'Base' : 'Entity';
    default: return 'Base';
  }
}

export const defaultValue = (parameter: IApplicationQueryParameter): any => {
  switch(parameter.parameterDataType) {
    case 'boolean': return false;
    case 'date': return new Date();
    case 'decimal': return 0;
    case 'float': return 0;    
    
    case 'long': return 0;
    case 'integer': return isFalsy(parameter.isPeriod)? 0 : new Date();
    case 'string': return (parameter.enumerationId??0)<=0? '' : 
      (parameter.parameterEnumerationItems??[]).some( x => true)?parameter.parameterEnumerationItems![0].code: '';
    case 'time': return new Date();

    default: return '';
  }
}

export interface IBusinessApplication {
    id: number,
    name: string,
    description: string,

    isDirectConnection: boolean,
    dbmsType: string,
    dbConnectionString: string,

    isAuthenticatedApi: boolean,
    apiUserName: string,
    apiPassword: string,
    authenticationUrl: string,

    localServerApiUrl: string,

    externalLibraryId: number,
    fullClassName: string,

    //requestTypeBusinessApps: IRequestTypeBusinessApp[],
    applicationQueries: IApplicationQuery[],

    businessApplicationProperties: IBusinessApplicationProperty[],

    //userBusinessApplications: IUserBusinessApplication[],
  }

  export interface ITableFilter {
    name: string,
    description: string
  }

  export interface IColumn {
    name: string, 
    description: string, 
    dataType: string, 
    length: number
  }

  export interface IBusinessApplicationProperty {
    id: number, 
    businessApplicationId: number,
    name: string,
    value: string
  }
  

export interface IApplicationQuery {
  id: number,
  
  name: string,
  description: string,
  isActive: boolean,

  baseTableFilter: string,
  baseTableFilterDecription: string,

  querySelect: string,
  queryGroup: string,
  queryFilter: string,

  baseEntity: string,

  methodName: string,

  jobs: any[],



  applicationQueryParameters: IApplicationQueryParameter[],  
  applicationQueryOutputs: IApplicationQueryOutput[],
  applicationQueryReports: IApplicationQueryReport[]
}

export interface IApplicationQueryParameter {
  id: number,
  businessApplicationQueryId: number,
  parameterName: string,

  parameterDataType: ParameterDataType,

  description?: string,

  enumerationId?: number,
  enumerationCode?: string,
  enumerationName?: string,
  parameterEnumerationItems?: IEnumerationItem[], 

  entityName?: string,
  entityDescription?: string,

  isPeriod?: boolean,

  jobScheduleParameters: any[],  
}

export interface IApplicationQueryReport {
  id: number,
  applicationQueryId: number,
  reportId: number,

  reportName: string,

  reportDescription: string
}

export interface IApplicationQueryOutput {
  id: number,
  applicationQueryId: number,

  type: 'base' | 'aggregation';
  alias: string,
  outputExpression: string,
  
  aggregationFunction: 'count' | 'sum' | 'min' | 'max' | 'avg'
}

export interface IRequestTypeBusinessAppCodification {
  id: number,
  requestTypeBusinessAppId: number,
  businessApplicationQueryId: number,
  requestClassEnumerationCode: string,
  defaultMappingCode: string,

  requestTypeBusinessAppCodificationMappings: IRequestTypeBusinessAppCodificationMapping[]
}

export interface IRequestTypeBusinessAppCodificationMapping {
  id: number,
  requestTypeBusinessAppId: number,
  businessApplicationQueryId: number,
  requestClassEnumerationCode: string,
  defaultMappingCode: string,
}

export const defaultBusinessApplication : IBusinessApplication = {
    id: 0,
    name: '',
    description: '',

    isDirectConnection: true,
    dbmsType: 'SQLSERVER',
    dbConnectionString: '',

    isAuthenticatedApi: false,
    apiUserName: '',
    apiPassword: '',
    authenticationUrl: '',

    localServerApiUrl: '',

    externalLibraryId: 0,
    fullClassName: '',

    businessApplicationProperties: [],
    //requestTypeBusinessApps: [],
    applicationQueries: [],
    //userBusinessApplications: []
  }

  export const defaultApplicationQuery : IApplicationQuery = {
    id: 0,
    
    name: '',
    description: '',
    isActive: true,

    baseTableFilter: '',
    baseTableFilterDecription: '',

    querySelect:'',
  queryGroup: '',
    queryFilter: '',

    baseEntity: '',

    methodName: '',

    jobs: [],

    applicationQueryParameters: [],
    applicationQueryOutputs: [],    
    applicationQueryReports: []
  }

  export const defaultApplicationQueryOutput : IApplicationQueryOutput = {
    id: 0,
    applicationQueryId: 0,

    type: 'base',
    alias: '',
    outputExpression: '',
    
    aggregationFunction: 'count'
  }


  export interface IBusinessApplicationSearch {    
    name: string,
    dbmsType: string
  }

  export interface IApplicationQuerySearch {    
    name: string,
    description: string
  }