import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject, Fragment}  from 'react';

import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import RepeatOneIcon from '@mui/icons-material/RepeatOne';
import FunctionsIcon from '@mui/icons-material/Functions';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';


import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import InsightsIcon from '@mui/icons-material/Insights';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import TableRowsIcon from '@mui/icons-material/TableRows';
import MoreIcon from '@mui/icons-material/More';


import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import { IEntity, IResult } from 'library/interface';

import useTicketService, { useBasicFilterTicket } from './services/Ticket';

import {  ITicket, defaultTicket, ITicketComment, ITicketResolution, ITicketMessage, TicketType } from './models/Ticket';

import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';

import useEntityService, {useBasicFilterEntity, useBasicFilterFeatureDescription} from 'features/services/Entity';

import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';

import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { isFalsy } from 'utility-types';
import { FormDialog } from 'components/ui/FormDialog';
import { Checkbox, Chip, FormControlLabel, IconButton, InputAdornment, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import IEnumeration, { Enum_TICKET_CATEGORY, Enum_TICKET_RESOLUTION, Enum_TICKET_SEVERITY, Enum_TICKET_STATUS, 
                       Enum_TICKET_ENTITY, Enum_TARGET_SERVICE, IEnumerationItem, Enum_TICKET_STATUS_PURPOSE, Enum_TICKET_CLASS } from 'features/configuration/models/Enumeration';

import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';

import { typographyGroupBoxStyling, justifyCenter } from 'themes/commonStyles';
import { MdOutlineAdd } from 'react-icons/md';
import ExpressionBox from 'components/ui/ExpressionBox';
import EnhancedTable from 'components/ui/EnhancedTable';
import { useBasicFilterUser } from 'features/security/services/User';
import { IUser } from 'features/security/models/User';
import { useBasicFilterContact } from './services/Contact';
import { IContact } from './models/Contact';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { useBasicFilterProblem } from './services/Problem';
import { IProblem } from './models/Problem';

import {addYears, addMonths, addDays, subDays, addHours} from 'date-fns';

export const TicketForm: FC<ITicket> = (props: ITicket = defaultTicket) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const { enqueueSnackbar } = useSnackbar();

  const {retrieveEntity, retrieveData, openEntityActionDrawer, checkEntitySaveAuthorization} = useEntityService();
  const {createTicket, updateTicket} = useTicketService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();
 

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);


  const {language: lg, contractIds, roleEntities, applicationSetup} = useRecoilValue(currentUserSessionAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterTicket = useBasicFilterTicket( 
    
    async (event: React.MouseEvent<unknown>, row: ITicket) => {
      const {id, title } = row;
      
      setIsSearchBoxShow(false);
      _setId(row.id);
    }
  );

  
  const [openUserFilter, setOpenUserFilter] = useState(false);
  const basicFilterUser = useBasicFilterUser( 
      (event: React.MouseEvent<unknown>, row: IUser) => {
          const {id, userDescription} = row;
          setValue('assignedUserId', id);
          setValue('assignedUserName', userDescription);

          setOpenUserFilter(false);
      }
  );

  const refType = useRef<number>(0);

  const [openContactFilter, setOpenContactFilter] = useState(false);
  const basicFilterContact = useBasicFilterContact( refType.current,

      (event: React.MouseEvent<unknown>, row: IContact) => {
          const {id, name, description ,whatsAppId, fullContactId} = row;
          setValue('contactId', id);
          setValue('contactName', name);
          setValue('contactDescription', description);
          
          setValue('fullContactId', fullContactId);

          setOpenContactFilter(false);
      }
  );

  const [openProblemFilter, setOpenProblemFilter] = useState(false);
  const basicFilterProblem = useBasicFilterProblem( 
      (event: React.MouseEvent<unknown>, row: IProblem) => {
          const {id, title} = row;
          setValue('problemId', id);
          setValue('problemTitle', title);

          setOpenUserFilter(false);
      }
  );
  
  const emptyFunc = (obj: any) => {}

  const methods = useForm<ITicket>({defaultValues:defaultTicket});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;
    
  const watchAssignedUserId = watch('assignedUserId');
  const watchProblemId = watch('problemId');
  const watchType = watch('type');

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<ITicket>,Error,ITicket>(
      _id>0?updateTicket:createTicket, {   
        onSuccess: (data: IResult<ITicket>) => {
          enqueueSnackbar( t('Operation done !!!'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          setCurrentFormNameAtom(`${t('Ticket')} - # ${data.data.id} # ` );
          //queryClient.invalidateQueries(['Ticket',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
            setIsSaveLoading(false);
        }
      });

    
    const {data: _data, refetch} = useQuery<ITicket>(['Ticket', _id], () => retrieveEntity('Ticket',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'Ticket'], () => getEnumerationItemsByEnumerationCodes
        ( [Enum_TICKET_CLASS, Enum_TICKET_CATEGORY, Enum_TICKET_SEVERITY, Enum_TICKET_ENTITY, 
           Enum_TICKET_STATUS_PURPOSE, Enum_TARGET_SERVICE, Enum_TICKET_RESOLUTION, Enum_TICKET_STATUS ] ));
       
      const handleClickOpenUserFilter = (event: any) => {        
        setOpenUserFilter(true);
      }

      const handleClickRemoveUser = (event: any) => {
        setValue('assignedUserId', 0);
        setValue('assignedUserName', '');     
      }

      const handleClickOpenContactFilter = (event: any) => {        
        setOpenContactFilter(true);
      }

      const handleClickOpenProblemFilter = (event: any) => {        
        setOpenProblemFilter(true);
      }

      const handleClickRemoveProblem = (event: any) => {
        setValue('problemId', 0);
        setValue('problemTitle', '');     
      }
      
      const handleChangeTicketType = ( event: React.MouseEvent<HTMLElement>, newType: TicketType ) => {
       
        if (newType === null) 
          return;
        setValue('type', newType);              
      }
   
    
    useEffect( () => {              
      switch(watchType) {
        case 'whatsApp' : refType.current = 1; break;
        case 'phoneCall' : refType.current = 2;break;
        case 'facebook' : refType.current = 3;break;
        case 'email' : refType.current = 4;break;
        case 'mail' : refType.current = 5;break;
        case 'twitter' : refType.current = 6;break;
        case 'linkedIn' : refType.current = 7;break;
        case 'instagram' : refType.current = 8;break;

        default: refType.current = 0;
      }
    }, [watchType]);


  useEffect( () => {              
    setCurrentFormNameAtom(t('Ticket'));  
    setCurrentBasicTextFilterProps(basicFilterTicket);

    reset({...defaultTicket, effectiveDate: new Date(), 
      dueDate: addHours(new Date(), applicationSetup.defaultTicketDurationHours) });  
  }, []);

  

  /********** This use effect call retrieve data wich will call refetch and _data will be updated. 
and the new useEffect will take place ********************/
useEffect( () => {
         
    setCurrentFormNameAtom(_id>0?`${t('Ticket')} - # ${_id} # -`: `${t('Ticket')}` );
    if(_id > 0)
      retrieveData('Ticket',_id, refetch);  
  }, [_id] );


useEffect( () => {
   
    if(_data && _data.id > 0) {
    reset(_data);
}
}, [_data]);

const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
  _setId(0);           
  reset({...defaultTicket, effectiveDate: new Date(), 
                           dueDate: addHours(new Date(), applicationSetup.defaultTicketDurationHours) });    
}

const saveData = async (event: MouseEvent<HTMLButtonElement>) => {     
  
  if(!checkEntitySaveAuthorization('Ticket', _id)){
    setIsSaveLoading(false);
    return;
  }
    
  const data = getValues(); 
  if(data.title.trim() === '' || data.description.trim() === '') {
      enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
      setIsSaveLoading(false);
      return;
    }

    if(data.contactId <= 0) {
      enqueueSnackbar( t('Contact is not specified'), { variant: 'warning',
        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
      setIsSaveLoading(false);
      return;
    }

  mutate(data);
}

const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
  openEntityActionDrawer('Ticket', _id);
}



const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
//    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
//    await retrieveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
//    reset(_data);        
}

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={12} md={7} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <ToggleButtonGroup size="small"
                                color="primary" value={watchType} exclusive
                                onChange={ (event, newType) => handleChangeTicketType(event, newType,)}
                                aria-label="Small sizes" fullWidth
                              >
                                <ToggleButton value="whatsApp">{t('WhatsApp')}</ToggleButton>
                                <ToggleButton value="phoneCall">{t('Call')} </ToggleButton>
                                <ToggleButton value="facebook">{t('Facebook')} </ToggleButton>
                                <ToggleButton value="email">{t('Email')} </ToggleButton>
                                <ToggleButton value="mail">{t('Mail')} </ToggleButton>
                                <ToggleButton value="twitter">{t('Twitter')} </ToggleButton>
                                <ToggleButton value="linkedIn">{t('LinkedIn')}</ToggleButton>
                                <ToggleButton value="instagram">{t('Instagram')}</ToggleButton>
                              </ToggleButtonGroup>
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                              <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                              <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                              <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                              <TextField sx={{width:'calc(50% - 8px)'}} id="title" label={t('Title')} {...register('title')}
                                inputProps={ {  autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                              
                              <Controller control={control}
                                name='issueDate' 
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                  <DatePicker label={t('Issue date')} 
                                    onChange={onChange} disabled               
                                    value={value}
                                    renderInput={(params) => <TextField {...params} sx={{width:'calc(16% - 8px)'}} />}
                                  /> )}
                              />
                              <Controller 
                                  name={`ticketClassCode`}
                                  control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(34% - 8px)'}} id="ticketClassCode"
                                      label={t('Class')} inputProps={ {readOnly: false}}>                                
                                      {enumItems && enumItems.filter( e => 
                                          e.enumerationCode === Enum_TICKET_CLASS ).map( 
                                            (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                    </TextField>
                                  )}
                              />
                              
                             { openUserFilter && <FormDialog open={openUserFilter} maxWidth='md'
                                okText={''} cancelText={t('Cancel')} title={t('User filter')} onCancel={()=> {setOpenUserFilter(false);}} 
                                onClose={()=> {setOpenUserFilter(false);}} onOk={()=> {}}  >
                                    <BasicTextFilterForm<IUser> {...basicFilterUser } />
                            </FormDialog> }   
                            { openContactFilter && <FormDialog open={openContactFilter} maxWidth='md'
                                okText={''} cancelText={t('Cancel')} title={t('Contact filter')} onCancel={()=> {setOpenContactFilter(false);}} 
                                onClose={()=> {setOpenContactFilter(false);}} onOk={()=> {}}  >
                                    <BasicTextFilterForm<IContact> {...basicFilterContact } />
                            </FormDialog> } 
                            { openProblemFilter && <FormDialog open={openProblemFilter} maxWidth='md'
                                okText={''} cancelText={t('Cancel')} title={t('Problem')} onCancel={()=> {setOpenProblemFilter(false);}} 
                                onClose={()=> {setOpenProblemFilter(false);}} onOk={()=> {}}  >
                                    <BasicTextFilterForm<IProblem> {...basicFilterProblem } />
                            </FormDialog> }
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="description" label={t('Description')} 
                              inputProps={ { readOnly: false, autoComplete: 'new-password', style: {textTransform: 'none'} } }
                              {...register('description')} multiline rows={2} />
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(50% - 8px)'}} id="assignedUserName" label={t('Assigned to')} 
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  {...register('assignedUserName')} 
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: ( 
                                      <InputAdornment position="end">
                                        { (isFalsy(watchAssignedUserId) ||  watchAssignedUserId<=0) ? 
                                          <IconButton color="primary" onClick={handleClickOpenUserFilter}>
                                            <ArrowDropDownCircleIcon />
                                          </IconButton> :
                                          <IconButton color="primary" onClick={handleClickRemoveUser}>
                                            <RemoveCircleIcon />
                                          </IconButton>
                                        }                                                                                         
                                    </InputAdornment>
                                  )
                                }} />
                                <Controller control={control}
                                  name='effectiveDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DateTimePicker label={t('Effective date')} 
                                      onChange={onChange}               
                                      value={value}
                                      renderInput={(params) => <TextField {...params} sx={{width:'calc(25% - 8px)'}} />}
                                    /> )}
                                />
                                <Controller control={control}
                                  name='dueDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DateTimePicker label={t('Due date')} 
                                      onChange={onChange}               
                                      value={value}
                                      renderInput={(params) => <TextField {...params} sx={{width:'calc(25% - 8px)'}} />}
                                    /> )}
                                />
                            </Box>
                            
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(35% - 8px)'}} id="contactName" label={`${t('Contact')} - ${t('Name')}`} 
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  {...register('contactName')} 
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={handleClickOpenContactFilter}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  )
                                }} /> 
                                <TextField sx={{width:'calc(35% - 8px)'}} id="contactDescription" label={`${t('Contact')} - ${t('Description')}`} 
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  {...register('contactDescription')} 
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={handleClickOpenContactFilter}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  )
                                }} />
                                <TextField sx={{width:'calc(30% - 8px)'}} 
                                  inputProps={ { readOnly: true, autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  id="fullContactId" label={`Identification`} 
                                  {...register('fullContactId')} />
                                
                            </Box>
                            <Box sx={{ mt: 4, width: '100%' }} >
                              <Controller 
                                  name={`categoryCode`}
                                  control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(35% - 8px)'}} id="categoryCode"
                                      label={t('Category')} inputProps={ {readOnly: false}}>                                
                                      {enumItems && enumItems.filter( e => 
                                                  e.enumerationCode === Enum_TICKET_CATEGORY ).map( 
                                              (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                            }
                                    </TextField>
                                  )}
                              />
                              <Controller 
                                  name={`severityCode`}
                                  control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(35% - 8px)'}} id="severityCode"
                                      label={t('Severity')} inputProps={ {readOnly: false}}>                                
                                      {enumItems && enumItems.filter( e => 
                                                  e.enumerationCode === Enum_TICKET_SEVERITY ).map( 
                                              (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                            }
                                    </TextField>
                                  )}
                              />
                              <TextField sx={{width:'calc(30% - 8px)'}} id="problemTitle" label={t('Associated to problem')} 
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                {...register('problemTitle')} 
                                InputProps={{
                                  readOnly: true,
                                  endAdornment: ( 
                                    <InputAdornment position="end">
                                      { (isFalsy(watchProblemId) ||  watchProblemId<=0) ? 
                                        <IconButton color="primary" onClick={handleClickOpenProblemFilter}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton> :
                                        <IconButton color="primary" onClick={handleClickRemoveProblem}>
                                          <RemoveCircleIcon />
                                        </IconButton>
                                      }                                                                                         
                                  </InputAdornment>
                                )
                                }} />
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller 
                                  name={`status`}
                                  control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(22% - 8px)'}} id="status"
                                      label={t('Status')} inputProps={ {readOnly: true}}>                                
                                      {enumItems && enumItems.filter( e => 
                                                  e.enumerationCode === Enum_TICKET_STATUS ).map( 
                                              (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                            }
                                    </TextField>
                                  )}
                              />
                              <Controller control={control}
                                  name='statusDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Date')} 
                                      onChange={onChange}  readOnly disableOpenPicker                   
                                      value={value}
                                      renderInput={(params) => <TextField {...params} sx={{width:'calc(13% - 8px)'}} />}
                                    /> )}
                                />
                              <Controller 
                                name={`statusPurpose`}
                                control={control}
                                render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(35% - 8px)'}} id="statusPurpose"
                                    label={t('Status purpose')} inputProps={ {readOnly: true}}>                                
                                    {enumItems && enumItems.filter( e => 
                                                e.enumerationCode === Enum_TICKET_STATUS_PURPOSE ).map( 
                                            (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                          }
                                  </TextField>
                                )}
                              />                              
                              <Controller 
                                  name={`resolutionCode`}
                                  control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id="resolutionCode"
                                      label={t('Resolution step')} inputProps={ {readOnly: true}}>                                
                                      {enumItems && enumItems.filter( e => 
                                                  e.enumerationCode === Enum_TICKET_RESOLUTION ).map( 
                                              (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                            }
                                    </TextField>
                                  )}
                              />
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller 
                                  name={`ticketEntityCode`} 
                                  control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(35% - 8px)'}} id="ticketEntityCode"
                                      label={t('Entity responsible')} inputProps={ {readOnly: false}}>                                
                                      {enumItems && enumItems.filter( e => 
                                                  e.enumerationCode === Enum_TICKET_ENTITY ).map( 
                                              (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                            }
                                    </TextField>
                                  )}
                              />
                              <Controller 
                                  name={`targetServiceCode`}
                                  control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(35% - 8px)'}} id="targetServiceCode"
                                      label={t('Target service or department')} inputProps={ {readOnly: false}}>                                
                                      {enumItems && enumItems.filter( e => 
                                                  e.enumerationCode === Enum_TARGET_SERVICE ).map( 
                                              (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                            }
                                    </TextField>
                                  )}
                              />
                            </Box>
                        </Stack>                        
                    </Grid>                   
                    <Grid item xs={12}  md={5} component={Paper} >
                        <Stack flexDirection='column'>      
                                      
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <EnhancedTable<ITicketComment> 
                                    rows={getValues().ticketComments} 
                                    headCells={[            
                                      
                                      {id:'commentText', label : t('Name'),  display: true, type: 'string', width: 75,  },
                                      {id:'date', label : t('Date'),  display: true, type: 'date', width: 25,  },
                                      
                                    ]} 
                                    title={t('Comments')} objKey={'id'} 
                                    
                                    onRowSelected={undefined} onRowDoubleClick={undefined} 
                                    onRowCheckedSelectChange={undefined} toolbarActions={undefined} order='asc' 
                                    orderBy={'id'}
                                />                        
                            </Box>
                            
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <EnhancedTable<ITicketResolution> 
                                    rows={getValues().ticketResolutions} 
                                    headCells={[            
                                      
                                      {id:'previousResolutionCode', label : t('Previous'),  display: true, type: 'string', width: 40,  },
                                      {id:'nextResolutionCode', label : t('Next'),  display: true, type: 'string', width: 40,  },
                                      {id:'date', label : t('Date'),  display: true, type: 'date', width: 20,  },
                                      
                                    ]} 
                                    title={t('Resolution history')} objKey={'id'} 
                                    
                                    onRowSelected={undefined} onRowDoubleClick={undefined} 
                                    onRowCheckedSelectChange={undefined} toolbarActions={undefined} order='asc' 
                                    orderBy={'id'}
                                />
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <EnhancedTable<ITicketMessage> 
                                    rows={getValues().ticketMessages} 
                                    headCells={[            
                                      
                                     
                                      {id:'contractMessageBody', label : t('Next'),  display: true, type: 'string', width: 100,  },
                                                                            
                                    ]} 
                                    title={t('Messages')} objKey={'id'} 
                                    
                                    onRowSelected={undefined} onRowDoubleClick={undefined} 
                                    onRowCheckedSelectChange={undefined} toolbarActions={undefined} order='asc' 
                                    orderBy={'id'}
                                />
                            </Box>
                        </Stack> 
                    </Grid>
                </Grid>
            </Box>
        </FormProvider>
  )
}
