import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject, Fragment}  from 'react';

import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useRecoilState } from 'recoil';
import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import RepeatOneIcon from '@mui/icons-material/RepeatOne';
import FunctionsIcon from '@mui/icons-material/Functions';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';


import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import InsightsIcon from '@mui/icons-material/Insights';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import TableRowsIcon from '@mui/icons-material/TableRows';
import MoreIcon from '@mui/icons-material/More';


import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import { IEntity, IResult } from 'library/interface';

import useMessageAutomationService, { useBasicFilterMessageAutomation } from './services/MessageAutomation';

import {  IMessageAutomation, MessageAutomationType, defaultMessageAutomation } from './models/MessageAutomation';

import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';

import useEntityService, {useBasicFilterEntity, useBasicFilterFeatureDescription} from 'features/services/Entity';

import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';

import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { isFalsy } from 'utility-types';
import { FormDialog } from 'components/ui/FormDialog';
import { Checkbox, Chip, FormControlLabel, IconButton, InputAdornment, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import IEnumeration, { Enum_PROBLEM_STATUS, Enum_TICKET_CATEGORY, Enum_TICKET_RESOLUTION, Enum_TICKET_SEVERITY, Enum_TICKET_STATUS, IEnumerationItem } from 'features/configuration/models/Enumeration';

import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';

import { typographyGroupBoxStyling, justifyCenter } from 'themes/commonStyles';
import { MdOutlineAdd } from 'react-icons/md';
import ExpressionBox from 'components/ui/ExpressionBox';
import EnhancedTable from 'components/ui/EnhancedTable';
import { useBasicFilterUser } from 'features/security/services/User';
import { IUser } from 'features/security/models/User';

import { DatePicker } from '@mui/x-date-pickers';
import NumberFormat from 'react-number-format';
import TextFieldRight from 'components/ui/TextFieldRight';
import { getValue } from '@testing-library/user-event/dist/utils';


export const MessageAutomationForm: FC<IMessageAutomation> = (props: IMessageAutomation = defaultMessageAutomation) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const { enqueueSnackbar } = useSnackbar();

  const {retrieveEntity, retrieveData, openEntityActionDrawer, checkEntitySaveAuthorization} = useEntityService();
  const {createMessageAutomation, updateMessageAutomation} = useMessageAutomationService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();

  

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterMessageAutomation = useBasicFilterMessageAutomation( 
    
    async (event: React.MouseEvent<unknown>, row: IMessageAutomation) => {
      const {id, name } = row;
      
      setIsSearchBoxShow(false);
      _setId(row.id);
    }
  );

  
    
  const emptyFunc = (obj: any) => {}

  const methods = useForm<IMessageAutomation>({defaultValues:defaultMessageAutomation});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;
    
  const watchType = watch('type');

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IMessageAutomation>,Error,IMessageAutomation>(
      _id>0?updateMessageAutomation:createMessageAutomation, {   
        onSuccess: (data: IResult<IMessageAutomation>) => {
          enqueueSnackbar( t('Operation done !!!'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          setCurrentFormNameAtom(`${t('Message automation')} - # ${data.data.id} # ` );
          //queryClient.invalidateQueries(['MessageAutomation',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    
    const {data: _data, refetch} = useQuery<IMessageAutomation>(['MessageAutomation', _id], () => retrieveEntity('MessageAutomation',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'MessageAutomation'], () => getEnumerationItemsByEnumerationCodes
        ( [Enum_PROBLEM_STATUS ] ));
       
           
    const handleChangeAutomationType = ( event: React.MouseEvent<HTMLElement>, newType: MessageAutomationType ) => {
       
      if (newType === null) 
        return;
      setValue('type', newType);              
    }
      

  useEffect( () => {              
    setCurrentFormNameAtom(t('Message automation'));  
    setCurrentBasicTextFilterProps(basicFilterMessageAutomation);
  }, []);

  

  /********** This use effect call retrieve data wich will call refetch and _data will be updated. 
and the new useEffect will take place ********************/
useEffect( () => {
    // setCurrentFormName(t('Billing'));        
    setCurrentFormNameAtom(_id>0?`${t('Message automation')} - # ${_id} # -`: `${t('Message automation')}` );
    if(_id > 0)
      retrieveData('MessageAutomation',_id, refetch);  
  }, [_id] );


useEffect( () => {
   
    if(_data && _data.id > 0) {
    reset(_data);
}
}, [_data]);

const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
  _setId(0);           
  reset(defaultMessageAutomation);    
}

const saveData = async (event: MouseEvent<HTMLButtonElement>) => {     
  
  if(!checkEntitySaveAuthorization('MessageAutomation', _id)){
    setIsSaveLoading(false);
    return;
  }
    
  const data = getValues(); 
  if(data.name.trim() === '' || data.description.trim() === '') {
      enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
      setIsSaveLoading(false);
      return;
    }

  mutate(data);
}

const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
  openEntityActionDrawer('MessageAutomation', _id);
}



const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
//    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
//    await retrieveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
//    reset(_data);        
}

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                              <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                              <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                              <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                              <TextField sx={{width:'calc(60% - 8px)'}} id="title" label={t('Name')} {...register('name')} 
                                  inputProps={ {  autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                              <Controller
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat              
                                      disabled={false}
                                      label={t('Priority')} sx={{width:'calc(20% - 8px)'}} //disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={0}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}

                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name='priority'
                                control={control}
                              />
                              <FormControlLabel sx={{width:'calc(20% - 8px)', marginTop: '2px'}}
                                  label={`${t('Active ?')}`}
                                  control={
                                  <Controller
                                      name={`isActive`} 
                                      control={control}
                                      render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                  />} 
                                />     
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="description" label={t('Description')} 
                              {...register('description')} inputProps={ {  autoComplete: 'new-password', style: {textTransform: 'none'} } }/>
                            </Box>                            
                            <ExpressionBox<IMessageAutomation> key={`expression : -1 ${getValues().matchingExpression}`}
                              control={control} setValue={setValue} getValues={getValues}
                              fieldExpressions={[
                                {entityName: 'ContractMessage', returnType: 'boolean', textRowCount: 2,
                                  path: `matchingExpression`, 
                                  label: `${t('Matching expression')} - ...`, 
                                  expression: getValues().matchingExpression },
                                ]}
                              />
                              <Box sx={{ mt: 1, width: '100%' }} >
                                <ToggleButtonGroup size="small"
                                  color="primary" value={watchType} exclusive
                                  onChange={ (event, newType) => handleChangeAutomationType(event, newType,)}
                                  aria-label="Small sizes" fullWidth
                                >
                                  <ToggleButton value="nonTicketable">{t('Non ticketable')}</ToggleButton>
                                  <ToggleButton value="ticketable">{t('Ticketable')} </ToggleButton>
                                  <ToggleButton value="newTicket">{t('New ticket')} </ToggleButton>
                                  <ToggleButton value="attachedTicket">{t('Joined to ticket')} </ToggleButton>
                                </ToggleButtonGroup>
                              </Box>
                              { watchType === 'newTicket' && 
                              <ExpressionBox<IMessageAutomation> key={`expression 0: ${getValues().matchingExpression}`}
                                control={control} setValue={setValue} getValues={getValues}
                                fieldExpressions={[
                                  {entityName: 'ContractMessage', returnType: 'string', textRowCount: 1,
                                    path: `newTicketTitleExpression`, 
                                    label: `${t('Expression')} : ${t('Title')} - ...`, 
                                    expression: getValues().newTicketTitleExpression },
                                  {entityName: 'ContractMessage', returnType: 'string', textRowCount: 1,
                                    path: `newTicketDescriptionExpression`, 
                                    label: `${t('Expression')} : ${t('Description')} - ...`, 
                                    expression: getValues().newTicketDescriptionExpression },  
                                  {entityName: 'ContractMessage', returnType: 'string', textRowCount: 1,
                                    path: `newTicketCategoryExpression`, 
                                    label: `${t('Expression')} : ${t('Category')} - ...`, 
                                    expression: getValues().newTicketCategoryExpression },
                                  {entityName: 'ContractMessage', returnType: 'string', textRowCount: 1,
                                    path: `newTicketSeverityExpression`, 
                                    label: `${t('Expression')} : ${t('Severity')} - ...`, 
                                    expression: getValues().newTicketSeverityExpression },  
                                ]}
                              /> }
                              { watchType === 'attachedTicket' && 
                              <ExpressionBox<IMessageAutomation> key={`expression 1: ${getValues().ticketFilterExpression}`}
                                control={control} setValue={setValue} getValues={getValues}
                                fieldExpressions={[
                                  {entityName: 'ContractMessage', returnType: 'numeric', textRowCount: 3,
                                    path: `ticketFilterExpression`, 
                                    label: `${t('Matching expression')} - ...`, 
                                    expression: getValues().ticketFilterExpression },                                   
                                ]}
                              /> }
                        </Stack>                        
                    </Grid>                   
                    <Grid item xs={12}  md={5} component={Paper} >
                        <Stack flexDirection='column'>      
                                      
                            <Box sx={{ mt: 1, width: '100%' }} >
                                                  
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                                                    
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              
                            </Box>
                        </Stack> 
                    </Grid>
                </Grid>
            </Box>
        </FormProvider>
  )
}
