
export interface IRole {
  id: number,
  name: string,
  normalizedName: string,
  description: string,
  concurrencyStamp: string,

  roleEntities: IRoleEntity[],

  userRoles: {id: number, userId: number, roleId: number}[]
  //userRole: any[] // just to have 
}



export interface IRoleEntity {
  id: number,

  roleId: number,
  canCreate: boolean,
  canRetrieve: boolean,
  canUpdate: boolean,
  printAllowed: boolean,
  workflowAllowed: boolean,
  attachAllowed: boolean,
  relativeViewAllowed: boolean,
  securityAllowed: boolean,
  linkAllowed: boolean,
  
  entityName: string,
  condition?: string,
  conditionName?: string,

  entityDescription: string,

  roleEntityFeatures: IRoleEntityFeature[]
}

export interface IRoleEntityFeature {
  id: number,
  roleEntityId: number,
  featureName: string,
  featureDescription: string,
}

export const defaultRole : IRole = {
  id: 0,
  name: '',
  normalizedName: '',
  description: '',
  concurrencyStamp: '',
  
  roleEntities: [],
  userRoles:[]
}

export interface IRoleSearch {

name: string,
description: string,  
}


