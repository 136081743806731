
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import {useWindowSize} from 'react-use';

import { Breakpoint, useTheme } from '@mui/system';
import { useMediaQuery } from '@mui/material';

import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom, isSaveLoadingAtom } from 'library/store';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';


import { DatePicker } from '@mui/x-date-pickers';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { flexBetween, flexBetweenCenter, flexCenter, justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import useTicketService from 'features/production/services/Ticket';

import { BasicTextFilterForm, defaultBasicTextFilterProps } from 'components/ui/BasicTextFilterForm';


import WidgetSummary from './widgets/WidgetSummary';
import WidgetBarChart from './widgets/WidgetBarChart';
import WidgetPieChart from './widgets/WidgetPieChart';
import { Button } from '@mui/material';
import { MdOutlineChangeCircle } from 'react-icons/md';
import { FormDialog } from 'components/ui/FormDialog';
import { INyxisDashboardDay, defaultNyxisDashboardDay } from 'features/production/models/Ticket';


export const Home = () => {

  const theme = useTheme();

  const { t, i18n } = useTranslation();

  const [currentFormName, setCurrentFormName] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const {language: lg, applicationSetup } = useRecoilValue(currentUserSessionAtom);
  
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);

  const {height, width} = useWindowSize();
  const isSm = useMediaQuery( theme.breakpoints.down('sm'));

  const {getNyxisDayDashboard} = useTicketService();
  const [dashboard, setDashboard] = useState<INyxisDashboardDay>(defaultNyxisDashboardDay);

  // const [openSchoolYearFilter, setOpenSchoolYearFilter] = useState(false);
  // const basicFilterSchoolYear = useBasicFilterSchoolYear( 
  //     async (event: React.MouseEvent<unknown>, row: ISchoolYear) => {
  //         const {id, name } = row;

  //         const dash = await getSchoolYearDashboard(id);
  //         setSchoolYearName(name);

  //         setDashboard(dash);
  //         setOpenSchoolYearFilter(false);
  //     }
  // );


  const handleClickSelectSchoolYear = (event: any) => {
    //setOpenSchoolYearFilter(true);
  }


  useEffect( () => {        
    setCurrentFormName(t('Home'));
    setCurrentBasicTextFilterProps(defaultBasicTextFilterProps);
    setIsSaveLoading(false);
  }, []);    

const [dateFrom, setDateFrom] = React.useState<Date>(new Date());
const [dateTo, setDateTo] = React.useState<Date>(new Date());

  useEffect(() => {
    async function _() {
            
      const dash = await getNyxisDayDashboard(dateFrom, dateTo);            
      setDashboard(dash);     
    }
    _();
  }, [dateFrom, dateTo]);
  
  return (
    <Box sx={{ mx: 0.1 }}>
      <Grid container rowSpacing={1} columnSpacing={3}>
        <Grid item xs={12}>
          <Stack flexDirection='column'>
            <Box sx={{ ...justifyCenter, mt: 0.25, mb: 0.25 }} >
              <Button>
                <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                  {`:::: ${t('The day from')} : `}
                </Typography>                
              </Button>              
              <DatePicker                      //label={t('Appointment date')} 
                renderInput={(params) => <TextField {...params} sx={{width: isSm?'calc(30% - 8px)':'calc(10% - 8px)'}} />}
                onChange={(newValue) => setDateFrom(newValue||new Date())}                    
                value={dateFrom}                      
              /> 
              <Button>
                <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                  {`:::: ${t('to')} `}
                </Typography>                
              </Button>              
              <DatePicker                      //label={t('Appointment date')} 
                renderInput={(params) => <TextField {...params} sx={{width: isSm?'calc(30% - 8px)':'calc(10% - 8px)'}} />}
                onChange={(newValue) => setDateTo(newValue||new Date())}                    
                value={dateTo}                      
              /> 
            </Box>
            
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <WidgetSummary title={`- ${t('Messages')} / ${t('Avg response time')} (min)`} key={` Messages ${dateFrom} ${dateTo}`} paletteColor={theme.palette['info']} 
                iconName='IoSendSharp' total={dashboard.messageCount} total2={dashboard.avgMessageResponseTime} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <WidgetSummary title={` - ${t('Tickets')} `} key={` Tickets ${dateFrom} ${dateTo} `} paletteColor={theme.palette['success']} 
                iconName='IoSendOutline' total={dashboard.ticketCount}  />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <WidgetSummary title={` - ${t('Contacts')} `}  key={` Contacts ${dateFrom} ${dateTo} `} paletteColor={theme.palette['warning']}  
                iconName='MdCallReceived' total={dashboard.contactCount}  />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <WidgetSummary title={`${t('Contracts')} `} key={` Contracts ${dateFrom} ${dateTo} `} paletteColor={theme.palette['success']} 
                iconName='MdContacts' total={dashboard.contractCount}  />
        </Grid>
        
        <Grid item xs={12} md={6} lg={6} key={` - `}>
          <WidgetBarChart title={`${t('Validated tickets per category')}`}
                subheader={`${t('Count')}`}
                formatterText={`${t('Category')}(s) `}
                chartLabels={dashboard.classChatLabels}
                //chartLabels={['SIL-A','CP-A','CE1-A','CE2-A','CM1-A','CM2-A','CL1-A','CL2-A','CL3-A','CL4-A','CL5-A','CL6-A']}
                chartData={[
                  {
                    name: t('Total'),
                    type: 'column',
                    fill: 'solid',
                    data: dashboard.categoryTicketCounts, // [32,41,25,41,35,28,29,27,35,24,41,33],
                  },
                  
                ]}   />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
            <WidgetPieChart title={`${t('Validated tickets assigned per user')}`} subheader=''
                // chartData={[
                //   { label: 'America', value: 4344 },
                //   { label: 'Asia', value: 5435 },
                //   { label: 'Europe', value: 1443 },
                //   { label: 'Africa', value: 4443 },
                // ]}
                //chartData={ dashboard.monthMessagesByType.map( x => ({...x, label: _mt.has(x.label)? _mt.get(x.label)??x.label :x.label }) )}
                chartData={ dashboard.ticketCountPerUser}
                chartColors={[
                  theme.palette.primary.main,
                  theme.palette.info.main,
                  theme.palette.warning.main,
                  theme.palette.error.main,
                ]}/>
        </Grid>      
      </Grid>      
    </Box>
  )
}
