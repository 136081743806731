
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import { IEdition, IEditionSearch } from "../models/Edition";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell } from 'components/ui/EnhancedTable';



import { currentUserSessionAtom } from 'library/store';


const _ = () => {

    const axios = useAxios(); 

    
    const createEdition = async (edition: IEdition)  =>       
        await (await axios.post('/api/misc/edition/create', edition)).data;       
        
    const updateEdition = async (edition: IEdition)  =>       
        await (await axios.post('/api/misc/edition/update', edition)).data; 
    
    const getEdition = async (id  : number )  => {
      const {data} = (await axios.get(`/api/misc/edition/get-edition/${id}`));
      return await data;
    }

    const getEditions = async (criteria: IEditionSearch, pagination?: IPagination) : Promise<IEdition[]> => {

        const {name, description} = criteria;
        const pageSize = pagination?.pageSize ?? 50;
        const pageNumber = pagination?.pageNumber ?? 1;
        
        const {data} = (await axios.get(`/api/misc/edition/get-editions?name=${name}&description=${description}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
        return await data;
    }

    const generateReport = async (desktopPublishingExtraction: any )  => 
      await (await axios.post('/api/misc/edition/generate-report', desktopPublishingExtraction)).data;

    
         
    return {    
      createEdition,
      updateEdition,
      getEditions,
      
      generateReport
      
    } 
}

export default _;


export const useBasicFilterEdition = ( onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IEdition) => void ) => {

  const { t, i18n } = useTranslation();   
  const { getEditions } = _();

  const [headEditionCells, setHeadEditionCells]  = useState<HeadCell<IEdition>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', width: 5 },
    {id:'name', label : t('Name'),  display: true, type: 'string', width: 40},
    {id:'description', label : t('Description'),  display: true, type: 'string', width: 15},
    {id:'isActive', label : t('Active ?'),  display: true, type: 'boolean', width: 10},
    
  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
         
      {name: 'name', text: t('Name'), value: ''},
      {name: 'description', text: t('Description'), value: ''},
           
    ]);    
  const [filteredEditions, ] = useState<IEdition[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IEdition[]> => {    
    
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
        
    const arr = await getEditions( {name, description}, pagination );
    
    return arr;
  }

  const objKey: keyof IEdition = 'id';

  return {
    title: t('Desktop publishing'), headCells: headEditionCells, objKey,
    filterElements, rows: filteredEditions, 
    onFilterButtonClick, onRowDoubleClick
  }
}


