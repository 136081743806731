

export interface IProblem {
    id: number,

    title: string,
    description: string,
    issueDate: Date,
    status: string,
    statusDate: Date
    rootCause: string,
  }


  export const defaultProblem : IProblem = {
    id: 0,
    title: '',
    description: '',
    issueDate: new Date(),
    status: '95',
    statusDate: new Date(),
    rootCause: '',
  }


  export interface IProblemSearch {
  
    title: string,
    description: string,  
  }
