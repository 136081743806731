export type JobType = 'report' | 'feature' ;

export type JobScheduleType = 'day' | 'month' | 'unic' | 'recurrent' ;

export type DayOfWeekType = 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT' | 'SUN' | '' | 'W'; // '' -> every day, 

//export type WeekOrderType = 'first' | 'second' | 'third' | 'fourth' | 'last';

export type DateType = 'day' | 'order'; 


export interface IJob {
    id: number,
    name: string,
    description: string,
    isActive: boolean,

    applicationQueryId: number,

    applicationQueryName: string,

    // businessApplicationId: number,
    // businessApplicationName: string,

    

    jobSchedules: IJobSchedule[]
  }


  export interface IJobSchedule {
    id: number,
    description: string,
    isActive: boolean,
    jobId: number,

    entityName: string,
    entityDescription: string,

    startDate: Date,
    hasEndDate: boolean,
    endDate: Date,
    executionTime: Date,
    isStepByItem: boolean,

    type:	JobScheduleType,
    endExecutionTime: Date,
    recurrencyUnit: string,
    recurrencyValue: number,
    hasEndExecutionTime: boolean,
    isMonday: boolean,
    isTuesday: boolean,
    isWednesday: boolean,
    isThursday: boolean,
    isFriday: boolean,
    isSaturday: boolean,
    isSunday: boolean,
    isJanuary: boolean,
    isFebuary: boolean,
    isMarch: boolean,
    isApril: boolean,
    isMay: boolean,
    isJune: boolean,
    isJuly: boolean,
    isAugust: boolean,
    isSeptember: boolean,
    isOctober: boolean,
    isNovember: boolean,
    isDecember: boolean,
    dateType: DateType,
    dayOfMonth: number,
    weekOrder: number, //WeekOrderType,
    dayOfWeek: DayOfWeekType,

    jobScheduleParameters: IJobScheduleParameter[],
    jobScheduleSteps: IJobScheduleStep[]
  }

  export interface IJobScheduleParameter {
    id: number,
    jobScheduleId: number,
    applicationQueryParameterId: number,
    parameterValueExpression: string,

    parameterName: string
  }

  export interface IJobScheduleStep {
    id: number,
    jobScheduleId: number,
        
    retentionMode: string,
    retentionUnit: string,
    retentionValue: number,

    type: JobType,
   
    featureName: string,
    featureDescription: string,
    reportId: number,
    reportName: string,
    reportTransmissionMode: string,
    reportRecipientExpression: string,
    reportLanguageExpression: string,     

    jobScheduleStepReportTables: IJobScheduleStepReportTable[]
  }

  export interface IJobScheduleStepReportTable {
    id: number,
    jobScheduleStepId: number,
    reportTableId: number,
    applicationQueryId: number,  
    
    tableName: string,
    applicationQueryName: string,  

    jobScheduleStepReportTableColumns: IJobScheduleStepReportTableColumn[],
    jobScheduleStepReportTableParameters: IJobScheduleStepReportTableParameter[]
    

  }

  export interface IJobScheduleStepReportTableColumn {
    id: number,
    jobScheduleStepReportTableId: number,
    reportTableColumnId: number,
    
    columnValueExpression: string,
    alias: string
  }

  export interface IJobScheduleStepReportTableParameter {
    id: number,
    jobScheduleStepReportTableId: number,
    applicationQueryParameterId: number,
    parameterValueExpression: string,

    parameterName: string
  }

  export const defaultJob : IJob = {
    id: 0,
    name: '',
    description: '',
    isActive: true,

    applicationQueryId: 0,

    applicationQueryName: '',
    // businessApplicationId: 0,
    // businessApplicationName: '',
   
    jobSchedules: []
  }

  export const defaultJobSchedule : IJobSchedule = {
    id: 0,
    description: '',
    isActive: true,
    jobId: 0,

    entityName: '',
    entityDescription: '',

    startDate: new Date(),
    hasEndDate: false,
    endDate: new Date(),
    executionTime: new Date(),
    isStepByItem: true,
    
    type:	'day',

    endExecutionTime: new Date(),
    recurrencyUnit: 'minute',
    recurrencyValue: 1,
    hasEndExecutionTime: true,

    isMonday: true,
    isTuesday: true,
    isWednesday: true,
    isThursday: true,
    isFriday: true,
    isSaturday: true,
    isSunday: true,
    isJanuary: true,
    isFebuary: true,
    isMarch: true,
    isApril: true,
    isMay: true,
    isJune: true,
    isJuly: true,
    isAugust: true,
    isSeptember: true,
    isOctober: true,
    isNovember: true,
    isDecember: true,
    dateType: 'day',
    dayOfMonth: 1,
    weekOrder: 1, //'first',
    dayOfWeek: 'MON',
    
    jobScheduleParameters: [],
    jobScheduleSteps: []
  }

  export const defaultJobScheduleStep : IJobScheduleStep = {
    id: 0,
    jobScheduleId: 0,
        
    retentionMode: 'NONE',
    retentionUnit: '',
    retentionValue: 0,

    type: 'report',
       
    featureName: '',
    featureDescription: '',
    reportId: 0,
    reportName: '',
    reportTransmissionMode: '',
    reportRecipientExpression: '',
    reportLanguageExpression: '',

    jobScheduleStepReportTables: []
  }


  export interface IJobSearch {
    name: string,
    description: string
  }