// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, CardHeader, Typography, useTheme } from '@mui/material';
// utils
//import { fShortenNumber } from '../../../utils/formatNumber';
// components
//import Iconify from '../../../components/iconify';

import ReactApexChart from 'react-apexcharts';

import { GrFormPreviousLink, GrFormNextLink } from 'react-icons/gr';
import useChart from 'library/useChart';
import numeral from 'numeral';


// ----------------------------------------------------------------------
const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 72;

const StyledChartWrapper = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));


export interface WidgetPieChartProps {    
    title: string,
    subheader: string,
    
    chartColors: string[],
       
    chartData: {
      label: string,      
      value: number,
    }[],//ApexAxisChartSeries, // | ApexNonAxisChartSeries,

    
}

export default function WidgetPieChart(prop: WidgetPieChartProps) {

  const theme = useTheme();
  
  const {title, subheader, chartColors, chartData} = prop;


  const chartLabels = chartData.map((i) => i.label);

  const chartSeries = chartData.map((i) => i.value);

  const chartOptions = useChart({
    colors: chartColors,
    labels: chartLabels,
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => numeral(seriesName).format(), //fNumber(seriesName),
        title: {
          formatter: (seriesName) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
  });

  return (
    <Card
      // sx={{
      //   py: 5,
      //   boxShadow: 0,
      //   textAlign: 'center',
      //   color: (theme) => theme.palette['primary'].dark, //.palette[color].darker,
      //   bgcolor: (theme) => theme.palette['primary'].light,
      //   //...sx,
      // }}
      //{...other}
    >
      <CardHeader title={title} subheader={subheader} /> 
      <StyledChartWrapper dir="ltr">
        <ReactApexChart type="pie" series={chartSeries} options={chartOptions} height={280} />
      </StyledChartWrapper>
      
    </Card>
  );
}